import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(),
	},
	header: {
		marginRight: theme.spacing(),
	},
	headerContainer: {
		marginTop: theme.spacing(),
		marginBottom: theme.spacing(),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	headerBlock: {
		margin: theme.spacing(),
	},
	headerDetails: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
		flexWrap: 'wrap',
	},
	divider: {
		marginBottom: theme.spacing(),
	},
}));
