import React from 'react';
import Paper from '@material-ui/core/Paper';
import MuiTable from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TableContainer from '@material-ui/core/TableContainer';

import Tooltip from '../../Tooltip';
import { useStyles } from './styles';
import { convertDistance } from '../utils';
import { useStoreState } from '../../../store';
import { Upload } from '../../../models/uploads';
import { formatDate } from '../../../utils/formatters';

export interface Props {
	uploads: Upload[];
	selected: string[];
	handleCheckbox: (id: string) => void;
	handleTableDelete: (id: string) => Promise<void>;
	isSelected: (id: string) => boolean;
	handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleImport: (id: string) => Promise<void>;
}

const Table: React.FC<Props> = ({
	uploads,
	selected,
	handleCheckbox,
	handleTableDelete,
	isSelected,
	handleSelectAll,
	handleImport,
}) => {
	const classes = useStyles();

	const timeFormat = useStoreState((state) => state.settings.timeFormat);

	return (
		<div id="import-table" className={classes.root}>
			<TableContainer
				style={{
					borderWidth: 0,
				}}
				component={Paper}
				variant="outlined"
			>
				<MuiTable stickyHeader size="small">
					<TableHead>
						<TableRow>
							<TableCell
								width="5%"
								classes={{ head: classes.header }}
							>
								<Checkbox
									color="primary"
									checked={
										uploads.length > 0 &&
										selected.length === uploads.length
									}
									classes={{
										root: classes.checkbox,
									}}
									onChange={handleSelectAll}
									style={{ padding: 4 }}
								/>
							</TableCell>
							<TableCell
								width="30%"
								classes={{ head: classes.header }}
							>
								Filename
							</TableCell>
							<TableCell
								width="15%"
								classes={{ head: classes.header }}
							>
								Date
							</TableCell>
							<TableCell
								width="15%"
								classes={{ head: classes.header }}
							>
								{`Time (${timeFormat})`}
							</TableCell>
							<TableCell
								width="15%"
								classes={{ head: classes.header }}
							>
								Distance
							</TableCell>
							<TableCell
								width="10%"
								classes={{ head: classes.header }}
							>
								Status
							</TableCell>
							<TableCell
								align="right"
								width="10%"
								classes={{ head: classes.header }}
							>
								Remove
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{uploads.map((row) => {
							const isItemSelected = isSelected(row.id);
							return (
								<TableRow
									hover
									key={row.id}
									style={{ textDecoration: 'none' }}
									selected={isItemSelected}
									className={classes.row}
									classes={{ selected: classes.selected }}
								>
									<TableCell>
										<Checkbox
											onClick={() =>
												handleCheckbox(row.id)
											}
											checked={isItemSelected}
											color="primary"
											style={{ padding: 4 }}
										/>
									</TableCell>
									<TableCell>{row.filename}</TableCell>
									<TableCell>
										{row.date ? formatDate(row.date) : '-'}
									</TableCell>
									<TableCell>
										{row.date
											? formatDate(
													row.date,
													timeFormat === '12h'
														? 'h:mm a'
														: 'HH:mm',
											  )
											: '-'}
									</TableCell>
									<TableCell>
										{row.distance
											? convertDistance(
													row.distance,
													row.unit,
											  )
											: '-'}
									</TableCell>
									<TableCell>
										{row.error && row.errorMessage ? (
											<div
												style={{
													display: 'flex',
													alignContent: 'center',
												}}
											>
												<Tooltip
													title={row.errorMessage}
												>
													<CancelIcon
														className={
															classes.invalid
														}
													/>
												</Tooltip>
											</div>
										) : (
											<div
												style={{
													display: 'flex',
													alignContent: 'center',
												}}
											>
												{row.success ? (
													<Tooltip
														title={
															'activity imported'
														}
													>
														<CheckCircleIcon
															className={
																classes.success
															}
														/>
													</Tooltip>
												) : (
													<Tooltip
														title={
															'ready to import'
														}
													>
														<IconButton
															className={
																classes.iconButton
															}
															style={{
																padding: 0,
															}}
															onClick={() =>
																handleImport(
																	row.id,
																)
															}
														>
															<AddCircleIcon
																className={
																	classes.valid
																}
															/>
														</IconButton>
													</Tooltip>
												)}
											</div>
										)}
									</TableCell>
									<TableCell align="right">
										<IconButton
											className={classes.iconButton}
											onClick={async () =>
												await handleTableDelete(row.id)
											}
										>
											<DeleteIcon
												className={classes.icon}
											/>
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</MuiTable>
			</TableContainer>
		</div>
	);
};

export default Table;
