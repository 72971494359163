import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

export interface Props {
	label: string;
	value: number;
}

const TooltipContent: React.FC<Props> = ({ label, value }) => {
	const classes = useStyles();

	return (
		<Paper className={classes.paper} variant="outlined">
			<div className={classes.tooltip}>
				<Typography className={classes.text} style={{ fontSize: 16 }}>
					{label}
				</Typography>
				<Typography
					className={classes.text}
					style={{ fontSize: 16 }}
				>{`: ${value} %`}</Typography>
			</div>
		</Paper>
	);
};

export default TooltipContent;
