import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import Info from './Info';
import Title from './Title';
import Icons from './Icons';
import Details from './Details';
import { useStyles } from './styles';
import { HealthData } from './interfaces';
import Food, { Props as FoodProps } from './Food';
import Health, { Props as HealthProps } from './Health';
import { getClosestDate } from '../../Log/Day/utils';
import { useStoreActions, useStoreState } from '../../../store';

const Header: React.FC = () => {
	const classes = useStyles();

	const setMacroError = useStoreActions(
		(actions) => actions.day.setMacroError,
	);
	const getHealthStats = useStoreActions(
		(actions) => actions.health.getHealthStats,
	);
	const date = useStoreState((state) => state.day.date);
	const setHealth = useStoreActions((actions) => actions.day.setHealth);
	const addHealth = useStoreActions((actions) => actions.health.addHealth);

	const [expand, setExpand] = useState(false);
	const [openHealth, setOpenHealth] = useState(false);
	const [openFood, setOpenFood] = useState(false);

	const {
		handleSubmit,
		control,
		reset,
		getValues,
		register,
		errors,
		watch,
	} = useForm<HealthData>();

	const handleExpandClick = () => {
		setExpand((value) => !value);
	};

	const handleHealthClick = () => {
		setMacroError(false);
		setOpenHealth((value) => !value);
	};

	const handleFoodClick = () => {
		setOpenFood((value) => !value);
	};

	const handleHealthConfirmClick = async (data: HealthData) => {
		try {
			const { carbs, fat, protein, calories } = data;
			if (carbs + fat + protein !== 100) {
				setMacroError(true);
			} else {
				await addHealth({ ...data, date, calories: calories || 0 });
				const healthStats = await getHealthStats();
				setHealth(getClosestDate(date, healthStats));
				handleHealthClick();
			}
		} catch (err) {
			handleHealthClick();
		}
	};

	const handleFoodConfirmClick = async () => {
		try {
		} catch (err) {}
	};

	const healthProps: HealthProps = {
		handleHealthConfirmClick,
		handleHealthClick,
		handleSubmit,
		openHealth,
		control,
		errors,
		reset,
		watch,
		register,
		getValues,
	};

	const foodProps: FoodProps = {
		openFood,
		handleFoodClick,
		handleFoodConfirmClick,
	};

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<Title />
				<Info />
				<Icons
					handleExpandClick={handleExpandClick}
					handleHealthClick={handleHealthClick}
					handleFoodClick={handleFoodClick}
				/>
			</div>
			<Details expand={expand} />
			<Health {...healthProps} />
			<Food {...foodProps} />
		</div>
	);
};

export default Header;
