import React from 'react';

import { fileToBase64 } from '../utils';
import { Upload } from '../../../models/uploads';
import PSelection, { Props } from './Selection';
import { useStoreActions, useStoreState } from '../../../store';

const Selection: React.FC = () => {
	const files = useStoreState((state) => state.uploads.files);
	const setFiles = useStoreActions((actions) => actions.uploads.setFiles);
	const selected = useStoreState((state) => state.uploads.selected);
	const setSelected = useStoreActions(
		(actions) => actions.uploads.setSelected,
	);
	const uploads = useStoreState((state) => state.uploads.uploads);
	const getUploads = useStoreActions((actions) => actions.uploads.getUploads);
	const removeUpload = useStoreActions(
		(actions) => actions.uploads.removeUpload,
	);
	const updateUpload = useStoreActions(
		(actions) => actions.uploads.updateUpload,
	);
	const addActivity = useStoreActions(
		(actions) => actions.activity.addActivity,
	);
	const addNotification = useStoreActions(
		(actions) => actions.notifications.addNotification,
	);
	const getNotifications = useStoreActions(
		(actions) => actions.notifications.getNotifications,
	);
	const getActivities = useStoreActions(
		(actions) => actions.activities.getActivities,
	);
	const setLoading = useStoreActions((actions) => actions.uploads.setLoading);

	const checkUploads = async (uploads: Upload[]) => {
		try {
			await Promise.all(
				uploads.map(
					async (upload) =>
						upload.error &&
						upload.errorMessage === 'duplicate upload' &&
						(await updateUpload(upload.id)),
				),
			);
			await getUploads();
		} catch (err) {}
	};

	const handleImportSelected = async () => {
		try {
			setLoading(true);
			const filesToDelete: string[] = [];
			await Promise.all(
				selected.map(async (id) => {
					try {
						const upload = uploads.filter(
							(upload) => upload.id === id,
						)[0];
						if (!upload) {
							filesToDelete.push(id);
						} else if (!upload.error && !upload.success) {
							const fileToImport = files.filter(
								(file) => file.id === id,
							)[0];
							if (fileToImport) {
								const base64 = await fileToBase64(
									fileToImport.file,
								);
								const activity = await addActivity({
									uploadId: id,
									base64,
								});
								await addNotification({
									message: `New activity: ${fileToImport.file.name}`,
									url: `/activities/${activity.id}`,
								});
								filesToDelete.push(id);
							} else {
								await removeUpload(id);
							}
						}
					} catch (err) {}
				}),
			);
			setFiles(files.filter((file) => !filesToDelete.includes(file.id)));
			setSelected([]);
			await getUploads();
			setLoading(false);
			await getNotifications();
			await getActivities();
		} catch (err) {}
	};

	const handleDeleteSelected = async () => {
		try {
			setLoading(true);
			await Promise.all(
				selected.map(async (id) => {
					try {
						await removeUpload(id);
					} catch (err) {}
				}),
			);
			const uploads = await getUploads();
			setFiles(files.filter((file) => !selected.includes(file.id)));
			setSelected([]);
			await checkUploads(uploads);
			setLoading(false);
		} catch (err) {}
	};

	const props: Props = {
		selected,
		handleImportSelected,
		handleDeleteSelected,
	};

	return <PSelection {...props} />;
};

export default Selection;
