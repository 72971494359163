import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(),
	},
	header: {
		marginRight: theme.spacing(),
	},
	headerContainer: {
		marginTop: theme.spacing(),
		marginBottom: theme.spacing(),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	iconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
		paddingTop: 0,
		paddingBottom: 0,
	},
	icon: {
		color: theme.palette.common.black,
	},
	paper: {
		borderColor: theme.palette.secondary.light,
		backgroundColor: theme.palette.secondary.light,
		marginTop: theme.spacing(),
		marginBottom: theme.spacing(),
		borderWidth: 2,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		flexWrap: 'wrap',
	},
	activityItem: {
		margin: theme.spacing(),
	},
	activityDetail: {
		marginTop: theme.spacing(),
		marginBottom: theme.spacing(),
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
	},
	button: {
		margin: theme.spacing(),
		'&:hover': {
			color: theme.palette.primary.main,
			backgroundColor: 'transparent',
		},
		fontWeight: 'bold',
		fontSize: 18,
		justifyContent: 'flex-start',
	},
}));
