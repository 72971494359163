import React, { useState } from 'react';

import { Notification } from '../../../models/notifications';
import { useStoreActions, useStoreState } from '../../../store';
import PIcons, { Props as IconsProps } from './Icons';

export interface Props {
	loading: boolean;
	login: boolean;
}

const Icons: React.FC<Props> = ({ loading, login }) => {
	const notifications = useStoreState(
		(state) => state.notifications.notifications,
	);
	const removeNotification = useStoreActions(
		(actions) => actions.notifications.removeNotification,
	);
	const setAuth = useStoreActions((actions) => actions.user.setAuth);
	const reset = useStoreActions((actions) => actions.reset);

	const [openDialog, setOpenDialog] = useState(false);
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const [settingsAnchor, setSettingsAnchor] = useState<Element | null>(null);

	const open = Boolean(anchorEl);
	const settingsOpen = Boolean(settingsAnchor);

	const handleSettingsOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setSettingsAnchor(event.currentTarget);
	};

	const handleSettingsClose = () => {
		setSettingsAnchor(null);
	};

	const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (notifications.length) {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleNotification = async (notification: Notification) => {
		try {
			await removeNotification(notification);
		} catch (err) {}
	};

	const handleDialogClick = () => {
		setOpenDialog((value) => !value);
	};

	const handleDialogConfirmClick = () => {
		handleLogout();
		handleDialogClick();
	};

	const handleLogout = () => {
		setAuth(false);
		reset();
	};

	const props: IconsProps = {
		handleOpen,
		handleClose,
		anchorEl,
		open,
		notifications,
		handleNotification,
		handleSettingsOpen,
		handleSettingsClose,
		settingsAnchor,
		settingsOpen,
		openDialog,
		handleDialogClick,
		handleDialogConfirmClick,
	};

	return loading || login ? <></> : <PIcons {...props} />;
};

export default Icons;
