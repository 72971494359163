import { action, Action } from 'easy-peasy';

export interface Day {
	date: string;
	current: boolean;
}

export interface LogModel {
	date: Date;
	setDate: Action<LogModel, Date>;
	week: number;
	setWeek: Action<LogModel, number>;
	days: Day[];
	setDays: Action<LogModel, Day[]>;
	redirectError: boolean;
	setRedirectError: Action<LogModel, boolean>;
}

const log: LogModel = {
	date: new Date(),
	setDate: action((state, value) => {
		state.date = value;
	}),
	week: 0,
	setWeek: action((state, value) => {
		state.week = value;
	}),
	days: [],
	setDays: action((state, value) => {
		state.days = value;
	}),
	redirectError: false,
	setRedirectError: action((state, value) => {
		state.redirectError = value;
	}),
};

export default log;
