import React, { useState } from 'react';

import { Props } from '../Filter/index';
import { useStoreState, useStoreActions } from '../../../../store';
import PArrayFilter, { Props as ArrayFilterProps } from './ArrayFilter';

const ArrayFilter: React.FC<Props> = ({
	filterReset,
	control,
	watch,
	operator,
	label,
	operators,
	reset,
	getValues,
	dataName,
}) => {
	const filters = useStoreState((state) => state.activities.filters);
	const setFilters = useStoreActions(
		(actions) => actions.activities.setFilters,
	);
	const tags = useStoreState((state) => state.tags.tags);
	const shoes = useStoreState((state) => state.shoes.shoes);
	const setSelectedTags = useStoreActions(
		(actions) => actions.activities.setSelectedTags,
	);
	const setSelectedShoes = useStoreActions(
		(actions) => actions.activities.setSelectedShoes,
	);
	const filtersData = useStoreState((state) => state.activities.filtersData);
	const setFiltersData = useStoreActions(
		(actions) => actions.activities.setFiltersData,
	);

	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const open = Boolean(anchorEl);

	const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		if (
			(dataName === 'tags' && tags.length) ||
			(dataName === 'shoes' && shoes.length)
		) {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleReset = () => {
		const newFilters = filters.filter((filter) => filter.type !== dataName);
		const newFiltersData = Object.assign({}, filtersData);
		newFiltersData[operator] = undefined;
		setFiltersData(newFiltersData);
		setFilters(newFilters);
		if (dataName === 'shoes') {
			setSelectedShoes([]);
		}
		if (dataName === 'tags') {
			setSelectedTags([]);
		}
		reset({
			...getValues(),
			...filterReset,
		});
	};

	const props: ArrayFilterProps = {
		watch,
		control,
		operator,
		operators,
		label,
		dataName,
		handleReset,
		handleOpen,
		handleClose,
		anchorEl,
		open,
	};

	return <PArrayFilter {...props} />;
};

export default ArrayFilter;
