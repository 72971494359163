import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(),
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
		flexWrap: 'wrap',
	},
	title: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'flex-start',
		margin: theme.spacing(),
	},
	divider: {
		marginTop: 8,
		marginBottom: 8,
	},
	shoes: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
	svg: {
		fill: theme.palette.defaultBlack,
	},
}));
