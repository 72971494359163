import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { AxiosResponse } from 'axios';
import moment from 'moment';

import Loading from '../Loading';
import { useStyles } from './styles';
import { useStoreState, useStoreActions } from '../../store';
import { getClosestDate, getEndOfDay } from '../Log/Day/utils';
import useWindowDimensions from '../../utils/windowSize';
import { formatDateAsNumber } from '../../utils/formatters';
import Header from './Header';
import Macros from './Macros';
import Activities from './Activities';
import Meal from './Meal';

const Day: React.FC = () => {
	const classes = useStyles();
	const { height } = useWindowDimensions();

	const location = useLocation();
	const id = location.pathname.split('/').pop() as string;

	const previousDate = useStoreState((state) => state.day.date);
	const theme = useStoreState((state) => state.settings.theme);
	const meals = useStoreState((state) => state.foods.meals);
	const mealNumbers = useStoreState((state) => state.settings.meals);
	const setDate = useStoreActions((actions) => actions.day.setDate);
	const getActivities = useStoreActions(
		(actions) => actions.activities.getActivities,
	);
	const getHealthStats = useStoreActions(
		(actions) => actions.health.getHealthStats,
	);
	const setActivities = useStoreActions(
		(actions) => actions.day.setActivities,
	);
	const setRedirectError = useStoreActions(
		(actions) => actions.log.setRedirectError,
	);
	const activities = useStoreState((state) => state.day.activities);
	const allActivities = useStoreState((state) => state.activities.activities);
	const food = useStoreState((state) => state.foods.foods);
	const entries = useStoreState((state) => state.foods.entries);
	const healthStats = useStoreState((state) => state.health.healthStats);
	const setHealth = useStoreActions((actions) => actions.day.setHealth);
	const getFood = useStoreActions((actions) => actions.foods.getFoods);
	const getMeals = useStoreActions((actions) => actions.foods.getMeals);
	const getEntries = useStoreActions((actions) => actions.foods.getEntries);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const date = moment(id, 'DD-MM-YYYY', true);
				const isoDate = date.clone().toISOString();
				if (
					!date.isValid() ||
					formatDateAsNumber(isoDate) >
						formatDateAsNumber(getEndOfDay().toISOString())
				) {
					setError(1);
					setRedirectError(true);
				} else {
					setDate(isoDate);
					let allActivityData = allActivities.slice();
					if (!allActivities.length) {
						allActivityData = await getActivities();
					}
					let newHealthStats = healthStats.slice();
					if (!healthStats.length) {
						newHealthStats = await getHealthStats();
					}
					if (!food.length) {
						await getFood();
					}
					if (!meals.length) {
						await getMeals();
					}
					if (isoDate !== previousDate) {
						await getEntries(isoDate);
					}
					const activityData = allActivityData.filter((activity) => {
						const activityDate = new Date(activity.date).setHours(
							0,
							0,
							0,
							0,
						);
						const filterDate = new Date(isoDate).setHours(
							0,
							0,
							0,
							0,
						);
						if (activityDate === filterDate) {
							return true;
						}
						return false;
					});
					setHealth(getClosestDate(isoDate, newHealthStats));
					setActivities(activityData);
				}
			} catch (err) {
				if (err && err.response) {
					setError((err.response as AxiosResponse).status);
				}
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, [
		setDate,
		getActivities,
		getHealthStats,
		setHealth,
		setActivities,
		getFood,
		getEntries,
		getMeals,
		setRedirectError,
	]);

	return (
		<div className={classes.root}>
			{error ? (
				<Redirect to={'/log'} />
			) : loading ? (
				<Loading />
			) : (
				<div
					className={classes.container}
					style={{
						maxHeight: height - 60 - theme.spacing(3),
						overflow: 'auto',
					}}
				>
					<Paper className={classes.paperTop}>
						<Header />
					</Paper>
					{!!entries.length && (
						<Paper className={classes.paper}>
							<Macros />
						</Paper>
					)}
					{!!activities.length && (
						<Paper className={classes.paper}>
							<Activities />
						</Paper>
					)}
					{mealNumbers.map((mealNumber, index) => {
						return (
							<Paper key={index} className={classes.paper}>
								<Meal name={mealNumber} number={index} />
							</Paper>
						);
					})}
				</div>
			)}
		</div>
	);
};

export default Day;
