import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogActions,
	Button,
	DialogContent,
	Stepper,
	Step,
	Radio,
	StepLabel,
	RadioGroup,
	Typography,
	FormControl,
	FormLabel,
	FormControlLabel,
	TextField,
	Select,
	MenuItem,
	Divider,
	IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Controller, UseFormMethods } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useStyles } from './styles';
import { useStoreState } from '../../../../store';
import Alert from '@material-ui/lab/Alert';
import { steps, units, getMealTotal } from './utils';
import { FoodData, MealData, MealFood } from './interfaces';
import { Food as IFood } from '../../../../models/foods';

export interface Props
	extends Pick<
		UseFormMethods<FoodData>,
		'control' | 'handleSubmit' | 'register' | 'errors'
	> {
	openFood: boolean;
	handleFoodClick: () => void;
	activeStep: number;
	handleTypeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleBack: () => void;
	handleFoodConfirmClick: (data: FoodData) => Promise<void>;
	deleteMealFood: (index: number) => void;
	handleMealConfirmClick: (data: MealData) => void;
	watchFood: IFood | null;
	watchUnit: string;
	watchValue: string;
	type: string | undefined;
	food: FoodData | undefined;
	mealFood: MealFood[];
	error: number;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mealControl: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mealRegister: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mealHandleSubmit: any;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mealErrors: any;
}

const Food: React.FC<Props> = ({
	openFood,
	handleFoodClick,
	activeStep,
	handleTypeChange,
	handleBack,
	handleFoodConfirmClick,
	deleteMealFood,
	handleMealConfirmClick,
	watchFood,
	watchUnit,
	watchValue,
	type,
	food,
	mealFood,
	error,
	control,
	handleSubmit,
	register,
	errors,
	mealControl,
	mealRegister,
	mealHandleSubmit,
	mealErrors,
}) => {
	const classes = useStyles();

	const theme = useStoreState((state) => state.settings.theme);
	const allFood = useStoreState((state) => state.foods.foods);

	return (
		<Dialog open={openFood} onClose={handleFoodClick} maxWidth="xl">
			<form>
				<DialogTitle>Add food or meal</DialogTitle>
				<DialogContent>
					<Stepper
						activeStep={activeStep}
						style={{ display: 'flex', flexWrap: 'wrap' }}
					>
						{steps.map((step, index) => {
							return (
								<Step key={index}>
									<StepLabel>{step}</StepLabel>
								</Step>
							);
						})}
					</Stepper>
					{activeStep === 0 && (
						<div
							style={{
								display: 'flex',
								width: '100%',
								justifyContent: 'center',
								marginTop: theme.spacing(2),
								marginBottom: theme.spacing(2),
							}}
						>
							<FormControl>
								<FormLabel className={classes.formLabel}>
									Type
								</FormLabel>
								<RadioGroup
									value={type || ''}
									onChange={handleTypeChange}
									style={{ flexDirection: 'row' }}
								>
									<FormControlLabel
										value="food"
										control={<Radio color="primary" />}
										label={
											<Typography
												className={classes.radio}
											>
												food
											</Typography>
										}
									/>
									<FormControlLabel
										value="meal"
										control={<Radio color="primary" />}
										label={
											<Typography
												className={classes.radio}
											>
												meal
											</Typography>
										}
									/>
								</RadioGroup>
							</FormControl>
						</div>
					)}
					{activeStep === 1 && type === 'food' && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								justifyContent: 'center',
								marginTop: theme.spacing(2),
								marginBottom: theme.spacing(2),
							}}
						>
							<div className={classes.food}>
								<Typography
									className={classes.foodItem}
									style={{ fontWeight: 'bold' }}
								>
									Name
								</Typography>
								<TextField
									inputRef={register(
										activeStep === 1
											? {
													required: true,
													minLength: 1,
													maxLength: 50,
											  }
											: undefined,
									)}
									name="name"
									className={classes.foodItem}
									autoComplete="off"
									size="small"
									variant="outlined"
									style={{ width: 245 }}
									error={errors.name ? true : false}
									defaultValue={food ? food.name : ''}
									InputProps={{ style: { height: 40 } }}
								/>
							</div>
							<div className={classes.food}>
								<Typography
									className={classes.foodItem}
									style={{ fontWeight: 'bold' }}
								>
									Amount
								</Typography>
								<TextField
									inputRef={register(
										activeStep === 1
											? {
													required: true,
													validate: (value: string) =>
														Number(value) > 0,
											  }
											: undefined,
									)}
									name="amount"
									className={classes.foodItem}
									autoComplete="off"
									size="small"
									variant="outlined"
									style={{
										width: 112,
									}}
									error={errors.amount ? true : false}
									defaultValue={food ? food.amount : ''}
									InputProps={{ style: { height: 40 } }}
								/>
								<FormControl className={classes.foodItem}>
									<Controller
										name="unit"
										control={control}
										defaultValue={
											food && food.unit
												? food.unit
												: units[0]
										}
										as={
											<Select className={classes.select}>
												{units.map((unit, index) => (
													<MenuItem
														value={unit}
														key={index}
													>
														{unit}
													</MenuItem>
												))}
											</Select>
										}
									/>
								</FormControl>
							</div>
							<div className={classes.food}>
								<Typography
									className={classes.foodItem}
									style={{ fontWeight: 'bold' }}
								>
									Calories (C)
								</Typography>
								<TextField
									inputRef={register(
										activeStep === 1
											? {
													validate: (value: string) =>
														Number(value) >= 0,
											  }
											: undefined,
									)}
									name="calories"
									className={classes.foodItem}
									autoComplete="off"
									size="small"
									variant="outlined"
									style={{ width: 112 }}
									error={errors.calories ? true : false}
									defaultValue={food ? food.calories : ''}
									InputProps={{ style: { height: 40 } }}
								/>
							</div>
							<div className={classes.food}>
								<Typography
									className={classes.foodItem}
									style={{ fontWeight: 'bold' }}
								>
									Carbs (g)
								</Typography>
								<TextField
									inputRef={register(
										activeStep === 1
											? {
													validate: (value: string) =>
														Number(value) >= 0,
											  }
											: undefined,
									)}
									name="carbs"
									className={classes.foodItem}
									autoComplete="off"
									size="small"
									variant="outlined"
									style={{ width: 112 }}
									error={errors.carbs ? true : false}
									defaultValue={food ? food.carbs : ''}
									InputProps={{ style: { height: 40 } }}
								/>
							</div>
							<div className={classes.food}>
								<Typography
									className={classes.foodItem}
									style={{ fontWeight: 'bold' }}
								>
									Fat (g)
								</Typography>
								<TextField
									inputRef={register(
										activeStep === 1
											? {
													validate: (value: string) =>
														Number(value) >= 0,
											  }
											: undefined,
									)}
									name="fat"
									className={classes.foodItem}
									autoComplete="off"
									size="small"
									variant="outlined"
									style={{ width: 112 }}
									error={errors.fat ? true : false}
									defaultValue={food ? food.fat : ''}
									InputProps={{ style: { height: 40 } }}
								/>
							</div>
							<div className={classes.food}>
								<Typography
									className={classes.foodItem}
									style={{ fontWeight: 'bold' }}
								>
									Protein (g)
								</Typography>
								<TextField
									inputRef={register(
										activeStep === 1
											? {
													validate: (value: string) =>
														Number(value) >= 0,
											  }
											: undefined,
									)}
									name="protein"
									className={classes.foodItem}
									autoComplete="off"
									size="small"
									variant="outlined"
									style={{ width: 112 }}
									error={errors.protein ? true : false}
									defaultValue={food ? food.protein : ''}
									InputProps={{ style: { height: 40 } }}
								/>
							</div>
						</div>
					)}
					{activeStep === 1 && type === 'meal' && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								justifyContent: 'center',
								marginTop: theme.spacing(2),
								marginBottom: theme.spacing(2),
							}}
						>
							<div className={classes.food}>
								<Typography
									className={classes.foodItem}
									style={{ fontWeight: 'bold' }}
								>
									Name
								</Typography>
								<TextField
									inputRef={register(
										activeStep === 1
											? {
													required: true,
													minLength: 1,
													maxLength: 50,
											  }
											: undefined,
									)}
									name="name"
									className={classes.foodItem}
									autoComplete="off"
									size="small"
									variant="outlined"
									style={{ width: 245 }}
									error={errors.name ? true : false}
									defaultValue={food ? food.name : ''}
									InputProps={{ style: { height: 40 } }}
								/>
							</div>
							<form className={classes.food}>
								<Controller
									name="food"
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									onChange={([, data]: [any, any]) => data}
									defaultValue={null}
									control={mealControl}
									render={({ onChange, ...props }) => (
										<Autocomplete
											{...props}
											options={allFood}
											getOptionLabel={(food) =>
												`${food.name}, ${food.amount} ${food.unit}`
											}
											className={classes.foodItem}
											ListboxProps={{
												style: { maxHeight: 300 },
											}}
											onChange={(_e, data) =>
												onChange(data)
											}
											renderInput={(params) => (
												<TextField
													{...params}
													size="small"
													variant="outlined"
													style={{ width: 250 }}
													InputProps={{
														...params.InputProps,
														placeholder: 'Search',
														style: { height: 40 },
													}}
												/>
											)}
										/>
									)}
								/>
								<FormControl className={classes.foodItem}>
									<Controller
										name="unit"
										control={mealControl}
										defaultValue="quantity"
										as={
											<Select className={classes.select}>
												<MenuItem value="quantity">
													quantity
												</MenuItem>
												<MenuItem value="amount">
													amount
												</MenuItem>
											</Select>
										}
									/>
								</FormControl>
								<TextField
									name="value"
									inputRef={mealRegister(
										activeStep === 1
											? {
													validate: (value: string) =>
														Number(value) > 0,
											  }
											: undefined,
									)}
									autoComplete="off"
									size="small"
									variant="outlined"
									error={mealErrors.value ? true : false}
									style={{ width: 112 }}
									placeholder={
										watchUnit === 'quantity'
											? 'Qty'
											: watchUnit === 'amount'
											? `Amt ${
													watchFood && watchFood.unit
														? `(${watchFood.unit})`
														: ''
											  }`
											: 'Qty'
									}
									className={classes.foodItem}
									InputProps={{ style: { height: 40 } }}
								/>
								<Button
									type="submit"
									disabled={!watchFood || !watchValue}
									className={classes.button}
									onClick={mealHandleSubmit(
										handleMealConfirmClick,
									)}
								>
									Add
								</Button>
							</form>
							{!!mealFood.length && (
								<div className={classes.food}>
									<Typography
										className={classes.foodItem}
										style={{ fontWeight: 'bold' }}
									>
										Ingredients
									</Typography>
								</div>
							)}
							<div style={{ maxHeight: 250, overflow: 'auto' }}>
								{mealFood.map((item, index) => {
									const amount =
										item.unit === 'quantity'
											? Number(item.value) *
											  item.food.amount
											: Number(item.value);

									return (
										<div
											key={index}
											className={classes.food}
										>
											<Typography
												className={classes.foodItem}
											>
												{`${item.food.name}, ${amount} ${item.food.unit}`}
											</Typography>
											<IconButton
												className={classes.iconButton}
												onClick={() =>
													deleteMealFood(index)
												}
											>
												<DeleteIcon
													className={classes.icon}
												/>
											</IconButton>
										</div>
									);
								})}
							</div>
							{error === 1 && (
								<div className={classes.food}>
									<Alert
										severity="error"
										variant="filled"
										style={{ width: '100%' }}
										className={classes.foodItem}
									>
										Minimum one item required
									</Alert>
								</div>
							)}
						</div>
					)}
					{activeStep === 2 && type === 'food' && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								justifyContent: 'center',
								marginTop: theme.spacing(2),
								marginBottom: theme.spacing(2),
							}}
						>
							<div
								className={classes.food}
								style={{ justifyContent: 'center' }}
							>
								<Typography
									className={classes.foodItem}
									variant="h5"
								>
									{food
										? `${food.name}, ${food.amount}${food.unit}`
										: ''}
								</Typography>
							</div>
							<Divider />
							<div className={classes.food}>
								<Typography className={classes.foodItem}>
									{food
										? `Calories: ${food.calories || 0} C`
										: ''}
								</Typography>
							</div>
							<div className={classes.food}>
								<Typography className={classes.foodItem}>
									{food ? `Carbs: ${food.carbs || 0} g` : ''}
								</Typography>
							</div>
							<div className={classes.food}>
								<Typography className={classes.foodItem}>
									{food ? `Fat: ${food.fat || 0} g` : ''}
								</Typography>
							</div>
							<div className={classes.food}>
								<Typography className={classes.foodItem}>
									{food
										? `Protein: ${food.protein || 0} g`
										: ''}
								</Typography>
							</div>
							{error === 409 && (
								<div className={classes.food}>
									<Alert
										severity="error"
										variant="filled"
										style={{ width: '100%' }}
										className={classes.foodItem}
									>
										{food
											? `${food.name} already exists`
											: 'food already exists'}
									</Alert>
								</div>
							)}
						</div>
					)}
					{activeStep === 2 && type === 'meal' && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
								justifyContent: 'center',
								marginTop: theme.spacing(2),
								marginBottom: theme.spacing(2),
							}}
						>
							<div
								className={classes.food}
								style={{ justifyContent: 'center' }}
							>
								<Typography
									className={classes.foodItem}
									variant="h5"
								>
									{food ? food.name : ''}
								</Typography>
							</div>
							<Divider />
							{mealFood.map((item) => {
								const amount =
									item.unit === 'quantity'
										? Number(item.value) * item.food.amount
										: Number(item.value);

								return (
									<div className={classes.food}>
										<Typography
											className={classes.foodItem}
										>
											{`${item.food.name}, ${amount} ${item.food.unit}`}
										</Typography>
									</div>
								);
							})}
							<Divider />
							<div className={classes.food}>
								<Typography className={classes.foodItem}>
									{`Calories: ${getMealTotal(
										mealFood,
										'calories',
									)} C`}
								</Typography>
							</div>
							<div className={classes.food}>
								<Typography className={classes.foodItem}>
									{`Carbs: ${getMealTotal(
										mealFood,
										'carbs',
									)} g`}
								</Typography>
							</div>
							<div className={classes.food}>
								<Typography className={classes.foodItem}>
									{`Fat: ${getMealTotal(mealFood, 'fat')} g`}
								</Typography>
							</div>
							<div className={classes.food}>
								<Typography className={classes.foodItem}>
									{`Protein: ${getMealTotal(
										mealFood,
										'protein',
									)} g`}
								</Typography>
							</div>
							{error === 409 && (
								<div className={classes.food}>
									<Alert
										severity="error"
										variant="filled"
										style={{ width: '100%' }}
										className={classes.foodItem}
									>
										{food
											? `${food.name} already exists`
											: 'meal already exists'}
									</Alert>
								</div>
							)}
						</div>
					)}
				</DialogContent>
				<DialogActions style={{ display: 'flex', flexWrap: 'wrap' }}>
					<Button
						className={classes.primaryButton}
						disabled={activeStep === 0}
						onClick={handleBack}
					>
						Back
					</Button>
					<Button
						type="submit"
						className={classes.primaryButton}
						disabled={activeStep === 0 && !type}
						onClick={handleSubmit(handleFoodConfirmClick)}
					>
						{activeStep === steps.length - 1 ? 'Confirm' : 'Next'}
					</Button>
					<Button
						onClick={handleFoodClick}
						className={classes.secondaryButton}
					>
						Cancel
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default Food;
