import axios from 'axios';
import decode from 'jwt-decode';

import store from '../store';

export interface Payload {
	id: string;
}

const configureInterceptors = (): void => {
	axios.interceptors.response.use(
		(res) => {
			const token = res.data.token;
			if (token) {
				localStorage.setItem('JWT', token);
			}
			return res;
		},
		(err) => {
			if (err.response) {
				if (err.response.status === 401) {
					store.getActions().user.setAuth(false);
				}
			}
			return Promise.reject(err);
		},
	);
	axios.interceptors.request.use(
		(req) => {
			const actualId = store.getState().user.user.id;
			if (actualId) {
				const token = req.headers['authorization'];
				if (token && token.split(' ').length === 2) {
					const { id } = decode(token.split(' ')[1]) as Payload;
					if (id !== actualId) {
						store.dispatch.reset();
						store.dispatch.setResetMessage(true);
						throw new axios.Cancel(
							'Request cancelled due to token mismatch',
						);
					}
				}
			}

			return req;
		},
		(err) => {
			return Promise.reject(err);
		},
	);
};

export default configureInterceptors;
