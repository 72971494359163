import React, { useState, useEffect } from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Chip, Divider, IconButton, Typography } from '@material-ui/core';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { useStyles } from './styles';
import { useStoreActions, useStoreState } from '../../../store';
import { ActivityDetails } from '../../../models/activity';
import {
	formatDistance,
	formatElevation,
	formatTime,
} from '../../../utils/formatters';
import Tooltip from '../../Tooltip';
import { Entry } from '../../../models/foods';
import {
	getBmr,
	getCaloriesEaten,
	getClosestDate,
	getExtraCalories,
} from '../../Log/Day/utils';
import { Health } from '../../../models/health';
import { User } from '../../../models/user';
import DistanceGraph from './DistanceGraph';
import CalorieGraph from './CalorieGraph';
import useWindowDimensions from '../../../utils/windowSize';
import FootprintIcon from '../../Icons/FootprintIcon';
import { IMacros } from '../../Day/Macros';
import { getEntryMacros } from '../../Day/Macros/utils';

const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

const getActivities = (
	activities: ActivityDetails[],
	startDate: Date,
	endDate: Date,
) => {
	return activities.filter((activity) => {
		if (
			Number(new Date(activity.date)) >= Number(startDate) &&
			Number(new Date(activity.date)) <= Number(endDate)
		) {
			return true;
		}
	});
};

const getEntries = (entries: Entry[], startDate: Date, endDate: Date) => {
	return entries.filter((entry) => {
		if (
			Number(new Date(entry.date)) >= Number(startDate) &&
			Number(new Date(entry.date)) <= Number(endDate)
		) {
			return true;
		}
	});
};

const getTotalDistance = (activities: ActivityDetails[]) => {
	return activities.reduce((acc, curr) => {
		return acc + curr.distance;
	}, 0);
};

const getTotalTime = (activities: ActivityDetails[]) => {
	return activities.reduce((acc, curr) => {
		return acc + curr.time;
	}, 0);
};

const getAveragePace = (activities: ActivityDetails[]) => {
	return (
		activities.reduce((acc, curr) => {
			return acc + curr.avgPace * curr.distance;
		}, 0) / getTotalDistance(activities)
	);
};

const getAverageCadence = (activities: ActivityDetails[]) => {
	return Math.round(
		(activities.reduce((acc, curr) => {
			return acc + curr.avgCadence * curr.distance;
		}, 0) /
			getTotalDistance(activities)) *
			2,
	);
};

const getAverageHeartRate = (activities: ActivityDetails[]) => {
	let totalDistance = 0;
	const reduced = activities.reduce((acc, curr) => {
		if (curr.avgHeartRate) {
			totalDistance += curr.distance;
			return acc + curr.avgHeartRate * curr.distance;
		}
		return acc;
	}, 0);
	return totalDistance !== 0 ? Math.round(reduced / totalDistance) : 'n/a';
};

const getTotalElevation = (activities: ActivityDetails[]) => {
	return activities.reduce((acc, curr) => {
		if (curr.ascent) {
			return acc + curr.ascent;
		}
		return acc;
	}, 0);
};

const getTotalCaloriesEaten = (entries: Entry[]) => {
	return Math.round(
		entries.reduce((acc, curr) => {
			return acc + curr.calories;
		}, 0),
	);
};

const getTotalCalories = (activities: ActivityDetails[]) => {
	return activities.reduce((acc, curr) => {
		return acc + curr.calories;
	}, 0);
};

export interface Shoe {
	name: string;
	distance: number;
}

export interface Meal {
	name: string;
	number: number;
}

const getShoes = (activities: ActivityDetails[]) => {
	return activities.reduce((acc, curr) => {
		curr.shoes.forEach((shoe) => {
			const found = acc.findIndex(
				(foundShoe) => foundShoe.name === shoe.name,
			);
			if (found !== -1) {
				acc[found].distance += shoe.distance;
			} else {
				acc.push({ name: shoe.name, distance: shoe.distance });
			}
		});
		return acc;
	}, [] as Shoe[]);
};

const getMeals = (entries: Entry[]) => {
	return entries.reduce((acc, curr) => {
		if (curr.amount === 0) {
			const found = acc.findIndex(
				(foundMeal) => foundMeal.name === curr.name,
			);
			if (found !== -1) {
				acc[found].number += 1;
			} else {
				acc.push({ name: curr.name, number: 1 });
			}
		}
		return acc;
	}, [] as Meal[]);
};

const getMacros = (health: Health | undefined, calories: number) => {
	if (!health) {
		return {
			carbs: 0,
			fat: 0,
			protein: 0,
		};
	}

	const carbs = Math.round((calories * health.carbs * 0.01) / 4);
	const fat = Math.round((calories * health.fat * 0.01) / 9);
	const protein = Math.round((calories * health.protein * 0.01) / 4);

	return {
		carbs,
		fat,
		protein,
	};
};

export const getDailyCalories = (
	activities: ActivityDetails[],
	weekday: number,
): number => {
	const calories = activities.reduce((acc, curr) => {
		if (moment(curr.date).isoWeekday() - 1 === weekday) {
			return acc + curr.calories;
		}
		return acc;
	}, 0);
	return calories;
};

export interface GraphData {
	id: string;
	distance: number;
	caloriesEaten: number;
	totalCalories: number;
}

const getGraphData = (
	activities: ActivityDetails[],
	entries: Entry[],
	startDate: Date,
	healthStats: Health[],
	user: User,
	days: number,
) => {
	const graphData: GraphData[] = [];
	dayNames.forEach((day) => {
		graphData.push({
			id: day,
			distance: 0,
			caloriesEaten: 0,
			totalCalories: 0,
		});
	});

	for (let i = 0; i < days; i++) {
		const filteredActivities = activities.filter(
			(activity) => moment(activity.date).isoWeekday() - 1 === i,
		);
		graphData[i].distance += getTotalDistance(filteredActivities);
		const filteredEntries = entries.filter(
			(entry) => moment(entry.date).isoWeekday() - 1 === i,
		);
		graphData[i].caloriesEaten = getTotalCaloriesEaten(filteredEntries);
		let totalCalories = 0;
		const date = moment(startDate).add(i, 'd').toISOString();
		const health = getClosestDate(date, healthStats);
		totalCalories += getBmr(health, user, date);
		totalCalories += getExtraCalories(health, date);
		totalCalories += getTotalCalories(filteredActivities);
		graphData[i].totalCalories = totalCalories;
	}

	return graphData;
};

const Summary: React.FC = () => {
	const classes = useStyles();

	const { width } = useWindowDimensions();

	const user = useStoreState((state) => state.user.user);
	const paceUnit = useStoreState((state) => state.settings.paceUnit);
	const allEntries = useStoreState((state) => state.foods.allEntries);
	const healthStats = useStoreState((state) => state.health.healthStats);
	const allActivities = useStoreState((state) => state.activities.activities);
	const selectedBar = useStoreState((state) => state.dashboard.selectedBar);
	const selectedDate = useStoreState((state) => state.dashboard.selectedDate);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const elevationUnit = useStoreState(
		(state) => state.settings.elevationUnit,
	);
	const setDate = useStoreActions((actions) => actions.log.setDate);
	const setWeek = useStoreActions((actions) => actions.log.setWeek);

	const [shoes, setShoes] = useState<Shoe[]>([]);
	const [meals, setMeals] = useState<Meal[]>([]);
	const [data, setData] = useState<GraphData[]>([]);
	const [endDate, setEndDate] = useState<Date>(new Date());
	const [startDate, setStartDate] = useState<Date>(new Date());
	const [entries, setEntries] = useState<Entry[]>([]);
	const [activities, setActivities] = useState<ActivityDetails[]>([]);
	const [days, setDays] = useState(0);
	const [macros, setMacros] = useState<IMacros>({
		carbs: 0,
		fat: 0,
		protein: 0,
	});
	const [entryMacros, setEntryMacros] = useState<IMacros>({
		carbs: 0,
		fat: 0,
		protein: 0,
	});
	const [caloriesEaten, setCaloriesEaten] = useState(0);
	const [totalCalories, setTotalCalories] = useState(0);

	const handleTrainingLogClick = () => {
		setDate(startDate);
		setWeek(0);
	};

	useEffect(() => {
		const fetchData = () => {
			const newStartDate = moment(selectedDate)
				.subtract(51 - selectedBar, 'w')
				.startOf('isoWeek')
				.toDate();
			setStartDate(newStartDate);
			const newEndDate = moment(selectedDate)
				.subtract(51 - selectedBar, 'w')
				.endOf('isoWeek')
				.toDate();
			setEndDate(newEndDate);
			const newActivities = getActivities(
				allActivities,
				newStartDate,
				newEndDate,
			);
			setActivities(newActivities);
			const newEntries = getEntries(allEntries, newStartDate, newEndDate);
			setEntries(newEntries);

			const date =
				selectedBar === 51 ? moment(selectedDate) : moment(newEndDate);
			const newDays = date.diff(moment(newStartDate), 'days') + 1;
			setDays(newDays);

			let newTotalCalories = 0;
			const newMacros: IMacros = { carbs: 0, fat: 0, protein: 0 };
			const newCaloriesEaten = getCaloriesEaten(newEntries);
			for (let i = 0; i < newDays; i++) {
				const date = moment(newStartDate).add(i, 'd').toISOString();
				const health = getClosestDate(date, healthStats);

				let dailyCalories = 0;
				dailyCalories += getBmr(health, user, date);
				dailyCalories += getExtraCalories(health, date);
				dailyCalories += getDailyCalories(newActivities, i);
				const { carbs, fat, protein } = getMacros(
					health,
					dailyCalories,
				);
				newMacros.carbs += carbs;
				newMacros.fat += fat;
				newMacros.protein += protein;
				newTotalCalories += dailyCalories;
			}
			setCaloriesEaten(newCaloriesEaten);
			setTotalCalories(newTotalCalories);
			setMacros(newMacros);
			setEntryMacros(getEntryMacros(newEntries));
		};
		fetchData();
	}, [
		selectedBar,
		selectedDate,
		allActivities,
		allEntries,
		healthStats,
		user,
	]);

	useEffect(() => {
		setData(
			getGraphData(
				activities,
				entries,
				startDate,
				healthStats,
				user,
				days,
			),
		);
	}, [activities, entries, healthStats, user, endDate, startDate, days]);

	useEffect(() => {
		setShoes(getShoes(activities));
	}, [activities]);

	useEffect(() => {
		setMeals(getMeals(entries));
	}, [entries]);

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<div className={classes.title}>
					<Typography
						variant="subtitle1"
						component={Link}
						onClick={handleTrainingLogClick}
						to={'/log'}
						className={classes.link}
					>
						{`${moment(startDate).format(
							'ddd Do MMM yyyy',
						)} - ${moment(endDate).format('ddd Do MMM yyyy')}`}
					</Typography>
					<Typography variant="h5">Weekly Summary</Typography>
				</div>
				<div className={classes.info}>
					<div className={classes.infoItem}>
						<Typography variant="subtitle1">
							Total Distance
						</Typography>
						<Typography
							variant="h5"
							style={{ fontWeight: 'lighter' }}
						>
							{formatDistance(
								getTotalDistance(activities),
								distanceUnit,
							)}
						</Typography>
					</div>
					<div className={classes.infoItem}>
						<Typography variant="subtitle1">
							Calories Eaten
						</Typography>
						<Typography
							variant="h5"
							style={{ fontWeight: 'lighter' }}
						>
							{`${caloriesEaten} C`}
						</Typography>
					</div>
					<div className={classes.infoItem}>
						<Typography variant="subtitle1">
							Total Calories
						</Typography>
						<Typography
							variant="h5"
							style={{ fontWeight: 'lighter' }}
						>
							{`${totalCalories} C`}
						</Typography>
					</div>
				</div>
				<div className={classes.icons}>
					<Tooltip title="View in training log">
						<IconButton
							className={classes.iconButton}
							component={Link}
							onClick={handleTrainingLogClick}
							to={'/log'}
						>
							<ChevronRightIcon
								fontSize="large"
								className={classes.icon}
							/>
						</IconButton>
					</Tooltip>
				</div>
			</div>
			<Divider className={classes.divider} />
			<div className={classes.detailsHeader}>
				{!!activities.length && (
					<div>
						<Typography variant="h5">Activities</Typography>
					</div>
				)}
				{!!activities.length && (
					<div className={classes.details}>
						<div
							className={classes.detailStats}
							style={{
								width:
									width > 1000
										? `calc(100% - ${500}px)`
										: '100%',
							}}
						>
							<div className={classes.infoItem}>
								<Typography variant="subtitle1">
									Total Time
								</Typography>
								<Typography
									variant="h5"
									style={{ fontWeight: 'lighter' }}
								>
									{formatTime(getTotalTime(activities))}
								</Typography>
							</div>
							<div className={classes.infoItem}>
								<Typography variant="subtitle1">
									Avg Pace
								</Typography>
								<Typography
									variant="h5"
									style={{ fontWeight: 'lighter' }}
								>
									{activities.length
										? formatTime(
												getAveragePace(activities),
												paceUnit,
										  )
										: 'n/a'}
								</Typography>
							</div>
							<div className={classes.infoItem}>
								<Typography variant="subtitle1">
									Avg Heart Rate
								</Typography>
								<Typography
									variant="h5"
									style={{ fontWeight: 'lighter' }}
								>
									{activities.length
										? `${getAverageHeartRate(
												activities,
										  )} bpm`
										: 'n/a'}
								</Typography>
							</div>
							<div className={classes.infoItem}>
								<Typography variant="subtitle1">
									Avg Cadence
								</Typography>
								<Typography
									variant="h5"
									style={{ fontWeight: 'lighter' }}
								>
									{activities.length
										? `${getAverageCadence(activities)} spm`
										: 'n/a'}
								</Typography>
							</div>
							<div className={classes.infoItem}>
								<Typography variant="subtitle1">
									Total Elevation
								</Typography>
								<Typography
									variant="h5"
									style={{ fontWeight: 'lighter' }}
								>
									{activities.length
										? formatElevation(
												getTotalElevation(activities),
												elevationUnit,
										  )
										: 'n/a'}
								</Typography>
							</div>
							{!!shoes.length && (
								<div
									style={{
										width: '100%',
									}}
								>
									<Typography variant="h5">Shoes</Typography>
								</div>
							)}
							{shoes.map((shoe, index) => {
								return (
									<div
										key={index}
										className={classes.infoItem}
									>
										<Chip
											className={classes.chip}
											label={`${
												shoe.name
											} - ${formatDistance(
												shoe.distance,
												distanceUnit,
											)}`}
											icon={
												<FootprintIcon
													className={classes.svg}
												/>
											}
										></Chip>
									</div>
								);
							})}
						</div>
						<div
							style={{
								height: 200,
								width: width > 1000 ? 500 : '100%',
							}}
						>
							<DistanceGraph data={data} />
						</div>
					</div>
				)}
			</div>
			{!!activities.length && <Divider className={classes.divider} />}
			<div className={classes.detailsHeader}>
				<div style={{ width: '100%' }}>
					<Typography variant="h5">{'Health & Nutrition'}</Typography>
				</div>
				<div className={classes.details}>
					<div
						className={classes.detailStats}
						style={{
							width:
								width > 1000 ? `calc(100% - ${500}px)` : '100%',
						}}
					>
						<div className={classes.infoItem}>
							<Typography variant="subtitle1">
								Avg Calories
							</Typography>
							<Typography
								variant="h5"
								style={{ fontWeight: 'lighter' }}
							>
								{`${Math.round(
									caloriesEaten / days,
								)} / ${Math.round(totalCalories / days)} C`}
							</Typography>
						</div>
						<div className={classes.infoItem}>
							<Typography variant="subtitle1">
								Avg Carbs
							</Typography>
							<Typography
								variant="h5"
								style={{ fontWeight: 'lighter' }}
							>
								{`${Math.round(
									entryMacros.carbs / days,
								)} / ${Math.round(macros.carbs / days)} g`}
							</Typography>
						</div>
						<div className={classes.infoItem}>
							<Typography variant="subtitle1">Avg Fat</Typography>
							<Typography
								variant="h5"
								style={{ fontWeight: 'lighter' }}
							>
								{`${Math.round(
									entryMacros.fat / days,
								)} / ${Math.round(macros.fat / days)} g`}
							</Typography>
						</div>
						<div className={classes.infoItem}>
							<Typography variant="subtitle1">
								Avg Protein
							</Typography>
							<Typography
								variant="h5"
								style={{ fontWeight: 'lighter' }}
							>
								{`${Math.round(
									entryMacros.protein / days,
								)} / ${Math.round(macros.protein / days)} g`}
							</Typography>
						</div>
						{!!meals.length && (
							<div
								style={{
									width: '100%',
								}}
							>
								<Typography variant="h5">Meals</Typography>
							</div>
						)}
						{meals.map((meal, index) => {
							return (
								<div key={index} className={classes.infoItem}>
									<Chip
										className={classes.chip}
										label={`${meal.name} x ${meal.number}`}
										icon={
											<RestaurantIcon
												className={classes.icon}
											/>
										}
									></Chip>
								</div>
							);
						})}
					</div>
					<div
						style={{
							height: 200,
							width: width > 1000 ? 500 : '100%',
						}}
					>
						<CalorieGraph data={data} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Summary;
