import React from 'react';
import Button from '@material-ui/core/Button';
import { UseFormMethods } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

import { useStylesForm as useStyles } from './styles';

export interface Props extends Pick<UseFormMethods, 'register' | 'reset'> {
	handleChangeForm: (type: string) => void;
	handleLogin: (
		// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
		e?: React.BaseSyntheticEvent<object, any, any> | undefined,
	) => Promise<void>;
}

const SignInForm: React.FC<Props> = ({
	handleChangeForm,
	handleLogin,
	register,
}) => {
	const classes = useStyles();

	return (
		<form>
			<div className={classes.form}>
				<TextField
					inputRef={register}
					label={'Email'}
					name="email"
					className={classes.formItem}
				/>
				<TextField
					inputRef={register}
					label={'Password'}
					name="password"
					type="password"
					className={classes.formItem}
				/>
				<Button
					type="submit"
					onClick={handleLogin}
					className={`${classes.formItem} ${classes.primaryButton}`}
				>
					Sign In
				</Button>
				<Button
					onClick={() => handleChangeForm('register')}
					className={`${classes.formItem} ${classes.secondaryButton}`}
				>
					Register
				</Button>
				<Button
					onClick={() => handleChangeForm('forgotten')}
					className={`${classes.formItem} ${classes.secondaryButton}`}
				>
					Forgotten Password
				</Button>
			</div>
		</form>
	);
};

export default SignInForm;
