import { action, Action } from 'easy-peasy';

export interface DashboardModel {
	date: Date;
	setDate: Action<DashboardModel, Date>;
	selectedBar: number;
	setSelectedBar: Action<DashboardModel, number>;
	selectedDate: Date;
	setSelectedDate: Action<DashboardModel, Date>;
}

const currDate = new Date();

const dashboard: DashboardModel = {
	date: currDate,
	setDate: action((state, value) => {
		state.date = value;
	}),
	selectedBar: 51,
	setSelectedBar: action((state, value) => {
		state.selectedBar = value;
	}),
	selectedDate: currDate,
	setSelectedDate: action((state, value) => {
		state.selectedDate = value;
	}),
};

export default dashboard;
