import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: '100%',
	},
	title: {
		width: '100%',
		fontWeight: 'bold',
		backgroundColor: theme.palette.background.default,
	},
}));
