import React from 'react';
import {
	Divider,
	Typography,
	Paper,
	IconButton,
	Button,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { sort } from './utils';
import Tooltip from '../../Tooltip';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import { useStoreState } from '../../../store';
import { formatDistance, formatTime } from '../../../utils/formatters';

const Activities: React.FC = () => {
	const classes = useStyles();

	const activities = useStoreState((state) => state.day.activities);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const paceUnit = useStoreState((state) => state.settings.paceUnit);

	return (
		<div className={classes.root}>
			<div className={classes.headerContainer}>
				<Typography variant="h5" className={classes.header}>
					Activities
				</Typography>
			</div>
			<Divider />
			{activities.sort(sort).map((activity, index) => {
				return (
					<Paper
						key={index}
						variant="outlined"
						className={classes.paper}
					>
						<div style={{ flex: 1 }}>
							<Button
								className={classes.button}
								variant="text"
								disableRipple={true}
								component={Link}
								to={`/activities/${activity.id}`}
							>
								{activity.name}
							</Button>
						</div>
						<div style={{ display: 'flex', flexWrap: 'wrap' }}>
							<Typography className={classes.activityDetail}>
								{formatDistance(
									activity.distance,
									distanceUnit,
								)}
							</Typography>
							<Typography className={classes.activityDetail}>
								{formatTime(activity.avgPace, paceUnit)}
							</Typography>
							<Typography className={classes.activityDetail}>
								{formatTime(activity.time)}
							</Typography>
							{!!activity.avgHeartRate && (
								<Typography className={classes.activityDetail}>
									{`${activity.avgHeartRate} bpm`}
								</Typography>
							)}
							<Typography className={classes.activityDetail}>
								{`${activity.calories} C`}
							</Typography>
						</div>
						<div
							style={{
								flex: 1,
								display: 'flex',
								justifyContent: 'flex-end',
							}}
						>
							<Tooltip title="View activity">
								<IconButton
									className={classes.iconButton}
									component={Link}
									to={`/activities/${activity.id}`}
								>
									<ChevronRightIcon
										fontSize="large"
										className={classes.icon}
									/>
								</IconButton>
							</Tooltip>
						</div>
					</Paper>
				);
			})}
		</div>
	);
};

export default Activities;
