import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import mongoose from 'mongoose';
import { CssBaseline, ThemeProvider } from '@material-ui/core';

import Header from '../Header';
import Routes from './Routes';
import Footer from '../Footer/Footer';
import globals from '../../utils/globals';
import { useStyles } from './styles';
import { useStoreActions, useStoreState } from '../../store';
import { UserData } from './interfaces';
import { axiosService } from '../../services/axios';

const App: React.FC = () => {
	const classes = useStyles();

	const theme = useStoreState((state) => state.settings.theme);
	const auth = useStoreState((state) => state.user.auth);
	const setAuth = useStoreActions((actions) => actions.user.setAuth);
	const setUser = useStoreActions((actions) => actions.user.setUser);
	const getNotifications = useStoreActions(
		(actions) => actions.notifications.getNotifications,
	);
	const removeUploads = useStoreActions(
		(actions) => actions.uploads.removeUploads,
	);
	const setSettings = useStoreActions(
		(actions) => actions.settings.setSettings,
	);

	const [loading, setLoading] = useState(true);

	const { key } = useLocation();

	useEffect(() => {
		const checkAuth = async () => {
			setLoading(true);
			const token = localStorage.getItem('JWT');
			try {
				const response = await axiosService.get('/api/user', {
					headers: {
						authorization: token,
					},
				});
				setAuth(true);
				const {
					id,
					name,
					age,
					sex,
					email,
					joined,
					settings,
				}: UserData = response.data.data;
				setUser({ id, name, age, sex, email, joined });
				setSettings(settings);
			} catch (err) {
			} finally {
				setLoading(false);
			}
		};
		if (!auth) {
			checkAuth();
		}
	}, [setAuth, auth, setUser, setSettings]);

	useEffect(() => {
		const pageChangeUpdate = async () => {
			try {
				await getNotifications();
			} catch (err) {}
		};
		pageChangeUpdate();
	}, [getNotifications, removeUploads, key]);

	useEffect(() => {
		const startNewSession = async () => {
			const session = mongoose.Types.ObjectId().toHexString();
			globals.session = session;

			try {
				await removeUploads({ removeSession: false });
			} catch (err) {}
		};
		startNewSession();
	}, [removeUploads]);

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline>
				<div className={classes.root}>
					<Header loading={loading} />
					<div className={classes.content}>
						{!loading && <Routes />}
					</div>
					<Footer />
				</div>
			</CssBaseline>
		</ThemeProvider>
	);
};

export default App;
