import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	icons: {
		display: 'flex',
		flexGrow: 1,
		alignContent: 'space-between',
		justifyContent: 'flex-end',
		height: '100%',
	},
	iconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
	},
	icon: {
		color: theme.palette.common.black,
	},
	badge: {
		backgroundColor: theme.palette.primary.main,
	},
	menuItem: {
		paddingTop: theme.spacing(),
		paddingBottom: theme.spacing(),
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
}));
