import React from 'react';

import Day from '../Day';
import { useStyles } from './styles';
import { useStoreState } from '../../../store';

const Days: React.FC = () => {
	const classes = useStyles();

	const days = useStoreState((state) => state.log.days);

	return (
		<div className={classes.root}>
			{days.map((day, index) => {
				return <Day day={day} key={index} />;
			})}
		</div>
	);
};

export default Days;
