import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useStoreState, useStoreActions } from '../../../store';
import { AddEntry } from '../../../models/foods';
import { getSelected } from '../../Activities/utils';
import { EntryData } from './interfaces';
import { getMealEntries } from './utils';
import PMeal, { Props as MealProps } from './Meal';

interface Props {
	name: string;
	number: number;
}

const Meal: React.FC<Props> = ({ name, number }) => {
	const selected = useStoreState((state) => state.foods.selected);
	const entries = useStoreState((state) => state.foods.entries);
	const date = useStoreState((state) => state.day.date);
	const addEntry = useStoreActions((actions) => actions.foods.addEntry);
	const getEntries = useStoreActions((actions) => actions.foods.getEntries);
	const removeEntry = useStoreActions((actions) => actions.foods.removeEntry);
	const setSelected = useStoreActions((actions) => actions.foods.setSelected);
	const setReset = useStoreActions((actions) => actions.foods.setReset);

	const { handleSubmit, control, reset, register, watch, errors } = useForm<
		EntryData
	>();

	const [removeOne, setRemoveOne] = useState(false);
	const [removeSelected, setRemoveSelected] = useState(false);
	const [removeId, setRemoveId] = useState('');

	const food = watch('food');
	const unit = watch('unit');
	const value = watch('value');

	const mealSelected = selected[number] || [];
	const mealEntries = getMealEntries(entries, number);

	const isSelected = (id: string) =>
		mealSelected && mealSelected.indexOf(id) !== -1;

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newSelected = Object.assign({}, selected);
		if (event.target.checked) {
			const newMealSelected = entries
				.filter((entry) => entry.mealNumber === number)
				.map((entry) => entry.id);
			newSelected[number] = newMealSelected;
			setSelected(newSelected);
			return;
		}
		newSelected[number] = [];
		setSelected(newSelected);
	};

	const handleRemoveOne = async () => {
		try {
			if (removeId) {
				await removeEntry(removeId);
				await getEntries(date);
				if (isSelected(removeId)) {
					handleCheckbox(removeId);
				}
				setRemoveOne((value) => !value);
				setReset(true);
			}
		} catch (err) {}
	};

	const handleRemoveSelected = async () => {
		try {
			for (const id of mealSelected) {
				try {
					await removeEntry(id);
				} catch (err) {}
			}
			await getEntries(date);
			const newSelected = Object.assign({}, selected);
			newSelected[number] = [];
			setSelected(newSelected);
			setRemoveSelected((value) => !value);
			setReset(true);
		} catch (err) {}
	};

	const handleCheckbox = (id: string) => {
		const newMealSelected = getSelected(id, mealSelected);
		const newSelected = Object.assign({}, selected);
		newSelected[number] = newMealSelected;
		setSelected(newSelected);
	};

	const handleMealConfirmClick = async (data: EntryData) => {
		try {
			if (data.food) {
				const params: AddEntry = {
					date,
					name: data.food.name,
					mealNumber: number,
					amount: data.value
						? data.unit === 'quantity'
							? Number(data.value) * data.food.amount
							: Number(data.value)
						: undefined,
				};
				await addEntry(params);
				await getEntries(date);
				resetData();
				setReset(true);
			}
		} catch (err) {}
	};

	const resetData = () => {
		reset({
			food: null,
			unit: 'quantity',
			value: '',
		});
	};

	const props: MealProps = {
		name,
		number,
		resetData,
		handleMealConfirmClick,
		control,
		handleSubmit,
		register,
		errors,
		handleCheckbox,
		handleRemoveSelected,
		handleRemoveOne,
		handleSelectAll,
		isSelected,
		mealEntries,
		mealSelected,
		food,
		unit,
		value,
		setRemoveId,
		setRemoveSelected,
		setRemoveOne,
		removeOne,
		removeSelected,
	};

	return <PMeal {...props} />;
};

export default Meal;
