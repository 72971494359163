import React from 'react';
import { UseFormMethods } from 'react-hook-form';

import { FilterData, FiltersData } from '../../interfaces';
import { useStoreState, useStoreActions } from '../../../../store';
import PDateFilter, { Props as DateFilterProps } from './DateFilter';

export interface Props
	extends FilterData,
		Pick<
			UseFormMethods,
			'control' | 'reset' | 'getValues' | 'register' | 'watch' | 'errors'
		> {
	filterReset: FiltersData;
}

const DateFilter: React.FC<Props> = ({
	control,
	watch,
	reset,
	getValues,
	type,
	operator,
	label,
	unit,
	secondary,
	operators,
	dataName,
	filterReset,
}) => {
	const setDate = useStoreActions((actions) => actions.activities.setDate);
	const setDateSecondary = useStoreActions(
		(actions) => actions.activities.setDateSecondary,
	);
	const filters = useStoreState((state) => state.activities.filters);
	const setFilters = useStoreActions(
		(actions) => actions.activities.setFilters,
	);
	const filtersData = useStoreState((state) => state.activities.filtersData);
	const setFiltersData = useStoreActions(
		(actions) => actions.activities.setFiltersData,
	);

	const handleDateChange = (newDate: Date | null) => {
		setDate(newDate);
	};
	const handleDateSecondaryChange = (newDate: Date | null) => {
		setDateSecondary(newDate);
	};

	const handleReset = () => {
		const newFilters = filters.filter((filter) => filter.type !== dataName);
		const newFiltersData = Object.assign({}, filtersData);
		newFiltersData[operator] = undefined;
		if (type) {
			newFiltersData[type] = undefined;
		}
		if (unit) {
			newFiltersData[unit] = undefined;
		}
		if (secondary) {
			newFiltersData[secondary] = undefined;
		}
		setFiltersData(newFiltersData);
		setFilters(newFilters);
		if (dataName === 'date') {
			setDate(null);
			setDateSecondary(null);
		}
		reset({
			...getValues(),
			...filterReset,
		});
	};

	const props: DateFilterProps = {
		control,
		watch,
		label,
		operator,
		operators,
		handleReset,
		handleDateChange,
		handleDateSecondaryChange,
	};

	return <PDateFilter {...props} />;
};

export default DateFilter;
