import React from 'react';
import { Checkbox, MenuItem, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { getSelected } from '../../utils';
import { useStoreState, useStoreActions } from '../../../../store';

const TagArray: React.FC = () => {
	const classes = useStyles();

	const tags = useStoreState((state) => state.tags.tags);
	const selectedTags = useStoreState(
		(state) => state.activities.selectedTags,
	);
	const setSelectedTags = useStoreActions(
		(actions) => actions.activities.setSelectedTags,
	);

	const isSelected = (name: string) => selectedTags.indexOf(name) !== -1;

	const handleCheckbox = (name: string) => {
		const newSelected = getSelected(name, selectedTags);
		setSelectedTags(newSelected);
	};

	return (
		<div>
			{tags.map((tag) => {
				const { id, name } = tag;
				const isItemSelected = isSelected(name);

				return (
					<MenuItem key={id} className={classes.menuItem}>
						<Typography>{name}</Typography>
						<Checkbox
							color="primary"
							checked={isItemSelected}
							onClick={() => handleCheckbox(name)}
						/>
					</MenuItem>
				);
			})}
		</div>
	);
};

export default TagArray;
