export const menuItems = [
	{
		menuTitle: 'Dashboard',
		pageURL: '/dashboard',
	},
	{
		menuTitle: 'Training Log',
		pageURL: '/log',
	},
	{
		menuTitle: 'Activities',
		pageURL: '/activities',
	},
	{
		menuTitle: 'Statistics',
		pageURL: '/statistics',
	},
];
