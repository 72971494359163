import React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { isDesktop } from 'react-device-detect';
import MapGL, { Marker, NavigationControl, ViewportProps } from 'react-map-gl';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Overlay from './Overlay';
import { useStoreState } from '../../../store';
import { PositionStream } from '../../../models/activity';

export interface Props {
	coordinates: PositionStream[];
	viewport: ViewportProps;
	map: {
		mapboxApiAccessToken: string;
		mapStyle: string;
	};
	handleViewportChange: (viewport: ViewportProps) => void;
	handleResize: ({
		width,
		height,
	}: {
		width: number;
		height: number;
	}) => void;
}

const Map: React.FC<Props> = ({
	coordinates,
	viewport,
	map,
	handleViewportChange,
	handleResize,
}) => {
	const mapPosition = useStoreState((state) => state.activity.mapPosition);
	const theme = useStoreState((state) => state.settings.theme);

	return (
		<MapGL
			{...viewport}
			mapboxApiAccessToken={map.mapboxApiAccessToken}
			onViewportChange={handleViewportChange}
			mapStyle={map.mapStyle}
			onResize={handleResize}
			dragPan={isDesktop}
			dragRotate={isDesktop}
			scrollZoom={false}
			touchAction="pan-y"
		>
			<Overlay points={coordinates} />
			<Overlay points={coordinates} highlight />
			<Overlay points={coordinates} highlight fixedHighlight />
			<Marker
				latitude={coordinates[mapPosition].latitude}
				longitude={coordinates[mapPosition].longitude}
				offsetLeft={-12}
				offsetTop={-12}
			>
				<FiberManualRecordIcon
					style={{
						color: theme.palette.defaultWhite,
					}}
				></FiberManualRecordIcon>
			</Marker>
			<Marker
				latitude={coordinates[mapPosition].latitude}
				longitude={coordinates[mapPosition].longitude}
				offsetLeft={-10}
				offsetTop={-10}
			>
				<FiberManualRecordIcon
					style={{
						color: theme.palette.primary.dark,
					}}
					fontSize="small"
				></FiberManualRecordIcon>
			</Marker>
			<Marker
				latitude={coordinates[coordinates.length - 1].latitude}
				longitude={coordinates[coordinates.length - 1].longitude}
				offsetLeft={-12}
				offsetTop={-12}
			>
				<FiberManualRecordIcon
					style={{
						color: theme.palette.defaultWhite,
					}}
				></FiberManualRecordIcon>
			</Marker>
			<Marker
				latitude={coordinates[coordinates.length - 1].latitude}
				longitude={coordinates[coordinates.length - 1].longitude}
				offsetLeft={-10}
				offsetTop={-10}
			>
				<FiberManualRecordIcon
					style={{
						color: theme.palette.error.main,
					}}
					fontSize="small"
				></FiberManualRecordIcon>
			</Marker>
			<Marker
				latitude={coordinates[0].latitude}
				longitude={coordinates[0].longitude}
				offsetLeft={-12}
				offsetTop={-12}
			>
				<FiberManualRecordIcon
					style={{
						color: theme.palette.defaultWhite,
					}}
				></FiberManualRecordIcon>
			</Marker>
			<Marker
				latitude={coordinates[0].latitude}
				longitude={coordinates[0].longitude}
				offsetLeft={-10}
				offsetTop={-10}
			>
				<FiberManualRecordIcon
					style={{
						color: theme.palette.success.main,
					}}
					fontSize="small"
				></FiberManualRecordIcon>
			</Marker>
			<div
				style={{
					position: 'absolute',
					top: theme.spacing(),
					left: theme.spacing(),
				}}
			>
				<NavigationControl onViewportChange={handleViewportChange} />
			</div>
		</MapGL>
	);
};

export default Map;
