import { ActivityDetails } from '../../../models/activity';
import { Shoe } from '../../Dashboard/Summary';

export const getDistance = (activities: ActivityDetails[]): number => {
	return activities.reduce((acc, curr) => {
		return acc + curr.distance;
	}, 0);
};

export const getTime = (activities: ActivityDetails[]): number => {
	return activities.reduce((acc, curr) => {
		return acc + curr.time;
	}, 0);
};

export const getElevation = (activities: ActivityDetails[]): number => {
	return activities.reduce((acc, curr) => {
		return acc + (Number(curr.ascent) || 0);
	}, 0);
};

export const getCalories = (activities: ActivityDetails[]): number => {
	return activities.reduce((acc, curr) => {
		return acc + curr.calories;
	}, 0);
};

export const getActivities = (
	activities: ActivityDetails[],
	startDate: Date,
	endDate: Date,
): ActivityDetails[] => {
	return activities.filter((activity) => {
		if (
			Number(new Date(activity.date)) >= Number(startDate) &&
			Number(new Date(activity.date)) <= Number(endDate)
		) {
			return true;
		}
	});
};

export const getShoes = (activities: ActivityDetails[]): Shoe[] => {
	return activities.reduce((acc, curr) => {
		curr.shoes.forEach((shoe) => {
			const found = acc.findIndex(
				(foundShoe) => foundShoe.name === shoe.name,
			);
			if (found !== -1) {
				acc[found].distance += shoe.distance;
			} else {
				acc.push({ name: shoe.name, distance: shoe.distance });
			}
		});
		return acc;
	}, [] as Shoe[]);
};

export const formatShoes = (shoes: Shoe[]): Shoe[] => {
	return shoes
		.sort((a: Shoe, b: Shoe) => b.distance - a.distance)
		.filter((shoe) => !!shoe.distance);
};
