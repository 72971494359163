import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	primaryButton: {
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		minWidth: 100,
		color: theme.palette.defaultBlack,
	},
	secondaryButton: {
		minWidth: 100,
		backgroundColor: theme.palette.grey[300],
		'&:hover': {
			backgroundColor: theme.palette.grey.A100,
		},
		color: theme.palette.defaultBlack,
	},
	health: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
	},
	healthItem: {
		margin: theme.spacing(),
	},
	alert: {
		width: '100%',
	},
}));
