import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import { useStoreActions, useStoreState } from '../../../../../store';
import PTitleForm, { Props as TitleFormProps } from './TitleForm';

export interface FormData {
	name: string;
}

interface Props {
	setEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const TitleForm: React.FC<Props> = ({ setEdit }) => {
	const activity = useStoreState((state) => state.activity.activity);
	const updateActivity = useStoreActions(
		(actions) => actions.activity.updateActivity,
	);
	const setActivityDetails = useStoreActions(
		(actions) => actions.activity.setActivityDetails,
	);
	const getActivities = useStoreActions(
		(actions) => actions.activities.getActivities,
	);

	const { handleSubmit, register, errors } = useForm<FormData>();

	const [loading, setLoading] = useState(false);

	const handleFormSubmit = async (data: FormData) => {
		setLoading(true);
		try {
			if (activity) {
				const updatedActivity = await updateActivity({
					activityId: activity.id,
					params: { ...data },
				});
				setActivityDetails(updatedActivity);
				await getActivities();
			}
		} catch (err) {
		} finally {
			setEdit(false);
			setLoading(false);
		}
	};

	const props: TitleFormProps = {
		handleFormSubmit,
		register,
		errors,
		loading,
		handleSubmit,
		setEdit,
	};

	return <PTitleForm {...props} />;
};

export default TitleForm;
