import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import Form from './Form';
import Error from './Error';
import { useStyles } from './styles';
import { useStoreState } from '../../store';

const Login: React.FC = () => {
	const classes = useStyles();

	const auth = useStoreState((state) => state.user.auth);

	const [error, setError] = useState(0);

	return !auth ? (
		<div className={classes.root}>
			<Form setError={setError} />
			<Error error={error} />
		</div>
	) : (
		<Redirect to={'/dashboard'} />
	);
};

export default Login;
