import { useMediaQuery } from '@material-ui/core';
import React from 'react';

import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import { Lap } from '../../../models/activity';
import { useStoreState } from '../../../store';
import useWindowDimensions from '../../../utils/windowSize';
import TooltipContent from './TooltipContent';

export interface Props {
	data: Lap[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleMouse: (state: any) => void;
	handleClick: () => void;
	focusBar: number | null;
	clickedBar: number | null;
	paceRange: number[];
}

const LapGraph: React.FC<Props> = ({
	data,
	handleMouse,
	handleClick,
	focusBar,
	clickedBar,
	paceRange,
}) => {
	const { width } = useWindowDimensions();

	const theme = useStoreState((state) => state.settings.theme);
	const isTablet = useMediaQuery(theme.breakpoints.down('sm'));

	const handleTick = (value: number) => {
		if (
			(value - 1) %
				Math.round(
					data.length / (isTablet && width >= 750 ? 30 : 15),
				) ===
			0
		) {
			return value;
		}
		return '';
	};

	return (
		<ResponsiveContainer width="100%" height="100%">
			<BarChart
				data={data}
				onClick={handleClick}
				onMouseMove={handleMouse}
				onMouseLeave={handleMouse}
				style={{ cursor: 'pointer' }}
			>
				<CartesianGrid vertical={false} horizontal={false} />
				<XAxis
					dataKey="number"
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					tickFormatter={handleTick as any}
					interval={0}
				/>
				<YAxis
					tick={false}
					width={0}
					domain={[paceRange[0] - paceRange[0] * 0.1, paceRange[1]]}
				/>
				<Tooltip
					cursor={false}
					content={<TooltipContent />}
					animationDuration={100}
				/>
				<Bar
					dataKey="avgPace"
					fill={theme.palette.primary.light}
					radius={[2, 2, 0, 0]}
				>
					{data.map((_entry, index) => (
						<Cell
							key={`cell-${index}`}
							fill={
								focusBar === index || clickedBar === index
									? theme.palette.primary.main
									: theme.palette.primary.light
							}
						/>
					))}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};

export default LapGraph;
