import React from 'react';
import { Link } from 'react-router-dom';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

import PLogo, { Props as LogoProps } from './Logo';

export interface Props {
	loading: boolean;
	login: boolean;
}

const Logo: React.FC<Props> = ({ loading, login }) => {
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

	const props: LogoProps = {
		component: loading || login ? undefined : Link,
		to: loading || login ? undefined : '/dashboard',
		isMobile,
	};

	return <PLogo {...props} />;
};

export default Logo;
