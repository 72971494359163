import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	primaryButton: {
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		color: theme.palette.defaultBlack,
		minWidth: 100,
	},
	secondaryButton: {
		minWidth: 100,
		backgroundColor: theme.palette.grey[300],
		'&:hover': {
			backgroundColor: theme.palette.grey.A100,
		},
		color: theme.palette.defaultBlack,
	},
}));
