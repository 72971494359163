import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	primaryButton: {
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		minWidth: 100,
		color: theme.palette.defaultBlack,
		marginTop: theme.spacing(),
	},
	secondaryButton: {
		minWidth: 100,
		backgroundColor: theme.palette.grey[300],
		'&:hover': {
			backgroundColor: theme.palette.grey.A100,
		},
		color: theme.palette.defaultBlack,
		marginTop: theme.spacing(),
	},
	radio: {
		fontSize: 16,
	},
	formLabel: {
		fontSize: 18,
	},
	food: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
		flexWrap: 'wrap',
	},
	foodItem: {
		margin: theme.spacing(),
	},
	select: {
		minWidth: 112,
	},
	button: {
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		minWidth: 100,
		color: theme.palette.defaultBlack,
		marginRight: theme.spacing(),
	},
	iconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
		paddingTop: 0,
		paddingBottom: 0,
	},
	icon: {
		color: theme.palette.common.black,
	},
}));
