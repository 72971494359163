import moment from 'moment';

import { User } from '../../../models/user';
import { Entry } from '../../../models/foods';
import { Health } from '../../../models/health';
import { ActivityDetails } from '../../../models/activity';

export const getDistance = (activities: ActivityDetails[]): number => {
	const distance = activities.reduce((acc, curr) => {
		return acc + curr.distance;
	}, 0);
	return distance;
};

export const getCalories = (activities: ActivityDetails[]): number => {
	const calories = activities.reduce((acc, curr) => {
		return acc + curr.calories;
	}, 0);
	return calories;
};

export const getEndOfDay = (): Date => {
	const current = new Date();
	current.setHours(23, 59, 59, 999);
	return current;
};

export const getClosestDate = (day: string, days: Health[]): Health => {
	const dayAsDate = new Date(day);
	dayAsDate.setHours(0, 0, 0, 0);

	const sorted = days.sort((a, b) => {
		const diffA = Math.abs(Number(dayAsDate) - Number(new Date(a.date)));
		const diffB = Math.abs(Number(dayAsDate) - Number(new Date(b.date)));
		return diffA - diffB;
	});

	const beforeDates = sorted.filter((date) => {
		return Number(new Date(date.date)) - Number(dayAsDate) < 0;
	});
	const onDate = sorted.filter((date) => {
		return Number(new Date(date.date)) - Number(dayAsDate) === 0;
	});
	const afterDates = sorted.filter((date) => {
		return Number(new Date(date.date)) - Number(dayAsDate) >= 0;
	});

	if (onDate.length) {
		return onDate[0];
	} else if (beforeDates.length) {
		return beforeDates[0];
	} else {
		return afterDates[0];
	}
};

export const getBmr = (
	health: Health | undefined,
	user: User,
	date: string,
): number => {
	if (!health) {
		return 0;
	}
	const { height, weight } = health;
	const { age, sex, joined } = user;

	const extraYears = moment(date).diff(moment(joined), 'years');

	let bmr = 0;

	if (sex === 'Male') {
		bmr =
			66.47 +
			13.75 * weight +
			5.003 * height -
			6.755 * (age + extraYears);
	} else {
		bmr =
			655.1 + 9.563 * weight + 1.85 * height - 4.676 * (age + extraYears);
	}
	return Math.round(bmr * 1.2);
};

export const getExtraCalories = (
	health: Health | undefined,
	day: string,
): number => {
	if (!health) {
		return 0;
	}
	const dayAsDate = new Date(day);
	dayAsDate.setHours(0, 0, 0, 0);
	if (Number(dayAsDate) === Number(new Date(health.date))) {
		return health.calories;
	}
	return 0;
};

export const getCaloriePercentage = (
	caloriesEaten: number,
	totalCalories: number,
): number => ((caloriesEaten - totalCalories) / totalCalories) * 100;

export const formatMealNumber = (number: number): number =>
	Math.round(number * 10) / 10;

export const getCaloriesEaten = (entries: Entry[]): number =>
	Math.round(
		entries.reduce((acc, curr) => acc + formatMealNumber(curr.calories), 0),
	);

export const getNumberMeals = (entries: Entry[]): number =>
	entries.reduce((acc, curr) => {
		if (!acc.includes(curr.mealNumber)) {
			acc.push(curr.mealNumber);
		}
		return acc;
	}, [] as number[]).length;
