import React from 'react';
import {
	ResponsiveContainer,
	PieChart,
	Pie,
	Tooltip,
	Legend,
	Cell,
} from 'recharts';
import { Divider, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import TooltipContent from './TooltipContent';
import { useStoreState } from '../../../store';
import { getMacros, getEntryMacros, getMacroData } from './utils';

export interface IMacros {
	carbs: number;
	fat: number;
	protein: number;
}

const Macros: React.FC = () => {
	const classes = useStyles();

	const activities = useStoreState((state) => state.day.activities);
	const theme = useStoreState((state) => state.settings.theme);
	const health = useStoreState((state) => state.day.health);
	const entries = useStoreState((state) => state.foods.entries);
	const user = useStoreState((state) => state.user.user);
	const date = useStoreState((state) => state.day.date);

	const macros = getMacros(health, user, date, activities);
	const entryMacros = getEntryMacros(entries);
	const data = getMacroData(entries);

	return (
		<div className={classes.root}>
			<div className={classes.headerContainer}>
				<Typography variant="h5" className={classes.header}>
					Macronutrients
				</Typography>
			</div>
			<Divider className={classes.divider} />
			<div className={classes.headerDetails}>
				{health && (
					<div className={classes.headerBlock}>
						<Typography variant="subtitle1">{`Carbs (${health.carbs}%)`}</Typography>
						<div className={classes.headerDetails}>
							<div
								style={{
									marginRight: theme.spacing(),
								}}
							>
								<Typography
									variant="h5"
									style={{
										fontWeight: 'lighter',
									}}
								>
									{`${macros.carbs} g`}
								</Typography>
								<Typography variant="subtitle2">
									Total
								</Typography>
							</div>
							<div>
								<Typography
									variant="h5"
									style={{
										fontWeight: 'lighter',
									}}
								>
									{`${entryMacros.carbs} g`}
								</Typography>
								<Typography variant="subtitle2">
									Eaten
								</Typography>
							</div>
						</div>
					</div>
				)}
				{health && (
					<div className={classes.headerBlock}>
						<Typography variant="subtitle1">{`Fat (${health.fat}%)`}</Typography>
						<div className={classes.headerDetails}>
							<div
								style={{
									marginRight: theme.spacing(),
								}}
							>
								<Typography
									variant="h5"
									style={{
										fontWeight: 'lighter',
									}}
								>
									{`${macros.fat} g`}
								</Typography>
								<Typography variant="subtitle2">
									Total
								</Typography>
							</div>
							<div>
								<Typography
									variant="h5"
									style={{
										fontWeight: 'lighter',
									}}
								>
									{`${entryMacros.fat} g`}
								</Typography>
								<Typography variant="subtitle2">
									Eaten
								</Typography>
							</div>
						</div>
					</div>
				)}
				{health && (
					<div className={classes.headerBlock}>
						<Typography variant="subtitle1">{`Protein (${health.protein}%)`}</Typography>
						<div className={classes.headerDetails}>
							<div
								style={{
									marginRight: theme.spacing(),
								}}
							>
								<Typography
									variant="h5"
									style={{
										fontWeight: 'lighter',
									}}
								>
									{`${macros.protein} g`}
								</Typography>
								<Typography variant="subtitle2">
									Total
								</Typography>
							</div>
							<div>
								<Typography
									variant="h5"
									style={{
										fontWeight: 'lighter',
									}}
								>
									{`${entryMacros.protein} g`}
								</Typography>
								<Typography variant="subtitle2">
									Eaten
								</Typography>
							</div>
						</div>
					</div>
				)}
				{!!entries.length && (
					<div style={{ width: 200, height: 108 }}>
						<ResponsiveContainer width="100%" height="100%">
							<PieChart width={108} height={108}>
								<Pie
									dataKey="value"
									data={data}
									cx="50%"
									cy="50%"
									outerRadius={54}
									stroke={theme.palette.background.paper}
									strokeWidth={3}
								>
									{data.map((entry, index) => (
										<Cell key={index} fill={entry.colour} />
									))}
								</Pie>
								<Tooltip content={<TooltipContent />} />
								<Legend
									layout="vertical"
									verticalAlign="middle"
									align="left"
								/>
							</PieChart>
						</ResponsiveContainer>
					</div>
				)}
			</div>
		</div>
	);
};

export default Macros;
