import { action, Action } from 'easy-peasy';

export interface User {
	id: string;
	name: string;
	age: number;
	sex: string;
	joined: string;
	email: string;
}

export interface UserModel {
	auth: boolean;
	setAuth: Action<UserModel, boolean>;
	user: User;
	setUser: Action<UserModel, User>;
}

const user: UserModel = {
	auth: false,
	setAuth: action((state, value) => {
		if (value === false) {
			localStorage.clear();
		}
		state.auth = value;
	}),
	user: {
		id: '',
		name: 'n/a',
		age: 0,
		sex: 'n/a',
		joined: 'n/a',
		email: 'n/a',
	},
	setUser: action((state, value) => {
		state.user = value;
	}),
};

export default user;
