import React, { useState } from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';

import MobileNavigation, {
	Props as MobileNavigationProps,
} from './MobileNavigation';
import StandardNavigation from './StandardNavigation';

export interface Props {
	loading: boolean;
	login: boolean;
}

const Navigation: React.FC<Props> = ({ loading, login }) => {
	const theme = useTheme();

	const [anchorEl, setAnchorEl] = useState<Element | null>(null);

	const isTablet = useMediaQuery(theme.breakpoints.down('sm'));
	const open = Boolean(anchorEl);

	const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const props: MobileNavigationProps = {
		open,
		handleClose,
		handleOpen,
		anchorEl,
	};

	return loading || login ? (
		<></>
	) : isTablet ? (
		<MobileNavigation {...props} />
	) : (
		<StandardNavigation />
	);
};

export default Navigation;
