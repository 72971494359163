import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import { FormData } from './index';
import { useStyles } from './styles';
import Loading from '../../../../Loading';
import { useStoreState } from '../../../../../store';

export interface Props
	extends Pick<UseFormMethods, 'handleSubmit' | 'register' | 'errors'> {
	loading: boolean;
	handleFormSubmit: (data: FormData) => Promise<void>;
	setEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const TitleForm: React.FC<Props> = ({
	handleSubmit,
	register,
	errors,
	loading,
	handleFormSubmit,
	setEdit,
}) => {
	const classes = useStyles();

	const activity = useStoreState((state) => state.activity.activity);
	const theme = useStoreState((state) => state.settings.theme);

	return (
		<form>
			<div className={classes.name}>
				<TextField
					autoComplete="off"
					defaultValue={activity ? activity.name : undefined}
					name="name"
					label={errors.name && errors.name.message}
					inputRef={register({
						required: {
							value: true,
							message: 'required',
						},
						minLength: {
							value: 1,
							message: 'minimum 1 character',
						},
						maxLength: {
							value: 50,
							message: 'maximum 50 characters',
						},
					})}
					error={errors.name ? true : false}
				></TextField>
				{loading ? (
					<div className={classes.icons}>
						<Loading size={24} thickness={4} />
					</div>
				) : (
					<div className={classes.icons}>
						<IconButton
							className={classes.iconButton}
							style={{
								padding: 0,
								marginLeft: theme.spacing(),
							}}
							type="submit"
							onClick={handleSubmit(handleFormSubmit)}
						>
							<CheckCircleIcon className={classes.icon} />
						</IconButton>
						<IconButton
							className={classes.iconButton}
							style={{
								padding: 0,
								marginLeft: theme.spacing(),
							}}
							onClick={() => setEdit(false)}
						>
							<CancelIcon className={classes.icon} />
						</IconButton>
					</div>
				)}
			</div>
		</form>
	);
};

export default TitleForm;
