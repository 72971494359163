import React from 'react';
import Alert, { Color } from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

import { useStyles } from './styles';

export interface Props {
	handleClose: () => void;
	redirectError: boolean;
	title: string;
	severity?: Color;
}

const Error: React.FC<Props> = ({
	handleClose,
	redirectError,
	title,
	severity,
}) => {
	const classes = useStyles();

	return (
		<Snackbar
			open={redirectError}
			onClose={handleClose}
			autoHideDuration={3000}
			transitionDuration={500}
			className={classes.alert}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
		>
			<Alert
				onClose={handleClose}
				severity={severity || 'error'}
				variant="filled"
			>
				{title}
			</Alert>
		</Snackbar>
	);
};

export default Error;
