import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { formatShoes } from './utils';
import { Shoe } from '../../../models/shoes';
import { useStoreState } from '../../../store';
import PAllTimeStats, { Props } from './AllTimeStats';
import { getComparator, stableSort } from '../../Activities/Table/utils';

const AllTimeStats: React.FC = () => {
	const allShoes = useStoreState((state) => state.shoes.shoes);
	const activities = useStoreState((state) => state.activities.activities);

	const [startDate, setStartDate] = useState<Date | undefined>(undefined);
	const [endDate] = useState<Date>(new Date());
	const [shoes, setShoes] = useState<Shoe[]>([]);

	useEffect(() => {
		const sorted = stableSort(activities, getComparator('asc', 'date'));
		if (sorted.length) {
			setStartDate(moment(sorted[0].date).toDate());
		}
	}, [activities]);

	useEffect(() => {
		setShoes(formatShoes(allShoes));
	}, [allShoes]);

	const props: Props = {
		startDate,
		endDate,
		shoes,
	};

	return <PAllTimeStats {...props} />;
};

export default AllTimeStats;
