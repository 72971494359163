import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';

import Tags from './Tags';
import Shoes from './Shoes';
import Title from '../Title';
import Display from './Display';
import Loading from '../Loading';
import { useStyles } from './styles';
import useWindowDimensions from '../../utils/windowSize';
import { useStoreState, useStoreActions } from '../../store';

const Settings: React.FC = () => {
	const classes = useStyles();
	const { height } = useWindowDimensions();

	const theme = useStoreState((state) => state.settings.theme);
	const tags = useStoreState((state) => state.tags.tags);
	const shoes = useStoreState((state) => state.shoes.shoes);
	const getTags = useStoreActions((actions) => actions.tags.getTags);
	const getShoes = useStoreActions((actions) => actions.shoes.getShoes);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (!tags.length) {
					await getTags();
				}
				if (!shoes.length) {
					await getShoes();
				}
				setLoading(false);
			} catch (err) {}
		};
		fetchData();
	}, [getTags, getShoes]);

	return (
		<div className={classes.root}>
			{loading ? (
				<Loading />
			) : (
				<div
					className={classes.container}
					style={{
						maxHeight: height - 60 - theme.spacing(3),
						overflow: 'auto',
					}}
				>
					<Title title="Settings" />
					<div className={classes.container}>
						<Paper className={classes.paper}>
							<Display />
						</Paper>
						<Paper className={classes.paper}>
							<Shoes />
						</Paper>
						<Paper className={classes.tagPaper}>
							<Tags />
						</Paper>
					</div>
				</div>
			)}
		</div>
	);
};

export default Settings;
