import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

export interface Props {
	id: string;
	caloriesEaten: number;
	totalCalories: number;
}

const ids = {
	Mon: 'Monday',
	Tue: 'Tuesday',
	Wed: 'Wednesday',
	Thu: 'Thursday',
	Fri: 'Friday',
	Sat: 'Saturday',
	Sun: 'Sunday',
};

const TooltipContent: React.FC<Props> = ({
	id,
	caloriesEaten,
	totalCalories,
}) => {
	const classes = useStyles();

	return (
		<Paper className={classes.paper} variant="outlined">
			<div className={classes.tooltip}>
				<Typography className={classes.text}>
					{ids[id as keyof typeof ids]}
				</Typography>
				<Typography
					className={classes.text}
				>{`${caloriesEaten} / ${totalCalories} C`}</Typography>
			</div>
		</Paper>
	);
};

export default TooltipContent;
