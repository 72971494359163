import React from 'react';
import { Link } from 'react-router-dom';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import {
	Checkbox,
	IconButton,
	Paper,
	TableContainer,
	TablePagination,
	TableSortLabel,
} from '@material-ui/core';

import { filter } from '../utils';
import Dialog from '../../Dialog';
import { useStyles } from './styles';
import { ActivityDetails } from '../../../models/activity';
import { getComparator, headCells, stableSort } from './utils';
import { useStoreActions, useStoreState } from '../../../store';
import {
	formatDistance,
	formatTime,
	formatDate,
	formatElevation,
} from '../../../utils/formatters';

export interface Props {
	handleChangePage: (_event: unknown, newPage: number) => void;
	handleChangeRowsPerPage: (
		event: React.ChangeEvent<HTMLInputElement>,
	) => void;
	createSortHandler: (
		property: keyof ActivityDetails,
	) => (event: React.MouseEvent<unknown>) => void;
	isSelected: (id: string) => boolean;
	handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleCheckbox: (id: string) => void;
	handleRemoveOne: () => Promise<void>;
}

const Table: React.FC<Props> = ({
	handleChangePage,
	handleChangeRowsPerPage,
	createSortHandler,
	isSelected,
	handleSelectAll,
	handleCheckbox,
	handleRemoveOne,
}) => {
	const classes = useStyles();

	const paceUnit = useStoreState((state) => state.settings.paceUnit);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const elevationUnit = useStoreState(
		(state) => state.settings.elevationUnit,
	);
	const page = useStoreState((state) => state.activities.page);
	const rowsPerPage = useStoreState((state) => state.activities.rowsPerPage);
	const length = useStoreState((state) => state.activities.length);
	const removeOne = useStoreState((state) => state.activities.removeOne);
	const activities = useStoreState((state) => state.activities.activities);
	const selected = useStoreState((state) => state.activity.selected);
	const filters = useStoreState((state) => state.activities.filters);
	const order = useStoreState((state) => state.activities.order);
	const orderBy = useStoreState((state) => state.activities.orderBy);
	const setRemoveId = useStoreActions(
		(actions) => actions.activities.setRemoveId,
	);
	const setRemoveOne = useStoreActions(
		(actions) => actions.activities.setRemoveOne,
	);

	return (
		<div className={classes.root}>
			<TableContainer
				component={Paper}
				variant="outlined"
				style={{ borderWidth: 0 }}
			>
				<MuiTable stickyHeader size="small">
					<TableHead>
						<TableRow>
							<TableCell
								width="5%"
								classes={{ head: classes.header }}
							>
								<Checkbox
									color="primary"
									classes={{
										root: classes.checkbox,
									}}
									checked={
										activities.length > 0 &&
										length > 0 &&
										selected.length === length
									}
									onChange={handleSelectAll}
									style={{ padding: 4 }}
								/>
							</TableCell>
							{headCells.map((headCell) => (
								<TableCell
									key={headCell.id}
									sortDirection={
										orderBy === headCell.id ? order : false
									}
									width={headCell.width}
									classes={{ head: classes.header }}
								>
									<TableSortLabel
										active={orderBy === headCell.id}
										direction={
											orderBy === headCell.id
												? order
												: 'asc'
										}
										onClick={createSortHandler(headCell.id)}
									>
										{headCell.label}
										{orderBy === headCell.id ? (
											<span
												className={
													classes.visuallyHidden
												}
											>
												{order === 'desc'
													? 'sorted descending'
													: 'sorted ascending'}
											</span>
										) : null}
									</TableSortLabel>
								</TableCell>
							))}
							<TableCell
								width="5%"
								classes={{ head: classes.header }}
							>
								Remove
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{stableSort(
							filter(activities, filters),
							getComparator(order, orderBy),
						)
							.slice(
								page * rowsPerPage,
								page * rowsPerPage + rowsPerPage,
							)
							.map((activity) => {
								const { id } = activity;
								const isItemSelected = isSelected(id);
								return (
									<TableRow
										hover
										key={id}
										style={{ textDecoration: 'none' }}
										selected={isItemSelected}
										className={classes.row}
										classes={{ selected: classes.selected }}
									>
										<TableCell>
											<Checkbox
												color="primary"
												onClick={() =>
													handleCheckbox(id)
												}
												checked={isItemSelected}
												style={{ padding: 4 }}
											/>
										</TableCell>
										<TableCell>
											{formatDate(activity.date)}
										</TableCell>
										<TableCell>
											<Link
												to={`/activities/${id}`}
												className={classes.rowLink}
											>
												{activity.name}
											</Link>
										</TableCell>
										<TableCell>
											{formatDistance(
												activity.distance,
												distanceUnit,
											)}
										</TableCell>
										<TableCell>
											{formatTime(activity.time)}
										</TableCell>
										<TableCell>
											{formatTime(
												activity.avgPace,
												paceUnit,
											)}
										</TableCell>
										<TableCell>
											{activity.avgHeartRate
												? `${activity.avgHeartRate} bpm`
												: 'n/a'}
										</TableCell>
										<TableCell>
											{activity.ascent ||
											activity.ascent === 0
												? formatElevation(
														activity.ascent,
														elevationUnit,
												  )
												: 'n/a'}
										</TableCell>
										<TableCell align="center">
											<IconButton
												className={classes.iconButton}
												onClick={() => {
													setRemoveId(activity.id);
													setRemoveOne();
												}}
											>
												<DeleteIcon
													className={classes.icon}
												/>
											</IconButton>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</MuiTable>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25, 50]}
				component="div"
				count={length}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
			<Dialog
				open={removeOne}
				handleDialogConfirmClick={handleRemoveOne}
				handleDialogClick={() => setRemoveOne()}
				title="Remove Activity"
				content="Are you sure you want to remove this activity?"
				confirm="Remove"
			/>
		</div>
	);
};

export default Table;
