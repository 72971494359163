import React from 'react';
import { UseFormMethods } from 'react-hook-form';

import PFilter, { Props as FilterProps } from './Filter';
import { FilterData, FiltersData } from '../../interfaces';
import { useStoreState, useStoreActions } from '../../../../store';

export interface Props
	extends FilterData,
		Pick<
			UseFormMethods,
			'control' | 'reset' | 'getValues' | 'register' | 'watch' | 'errors'
		> {
	filterReset: FiltersData;
}

const Filter: React.FC<Props> = ({
	filterReset,
	control,
	errors,
	register,
	watch,
	reset,
	getValues,
	type,
	operator,
	label,
	unit,
	secondary,
	operators,
	units,
	dataType,
	dataName,
}) => {
	const filters = useStoreState((state) => state.activities.filters);
	const setFilters = useStoreActions(
		(actions) => actions.activities.setFilters,
	);
	const filtersData = useStoreState((state) => state.activities.filtersData);
	const setFiltersData = useStoreActions(
		(actions) => actions.activities.setFiltersData,
	);

	const handleReset = () => {
		const newFilters = filters.filter((filter) => filter.type !== dataName);
		const newFiltersData = Object.assign({}, filtersData);
		newFiltersData[operator] = undefined;
		if (type) {
			newFiltersData[type] = undefined;
		}
		if (unit) {
			newFiltersData[unit] = undefined;
		}
		if (secondary) {
			newFiltersData[secondary] = undefined;
		}
		setFiltersData(newFiltersData);
		setFilters(newFilters);
		reset({
			...getValues(),
			...filterReset,
		});
	};

	const props: FilterProps = {
		control,
		register,
		errors,
		watch,
		type,
		operator,
		label,
		unit,
		secondary,
		operators,
		units,
		dataType,
		dataName,
		handleReset,
	};

	return <PFilter {...props} />;
};

export default Filter;
