import { IMacros } from '.';
import { getBmr, getCalories, getExtraCalories } from '../../Log/Day/utils';
import { MacroData } from './interfaces';
import { User } from '../../../models/user';
import { Entry } from '../../../models/foods';
import { Health } from '../../../models/health';
import { ActivityDetails } from '../../../models/activity';

export const getMacros = (
	health: Health | undefined,
	user: User,
	date: string,
	activities: ActivityDetails[],
): IMacros => {
	if (!health) {
		return {
			carbs: 0,
			fat: 0,
			protein: 0,
		};
	}
	const totalCalories =
		getBmr(health, user, date) +
		getCalories(activities) +
		getExtraCalories(health, date);

	const carbs = Math.round((totalCalories * health.carbs * 0.01) / 4);
	const fat = Math.round((totalCalories * health.fat * 0.01) / 9);
	const protein = Math.round((totalCalories * health.protein * 0.01) / 4);

	return {
		carbs,
		fat,
		protein,
	};
};

export const formatMealNumber = (number: number): number =>
	Math.round(number * 10) / 10;

export const getEntryMacros = (entries: Entry[]): IMacros => {
	const carbs = formatMealNumber(
		entries.reduce((acc, curr) => acc + formatMealNumber(curr.carbs), 0),
	);
	const fat = formatMealNumber(
		entries.reduce((acc, curr) => acc + formatMealNumber(curr.fat), 0),
	);
	const protein = formatMealNumber(
		entries.reduce((acc, curr) => acc + formatMealNumber(curr.protein), 0),
	);

	return {
		carbs,
		fat,
		protein,
	};
};

export const getMacroData = (entries: Entry[]): MacroData[] => {
	const { carbs, fat, protein } = getEntryMacros(entries);
	const total = carbs * 4 + fat * 9 + protein * 4;

	return [
		{
			name: 'carbs',
			value: Math.round(((carbs * 4) / total) * 100),
			colour: '#FFD438',
		},
		{
			name: 'fat',
			value: Math.round(((fat * 9) / total) * 100),
			colour: '#F84F4F',
		},
		{
			name: 'protein',
			value: Math.round(((protein * 4) / total) * 100),
			colour: '#4CAF50',
		},
	];
};
