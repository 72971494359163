import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { AxiosResponse } from 'axios';
import PFood, { Props as FoodProps } from './Food';
import { useStoreActions } from '../../../../store';
import { FoodData, MealFood, MealData } from './interfaces';
import { AddFood, AddMeal } from '../../../../models/foods';

export interface Props {
	openFood: boolean;
	handleFoodClick: () => void;
	handleFoodConfirmClick: () => Promise<void>;
}

const Food: React.FC<Props> = ({ openFood, handleFoodClick }) => {
	const addFood = useStoreActions((actions) => actions.foods.addFood);
	const getFood = useStoreActions((actions) => actions.foods.getFoods);
	const addMeal = useStoreActions((actions) => actions.foods.addMeal);
	const getMeals = useStoreActions((actions) => actions.foods.getMeals);

	const [activeStep, setActiveStep] = useState(0);
	const [type, setType] = useState<string | undefined>();
	const [food, setFood] = useState<FoodData | undefined>();
	const [mealFood, setMealFood] = useState<MealFood[]>([]);
	const [error, setError] = useState(0);

	const { handleSubmit, register, control, errors } = useForm<FoodData>();
	const {
		handleSubmit: mealHandleSubmit,
		register: mealRegister,
		control: mealControl,
		reset: mealReset,
		watch: mealWatch,
		errors: mealErrors,
	} = useForm<MealData>();

	const watchFood = mealWatch('food');
	const watchUnit = mealWatch('unit');
	const watchValue = mealWatch('value');

	const handleMealConfirmClick = (data: MealData) => {
		if (data.food) {
			const newMealFood = mealFood.slice();
			newMealFood.push({
				food: data.food,
				unit: data.unit,
				value: data.value,
			});
			setMealFood(newMealFood);
			resetMealData();
		}
	};

	const resetMealData = () => {
		mealReset({
			food: null,
			unit: 'quantity',
			value: '',
		});
	};

	const deleteMealFood = (index: number) => {
		const newMealFood = mealFood.slice();
		newMealFood.splice(index, 1);
		setMealFood(newMealFood);
	};

	const handleFoodConfirmClick = async (data: FoodData) => {
		try {
			if (activeStep === 2 && type === 'food' && food) {
				const {
					name,
					amount,
					unit,
					calories,
					carbs,
					fat,
					protein,
				} = food;
				const formattedData: AddFood = {
					name,
					amount: Number(amount),
					unit,
					calories: Number(calories),
					carbs: Number(carbs),
					fat: Number(fat),
					protein: Number(protein),
				};
				await addFood(formattedData);
				await getFood();
				handleFoodClick();
				handleNext();
			} else if (activeStep === 2 && type === 'meal' && food) {
				const formattedMeal: AddMeal = {
					name: food.name,
					foods: mealFood.map((item) => {
						const amount =
							item.unit === 'quantity'
								? Number(item.value) * item.food.amount
								: Number(item.value);

						return {
							food: item.food.name,
							amount,
						};
					}),
				};
				await addMeal(formattedMeal);
				await getMeals();
				handleFoodClick();
				handleNext();
			} else if (activeStep === 1 && type === 'meal') {
				if (mealFood.length) {
					setFood(data);
					handleNext();
				} else {
					setError(1);
				}
			} else if (data.name) {
				setFood(data);
				handleNext();
			} else {
				handleNext();
			}
		} catch (err) {
			if (err && err.response) {
				setError((err.response as AxiosResponse).status);
			}
		}
	};

	const handleNext = () => {
		setError(0);
		setActiveStep((value) => value + 1);
	};

	const handleBack = () => {
		setError(0);
		setActiveStep((value) => value - 1);
	};

	const handleReset = () => {
		setError(0);
		setActiveStep(0);
		setType(undefined);
		setFood(undefined);
		setMealFood([]);
	};

	const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setType((event.target as HTMLInputElement).value);
	};

	useEffect(() => {
		if (openFood) {
			handleReset();
		}
	}, [openFood]);

	const props: FoodProps = {
		openFood,
		handleFoodClick,
		activeStep,
		handleTypeChange,
		handleBack,
		handleFoodConfirmClick,
		deleteMealFood,
		handleMealConfirmClick,
		watchFood,
		watchUnit,
		watchValue,
		type,
		food,
		mealFood,
		error,
		control,
		handleSubmit,
		register,
		errors,
		mealControl,
		mealRegister,
		mealHandleSubmit,
		mealErrors,
	};

	return <PFood {...props} />;
};

export default Food;
