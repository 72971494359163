import React from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider } from 'easy-peasy';
import { BrowserRouter as Router } from 'react-router-dom';

import './index.css';
import store from './store';
import App from './components/App';
import configureInterceptors from './interceptors';

configureInterceptors();

ReactDOM.render(
	<StoreProvider store={store}>
		<Router>
			<App />
		</Router>
	</StoreProvider>,
	document.getElementById('root'),
);
