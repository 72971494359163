import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(),
	},
	header: {
		marginRight: theme.spacing(),
	},
	headerContainer: {
		marginTop: theme.spacing(),
		marginBottom: theme.spacing(),
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	headerBlock: {
		margin: theme.spacing(),
	},
	headerDetails: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
		flexWrap: 'wrap',
	},
	divider: {
		marginBottom: theme.spacing(),
	},
	tableHeader: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.defaultBlack,
	},
	checkbox: {
		color: theme.palette.defaultBlack,
	},
	button: {
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		minWidth: 100,
		color: theme.palette.defaultBlack,
		marginRight: theme.spacing(),
	},
	secondaryButton: {
		minWidth: 100,
		backgroundColor: theme.palette.grey[300],
		'&:hover': {
			backgroundColor: theme.palette.grey.A100,
		},
		color: theme.palette.defaultBlack,
	},
	selection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	selectionItem: {
		marginRight: theme.spacing(),
	},
	select: {
		minWidth: 112,
	},
	iconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
		paddingTop: 0,
		paddingBottom: 0,
	},
	icon: {
		color: theme.palette.common.black,
	},
	row: {
		'&$selected, &$selected:hover': {
			backgroundColor: theme.palette.action.hover,
		},
	},
	selected: {},
}));
