import React from 'react';

import PGraphOptions, { Props } from './GraphOptions';
import { useStoreActions } from '../../../store';

const GraphOptions: React.FC = () => {
	const setPace = useStoreActions((actions) => actions.activity.setPace);
	const setHeartRate = useStoreActions(
		(actions) => actions.activity.setHeartRate,
	);
	const setGraphSelected = useStoreActions(
		(actions) => actions.activity.setGraphSelected,
	);

	const handlePace = () => {
		setPace();
		setGraphSelected();
	};

	const handleHeartRate = () => {
		setHeartRate();
		setGraphSelected();
	};

	const props: Props = {
		handlePace,
		handleHeartRate,
	};

	return <PGraphOptions {...props} />;
};

export default GraphOptions;
