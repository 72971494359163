import moment from 'moment';

import { Week } from './interfaces';
import { ActivityDetails } from '../../../models/activity';

export const getWeeks = (date: Date, activities: ActivityDetails[]): Week[] => {
	const weeks: Week[] = [];
	for (let i = 0; i < 52; i++) {
		const startDate = moment(date)
			.subtract(51 - i, 'week')
			.startOf('isoWeek');
		const endDate = moment(date)
			.subtract(51 - i, 'week')
			.endOf('isoWeek');
		const filtered = activities.filter((activity) => {
			if (
				Number(new Date(activity.date)) >=
					Number(startDate.clone().toDate()) &&
				Number(new Date(activity.date)) <=
					Number(endDate.clone().toDate())
			) {
				return true;
			}
		});
		const total = filtered.reduce((acc, curr) => {
			return acc + curr.distance;
		}, 0);
		const startDay = startDate.clone().date();
		const endDay = endDate.clone().date();
		weeks.push({
			id: i - 51,
			distance: total,
			tick:
				startDay === 1 || (startDay > 1 && startDay > endDay)
					? true
					: false,
		});
	}
	return weeks;
};

export const getTicks = (weeks: Week[]): number[] =>
	weeks.reduce((acc, curr) => {
		if (curr.tick) {
			acc.push(curr.id);
		}
		return acc;
	}, [] as number[]);
