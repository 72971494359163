import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import SignInForm, { Props as SignInFormProps } from './SignInForm';
import RegisterForm, { Props as RegisterFormProps } from './RegisterForm';
import {
	SignInFormData,
	RegisterFormData,
	ForgottenFormData,
} from './interfaces';
import { forgotPassword, loginUser, registerUser } from './utils';
import { useStyles } from './styles';
import { useStoreActions } from '../../../store';
import { User } from '../../../models/user';
import ForgottenForm, { Props as ForgottenFormProps } from './ForgottenForm';

interface Props {
	setError: React.Dispatch<React.SetStateAction<number>>;
}

const Form: React.FC<Props> = ({ setError }) => {
	const classes = useStyles();

	const setAuth = useStoreActions((actions) => actions.user.setAuth);
	const setUser = useStoreActions((actions) => actions.user.setUser);
	const getSettings = useStoreActions(
		(actions) => actions.settings.getSettings,
	);

	const [defaultValues, setDefaultValues] = useState<
		SignInFormData | RegisterFormData | ForgottenFormData
	>({ email: '', password: '' });
	const [formType, setFormType] = useState('signIn');

	const {
		handleSubmit: handleSubmitForgotten,
		register: registerForgotten,
		reset: resetForgotten,
	} = useForm<ForgottenFormData>();
	const {
		handleSubmit: handleSubmitSignIn,
		register: registerSignIn,
		watch: watchSignIn,
		reset: resetSignIn,
	} = useForm<SignInFormData>();
	const {
		handleSubmit: handleSubmitRegister,
		register: registerRegister,
		watch: watchRegister,
		errors: errorsRegister,
		reset: resetRegister,
		control: controlRegister,
	} = useForm<RegisterFormData>();

	const handleFormSubmit = async (
		data: SignInFormData | RegisterFormData | ForgottenFormData,
		actionType: string,
	) => {
		setError(0);
		try {
			let response;
			if (actionType === 'register') {
				response = await registerUser(data as RegisterFormData);
			} else if (actionType === 'login') {
				response = await loginUser(data as SignInFormData);
			} else {
				response = await forgotPassword(data as ForgottenFormData);
			}

			if (response && actionType !== 'forgotten') {
				localStorage.setItem('JWT', response.token);
				setAuth(true);
				const {
					id,
					name,
					age,
					sex,
					email,
					joined,
				}: User = response.user;
				setUser({ id, name, age, sex, email, joined });
				await getSettings();
			} else if (actionType === 'forgotten') {
				setError(200);
			}
		} catch (err) {
			if (err && err.response) {
				const status = (err.response as AxiosResponse).status;
				if (actionType === 'forgotten' && status === 404) {
					setError(4040);
				} else {
					setError(status);
				}
			}
		}
	};

	const handleLogin = handleSubmitSignIn(async (data) => {
		await handleFormSubmit(data, 'login');
	});

	const handleRegister = handleSubmitRegister(async (data) => {
		await handleFormSubmit(data, 'register');
	});

	const handleForgotten = handleSubmitForgotten(async (data) => {
		await handleFormSubmit(data, 'forgotten');
	});

	const handleChangeForm = (type: string) => {
		if (type === 'register') {
			const email = watchSignIn('email');
			const password = watchSignIn('password');
			setDefaultValues({
				name: '',
				sex: 'Male',
				age: '',
				height: '',
				weight: '',
				email,
				password,
				confirmPassword: '',
			});
			setFormType('register');
		} else if (type === 'signIn') {
			const email = watchRegister('email');
			const password = watchRegister('password');
			setDefaultValues({ email, password });
			setFormType('signIn');
		} else {
			const email = watchSignIn('email');
			setDefaultValues({ email });
			setFormType('forgotten');
		}
		setError(0);
	};

	useEffect(() => {
		if (formType === 'signIn') {
			resetSignIn(defaultValues);
		} else if (formType === 'register') {
			resetRegister(defaultValues);
		} else {
			resetForgotten(defaultValues);
		}
	}, [formType, defaultValues, resetSignIn, resetRegister, resetForgotten]);

	const signInFormProps: SignInFormProps = {
		handleChangeForm,
		handleLogin,
		register: registerSignIn,
		reset: resetSignIn,
	};

	const registerFormProps: RegisterFormProps = {
		handleChangeForm,
		handleRegister,
		register: registerRegister,
		reset: resetRegister,
		watch: watchRegister,
		errors: errorsRegister,
		control: controlRegister,
	};

	const forgottenFormProps: ForgottenFormProps = {
		handleChangeForm,
		handleForgotten,
		register: registerForgotten,
	};

	return (
		<Paper className={classes.paper}>
			<Typography className={classes.loginTitle} align="center">
				{formType === 'signIn'
					? 'Sign In'
					: formType === 'register'
					? 'Register'
					: 'Forgotten Password'}
			</Typography>
			{formType === 'signIn' ? (
				<SignInForm {...signInFormProps} />
			) : formType === 'register' ? (
				<RegisterForm {...registerFormProps} />
			) : (
				<ForgottenForm {...forgottenFormProps} />
			)}
		</Paper>
	);
};

export default Form;
