import { ActivityDetails } from '../../../models/activity';
import { formatDateAsNumber } from '../../../utils/formatters';

export const sort = (a: ActivityDetails, b: ActivityDetails): number => {
	if (formatDateAsNumber(a.date) < formatDateAsNumber(b.date)) {
		return -1;
	}
	if (formatDateAsNumber(a.date) > formatDateAsNumber(b.date)) {
		return 1;
	}
	return 0;
};
