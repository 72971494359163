import React from 'react';

import PTooltipContent, {
	Props as TooltipContentProps,
} from './TooltipContent';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TooltipContent: React.FC = ({ active, payload, label }: any) => {
	if (active && payload && payload.length) {
		const { distance } = payload[0].payload;

		const props: TooltipContentProps = {
			id: String(label),
			distance,
		};
		return <PTooltipContent {...props} />;
	}
	return null;
};

export default TooltipContent;
