import React from 'react';
import Button from '@material-ui/core/Button';
import { Link, useLocation } from 'react-router-dom';

import { useStoreState } from '../../../store';
import { useStylesDesktop as useStyles } from './styles';

const StandardNavigation: React.FC = () => {
	const classes = useStyles();

	const { pathname } = useLocation();

	const theme = useStoreState((state) => state.settings.theme);

	return (
		<>
			<Button
				className={classes.button}
				variant="text"
				disableRipple={true}
				component={Link}
				to="/dashboard"
				style={
					pathname === '/dashboard'
						? {
								color: theme.palette.primary.main,
						  }
						: {}
				}
			>
				Dashboard
			</Button>
			<Button
				className={classes.button}
				variant="text"
				disableRipple={true}
				component={Link}
				to="/log"
				style={
					pathname === '/log'
						? {
								color: theme.palette.primary.main,
						  }
						: {}
				}
			>
				Training Log
			</Button>
			<Button
				className={classes.button}
				variant="text"
				disableRipple={true}
				component={Link}
				to="/activities"
				style={
					pathname === '/activities'
						? {
								color: theme.palette.primary.main,
						  }
						: {}
				}
			>
				Activities
			</Button>
			<Button
				className={`${classes.button} ${classes.lastButton}`}
				variant="text"
				disableRipple={true}
				component={Link}
				to="/statistics"
				style={
					pathname === '/statistics'
						? {
								color: theme.palette.primary.main,
						  }
						: {}
				}
			>
				Statistics
			</Button>
		</>
	);
};

export default StandardNavigation;
