import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Week } from './interfaces';
import PGraph, { Props } from './Graph';
import { getWeeks, getTicks } from './utils';
import { formatDistance } from '../../../utils/formatters';
import { useStoreState, useStoreActions } from '../../../store';

const Graph: React.FC = () => {
	const date = useStoreState((state) => state.dashboard.date);
	const setDate = useStoreActions((actions) => actions.dashboard.setDate);
	const activities = useStoreState((state) => state.activities.activities);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const setSelectedBar = useStoreActions(
		(actions) => actions.dashboard.setSelectedBar,
	);
	const setSelectedDate = useStoreActions(
		(actions) => actions.dashboard.setSelectedDate,
	);

	const [focusBar, setFocusBar] = useState<number | null>(null);
	const [data, setData] = useState<Week[]>([]);
	const [ticks, setTicks] = useState<number[]>([]);

	const handleTick = (value: number): string => {
		let month = moment(date)
			.subtract(Math.abs(value), 'week')
			.endOf('isoWeek')
			.format('MMM');
		const year = moment(date)
			.subtract(Math.abs(value), 'week')
			.endOf('isoWeek')
			.format('YYYY');
		if (month === 'Jan') {
			month = `${month} ${year}`;
		}
		return month;
	};

	const handleYTick = (value: number): string => {
		return formatDistance(value, distanceUnit, true);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleMouse = (state: any) => {
		if (state.isTooltipActive) {
			setFocusBar(state.activeTooltipIndex);
		} else {
			setFocusBar(null);
		}
	};

	const handleClick = () => {
		if (focusBar || focusBar === 0) {
			setSelectedBar(focusBar);
			setSelectedDate(date);
		}
	};

	const handlePrevious = () => {
		const newDate = moment(date);
		newDate.subtract(1, 'y');
		setDate(newDate.toDate());
	};

	const handleNext = () => {
		const newDate = moment(date);
		newDate.add(1, 'y');
		setDate(newDate.toDate());
	};

	useEffect(() => {
		setData(getWeeks(date, activities));
	}, [date, setDate, activities]);

	useEffect(() => {
		setTicks(getTicks(data));
	}, [data]);

	const props: Props = {
		focusBar,
		data,
		ticks,
		handleTick,
		handleYTick,
		handleMouse,
		handleClick,
		handlePrevious,
		handleNext,
	};

	return <PGraph {...props} />;
};

export default Graph;
