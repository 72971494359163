import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStylesDesktop = makeStyles(
	(theme) => ({
		button: {
			marginRight: theme.spacing(4),
			'&:hover': {
				color: theme.palette.primary.main,
				backgroundColor: 'transparent',
			},
			fontSize: 18,
			fontWeight: 600,
		},
		lastButton: {
			marginRight: theme.spacing(0),
		},
	}),
	{ index: 1 },
);

export const useStylesMobile = makeStyles(
	(theme) => ({
		menuIconButton: {
			justifyContent: 'flex-start',
			'&:hover': {
				backgroundColor: 'transparent',
				'& $menuIcon': {
					color: theme.palette.primary.main,
				},
			},
			height: '100%',
		},
		menuIcon: {
			color: theme.palette.common.black,
		},
	}),
	{ index: 1 },
);
