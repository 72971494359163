import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	error: {
		width: '100%',
		maxWidth: '350px',
		paddingLeft: 0,
		paddingRight: 0,
		marginTop: theme.spacing(),
	},
	errorPlaceholder: {
		height: 48,
		marginTop: theme.spacing(),
	},
}));
