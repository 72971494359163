import React, { useState, useEffect } from 'react';

import { useStoreState } from '../../../store';
import PDay, { Props as DayProps } from './Day';
import {
	getBmr,
	getCaloriePercentage,
	getCalories,
	getCaloriesEaten,
	getClosestDate,
	getEndOfDay,
	getExtraCalories,
	getNumberMeals,
} from './utils';
import { ActivityDetails } from '../../../models/activity';
import { formatDateAsNumber } from '../../../utils/formatters';
import { Day as DayInterface } from '../../../models/log';

export interface Props {
	day: DayInterface;
}

const Day: React.FC<Props> = ({ day }) => {
	const user = useStoreState((state) => state.user.user);
	const allEntries = useStoreState((state) => state.foods.allEntries);
	const healthStats = useStoreState((state) => state.health.healthStats);
	const allActivities = useStoreState((state) => state.activities.activities);

	const [bmr, setBmr] = useState(0);
	const [calories, setCalories] = useState(0);
	const [loading, setLoading] = useState(true);
	const [numberMeals, setNumberMeals] = useState(0);
	const [extraCalories, setExtraCalories] = useState(0);
	const [caloriesEaten, setCaloriesEaten] = useState(0);
	const [caloriePercentage, setCaloriePercentage] = useState(0);
	const [activities, setActivities] = useState<ActivityDetails[]>([]);

	const date = day.date;

	const inFuture =
		formatDateAsNumber(day.date) >
		formatDateAsNumber(getEndOfDay().toISOString());

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const activityData = allActivities.filter((activity) => {
					const activityDate = new Date(activity.date).setHours(
						0,
						0,
						0,
						0,
					);
					const filterDate = new Date(date).setHours(0, 0, 0, 0);
					if (activityDate === filterDate) {
						return true;
					}
					return false;
				});

				const entryData = allEntries.filter((entry) => {
					const entryDate = new Date(entry.date).setHours(0, 0, 0, 0);
					const filterDate = new Date(date).setHours(0, 0, 0, 0);
					if (entryDate === filterDate) {
						return true;
					}
					return false;
				});

				const health = getClosestDate(date, healthStats);
				const newBmr = getBmr(health, user, date);
				const newCalories = getCalories(activityData);
				const newCaloriesEaten = getCaloriesEaten(entryData);
				const newExtraCalories = getExtraCalories(health, date);

				setActivities(activityData);
				setBmr(newBmr);
				setCalories(newCalories);
				setExtraCalories(newExtraCalories);
				setCaloriesEaten(newCaloriesEaten);
				setNumberMeals(getNumberMeals(entryData));
				setCaloriePercentage(
					getCaloriePercentage(
						newCaloriesEaten,
						newBmr + newCalories + newExtraCalories,
					),
				);
				setLoading(false);
			} catch (err) {}
		};
		fetchData();
	}, [date, allActivities, healthStats, allEntries, user]);

	const props: DayProps = {
		day,
		activities,
		loading,
		inFuture,
		bmr,
		calories,
		extraCalories,
		caloriesEaten,
		numberMeals,
		caloriePercentage,
	};

	return <PDay {...props} />;
};

export default Day;
