import React from 'react';
import {
	Area,
	CartesianGrid,
	ComposedChart,
	DotProps,
	Line,
	ReferenceArea,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';

import { Stream } from '../../../models/activity';
import { useStoreState } from '../../../store';
import TooltipContent from './TooltipContent';

export interface Props {
	data: Stream[];
	handleRef: (el: CartesianGrid | null) => void;
	handleTick: (value: number) => void;
	handleMouseLeave: () => void;
	CustomDot: (
		props: DotProps & {
			index?: number;
		},
	) => JSX.Element;
	HiddenCustomDot: (
		props: DotProps & {
			index?: number;
		},
	) => JSX.Element;
	domain: number[];
	paceRange: number[];
	elevationRange: number[];
	heartRateRange: number[];
}

const Graph: React.FC<Props> = ({
	data,
	handleRef,
	handleTick,
	handleMouseLeave,
	CustomDot,
	HiddenCustomDot,
	domain,
	paceRange,
	elevationRange,
	heartRateRange,
}) => {
	const theme = useStoreState((state) => state.settings.theme);
	const pace = useStoreState((state) => state.activity.pace);
	const heartRate = useStoreState((state) => state.activity.heartRate);
	const clickHighlight = useStoreState(
		(state) => state.activity.clickHighlight,
	);
	const hoverHighlight = useStoreState(
		(state) => state.activity.hoverHighlight,
	);

	const graphSelected = useStoreState(
		(state) => state.activity.graphSelected,
	);

	const max = data[data.length - 1].time;

	return (
		<ResponsiveContainer width="100%" height="100%">
			<ComposedChart
				data={data}
				onMouseLeave={handleMouseLeave}
				margin={{
					left: theme.spacing(3),
					right: theme.spacing(3),
					top: theme.spacing(),
				}}
			>
				<CartesianGrid
					vertical={false}
					horizontal={false}
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					ref={handleRef as any}
				/>
				<XAxis
					dataKey="time"
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					tickFormatter={handleTick as any}
					type="number"
					ticks={domain}
					domain={[domain[0], domain[domain.length - 1]]}
				/>
				<YAxis
					yAxisId="pace"
					reversed
					domain={[
						paceRange[0],
						paceRange[1] +
							(paceRange[1] - paceRange[0]) * (graphSelected - 1),
					]}
					tick={false}
					width={16}
				/>
				<YAxis
					yAxisId="elevation"
					domain={[
						elevationRange[0],
						elevationRange[1] - elevationRange[0] > 200
							? elevationRange[1] - elevationRange[0]
							: 200,
					]}
					tick={false}
					width={0}
				/>
				<YAxis
					yAxisId="heartRate"
					orientation="right"
					domain={[
						heartRateRange[0],
						heartRateRange[1] +
							(heartRateRange[1] - heartRateRange[0]) *
								(graphSelected - 1),
					]}
					tick={false}
					width={16}
				/>
				<Tooltip content={<TooltipContent />} animationDuration={100} />
				<Area
					yAxisId="elevation"
					dataKey="elevation"
					fill={theme.palette.grey[300]}
					stroke={theme.palette.grey[300]}
					strokeWidth={3}
					activeDot={<HiddenCustomDot />}
				/>
				{clickHighlight && (
					<ReferenceArea
						yAxisId="elevation"
						x1={clickHighlight.min}
						x2={clickHighlight.max > max ? max : clickHighlight.max}
					/>
				)}
				{hoverHighlight && (
					<ReferenceArea
						yAxisId="elevation"
						x1={hoverHighlight.min}
						x2={hoverHighlight.max > max ? max : hoverHighlight.max}
					/>
				)}
				<Line
					yAxisId="pace"
					dataKey="pace"
					stroke={theme.palette.primary.main}
					strokeWidth={3}
					activeDot={<CustomDot />}
					dot={false}
					hide={!pace}
				/>
				<Line
					yAxisId="heartRate"
					dataKey="heartRate"
					stroke={theme.palette.error.main}
					strokeWidth={3}
					activeDot={<CustomDot />}
					dot={false}
					hide={!heartRate}
				/>
			</ComposedChart>
		</ResponsiveContainer>
	);
};

export default Graph;
