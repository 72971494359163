import React from 'react';
import {
	FormControl,
	IconButton,
	MenuItem,
	Paper,
	Select,
	Typography,
} from '@material-ui/core';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import { Controller, UseFormMethods } from 'react-hook-form';

import { useStyles } from './styles';
import { FiltersData } from '../../interfaces';
import { useStoreState } from '../../../../store';

export interface Props extends Pick<UseFormMethods, 'control' | 'watch'> {
	operator: keyof FiltersData;
	operators: string[];
	label: string;
	handleReset: () => void;
	handleDateChange: (newDate: Date | null) => void;
	handleDateSecondaryChange: (newDate: Date | null) => void;
}

const DateFilter: React.FC<Props> = ({
	control,
	watch,
	label,
	operator,
	operators,
	handleReset,
	handleDateChange,
	handleDateSecondaryChange,
}) => {
	const classes = useStyles();

	const date = useStoreState((state) => state.activities.date);
	const dateSecondary = useStoreState(
		(state) => state.activities.dateSecondary,
	);
	const filtersData = useStoreState((state) => state.activities.filtersData);

	return (
		<Paper variant="outlined" className={classes.paper}>
			<Typography
				className={classes.filterItem}
				style={{ fontWeight: 'bold' }}
			>
				{label}
			</Typography>
			<FormControl className={classes.filterItem}>
				<Controller
					name={operator}
					control={control}
					defaultValue={filtersData[operator] || ''}
					as={
						<Select className={classes.select}>
							{operators.map((operator, index) => (
								<MenuItem value={operator} key={index}>
									{operator}
								</MenuItem>
							))}
						</Select>
					}
				/>
			</FormControl>
			{(watch(operator) || filtersData[operator]) && (
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						className={classes.filterItem}
						autoOk
						disableToolbar
						variant="inline"
						format="dd/MM/yyyy"
						margin="normal"
						value={date}
						onChange={handleDateChange}
						KeyboardButtonProps={{
							className: classes.datePicker,
						}}
						InputProps={{ style: { width: 150 } }}
					></KeyboardDatePicker>
				</MuiPickersUtilsProvider>
			)}
			{(watch(operator) === 'between' ||
				filtersData[operator] === 'between') && (
				<Typography className={classes.filterItem}>{'and'}</Typography>
			)}
			{(watch(operator) === 'between' ||
				filtersData[operator] === 'between') && (
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<KeyboardDatePicker
						className={classes.filterItem}
						autoOk
						disableToolbar
						variant="inline"
						format="dd/MM/yyyy"
						margin="normal"
						value={dateSecondary}
						onChange={handleDateSecondaryChange}
						KeyboardButtonProps={{
							className: classes.datePicker,
						}}
						InputProps={{ style: { width: 150 } }}
					></KeyboardDatePicker>
				</MuiPickersUtilsProvider>
			)}
			{(watch(operator) || filtersData[operator]) && (
				<IconButton className={classes.iconButton}>
					<DeleteIcon
						className={classes.icon}
						onClick={handleReset}
					/>
				</IconButton>
			)}
		</Paper>
	);
};

export default DateFilter;
