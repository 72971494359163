import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	tooltipPlacementBottom: {
		margin: 0,
	},
	tooltip: {
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.defaultWhite,
		fontSize: 14,
	},
	arrow: {
		color: theme.palette.primary.dark,
	},
}));
