import React, { useState } from 'react';
import {
	BarChart,
	CartesianGrid,
	ResponsiveContainer,
	YAxis,
	XAxis,
	Bar,
	Tooltip,
	Cell,
} from 'recharts';

import { GraphData } from '..';
import TooltipContent from './TooltipContent';
import { useStoreState } from '../../../../store';
import { formatDistance } from '../../../../utils/formatters';

export interface Props {
	data: GraphData[];
}

const DistanceGraph: React.FC<Props> = ({ data }) => {
	const theme = useStoreState((state) => state.settings.theme);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);

	const [focusBar, setFocusBar] = useState<number | null>(null);

	const handleDistanceTick = (value: number) => {
		return formatDistance(value, distanceUnit, true);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleMouse = (state: any) => {
		if (state.isTooltipActive) {
			setFocusBar(state.activeTooltipIndex);
		} else {
			setFocusBar(null);
		}
	};

	return (
		<ResponsiveContainer width="100%" height="100%">
			<BarChart
				data={data}
				margin={{ top: theme.spacing(2) }}
				onMouseMove={handleMouse}
				onMouseLeave={handleMouse}
			>
				<CartesianGrid vertical={false} horizontal={false} />
				<XAxis dataKey="id" />
				<YAxis
					tickFormatter={handleDistanceTick}
					tickCount={3}
					width={120}
				/>
				<Tooltip
					cursor={false}
					content={<TooltipContent />}
					animationDuration={100}
				/>
				<Bar
					dataKey="distance"
					fill={theme.palette.primary.light}
					radius={[2, 2, 0, 0]}
				>
					{data.map((_entry, index) => (
						<Cell
							key={`cell-${index}`}
							fill={
								focusBar === index
									? theme.palette.primary.main
									: theme.palette.primary.light
							}
						/>
					))}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
};

export default DistanceGraph;
