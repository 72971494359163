import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(),
		display: 'flex',
		alignItems: 'center',
	},
	uploadButton: {
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		minWidth: 100,
		color: theme.palette.defaultBlack,
		marginRight: theme.spacing(),
	},
}));
