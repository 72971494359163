import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useStoreState } from '../../../store';

type Props = RouteProps;

const ProtectedRoute: React.FC<Props> = (props) => {
	const auth = useStoreState((state) => state.user.auth);

	return auth ? (
		<Route {...props} key={Date.now()} />
	) : (
		<Redirect to="/login" />
	);
};

export default ProtectedRoute;
