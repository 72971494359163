import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';

import { useStyles } from './styles';
import { UploadFile } from '../../../models/uploads';
import { useStoreState } from '../../../store';

export interface Props {
	files: UploadFile[];
	handleAdd: (addedFiles: FileObject[]) => Promise<void>;
	handleDelete: (deleted: FileObject) => Promise<void>;
}

const Dropzone: React.FC<Props> = ({ files, handleAdd, handleDelete }) => {
	const classes = useStyles();

	const loading = useStoreState((state) => state.uploads.loading);

	return (
		<div className={classes.root}>
			<DropzoneAreaBase
				classes={{ text: classes.text, icon: classes.icon }}
				fileObjects={files}
				onAdd={loading ? undefined : handleAdd}
				onDelete={loading ? undefined : handleDelete}
				filesLimit={10}
				maxFileSize={25000000}
				showFileNames={true}
				useChipsForPreview={true}
				acceptedFiles={['.fit']}
				showAlerts={['error']}
				Icon={(CloudUploadIcon as unknown) as React.ReactElement}
				dropzoneText={'Drag and drop a .fit file here or click'}
				alertSnackbarProps={{
					className: classes.alert,
					autoHideDuration: 3000,
					transitionDuration: 500,
				}}
				previewChipProps={{
					color: 'primary',
					variant: 'default',
					size: 'medium',
					deleteIcon: <DeleteIcon />,
					className: classes.chip,
				}}
			/>
		</div>
	);
};

export default Dropzone;
