import React from 'react';
import {
	Button,
	Divider,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@material-ui/core';

import { formatDistance, formatTime } from '../../../../utils/formatters';
import { TooltipNoMargin as Tooltip } from '../../../Tooltip';
import { useStoreState } from '../../../../store';
import { Lap } from '../../../../models/activity';

export interface Props {
	laps: Lap[];
	activityIndex: number;
	isSelected: (activityId: number, lapId: number) => boolean;
	handleButton: (activityId: number, lapId: number) => void;
}

const Laps: React.FC<Props> = ({
	laps,
	activityIndex,
	isSelected,
	handleButton,
}) => {
	const theme = useStoreState((state) => state.settings.theme);
	const paceUnit = useStoreState((state) => state.settings.paceUnit);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);

	return (
		<List dense disablePadding style={{ width: '100%' }}>
			{laps.map((lap, lapIndex) => {
				const { number, distance, duration, avgPace } = lap;
				const isItemSelected = isSelected(activityIndex, lapIndex);
				return (
					<div key={number}>
						{lapIndex !== 0 && (
							<Divider
								style={{
									width: '100%',
								}}
							/>
						)}
						<ListItem dense disableGutters>
							<Tooltip title={`Lap ${lapIndex + 1}`}>
								<Button
									size="small"
									variant="text"
									onClick={() =>
										handleButton(activityIndex, lapIndex)
									}
									style={
										isItemSelected
											? {
													backgroundColor:
														theme.palette.primary
															.light,
											  }
											: undefined
									}
									fullWidth
								>
									<ListItemText
										disableTypography
										style={{ margin: 0 }}
										primary={
											<Typography
												style={{
													color: isItemSelected
														? theme.palette
																.defaultBlack
														: theme.palette.text
																.primary,
												}}
											>
												{formatTime(
													duration,
													undefined,
													true,
												)}
											</Typography>
										}
										secondary={
											<Typography
												style={{
													color: isItemSelected
														? theme.palette
																.defaultBlack
														: theme.palette.text
																.primary,
												}}
											>
												{formatDistance(
													distance,
													distanceUnit,
												)}{' '}
												{formatTime(avgPace, paceUnit)}
											</Typography>
										}
									/>
								</Button>
							</Tooltip>
						</ListItem>
					</div>
				);
			})}
		</List>
	);
};

export default Laps;
