import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link, useLocation } from 'react-router-dom';
import { Badge } from '@material-ui/core';

import { useStyles } from './styles';
import { Notification } from '../../../models/notifications';
import { TooltipNoMargin as Tooltip } from '../../Tooltip';
import { useStoreState } from '../../../store';
import Dialog from '../../Dialog';

export interface Props {
	handleClose: () => void;
	handleOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
	anchorEl: Element | null;
	open: boolean;
	handleSettingsClose: () => void;
	handleSettingsOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
	settingsAnchor: Element | null;
	settingsOpen: boolean;
	notifications: Notification[];
	handleNotification: (notification: Notification) => void;
	openDialog: boolean;
	handleDialogClick: () => void;
	handleDialogConfirmClick: () => void;
}

const Icons: React.FC<Props> = ({
	handleClose,
	handleOpen,
	anchorEl,
	open,
	notifications,
	handleNotification,
	handleSettingsClose,
	handleSettingsOpen,
	settingsAnchor,
	settingsOpen,
	openDialog,
	handleDialogClick,
	handleDialogConfirmClick,
}) => {
	const classes = useStyles();

	const { pathname } = useLocation();

	const theme = useStoreState((state) => state.settings.theme);

	return (
		<div className={classes.icons}>
			<Tooltip
				title={
					notifications.length === 0
						? 'No notifications'
						: `${notifications.length} ${
								notifications.length === 1
									? 'notification'
									: 'notifications'
						  }`
				}
			>
				<span>
					<IconButton
						className={classes.iconButton}
						onClick={handleOpen}
						disabled={notifications.length === 0}
						style={{ height: '100%' }}
					>
						<Badge
							classes={{ badge: classes.badge }}
							badgeContent={notifications.length}
						>
							<NotificationsIcon className={classes.icon} />
						</Badge>
					</IconButton>
				</span>
			</Tooltip>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={open}
				onClose={handleClose}
				MenuListProps={{ onMouseLeave: handleClose }}
				style={{ maxHeight: 500, overflow: 'auto' }}
				PaperProps={{
					style: {
						boxShadow: '0px 2px 10px 1px rgba(0, 0, 0, 0.05)',
					},
				}}
			>
				{notifications.map((notification) => {
					const { message, url, id } = notification;

					return (
						<MenuItem
							className={classes.menuItem}
							key={id}
							onClick={() => {
								handleClose();
								handleNotification(notification);
							}}
							component={Link}
							to={url}
						>
							{message}
						</MenuItem>
					);
				})}
			</Menu>
			<Tooltip title={'Import'}>
				<IconButton
					className={classes.iconButton}
					component={Link}
					to="/import"
				>
					<AddCircleIcon
						className={classes.icon}
						style={
							pathname === '/import'
								? {
										color: theme.palette.primary.main,
								  }
								: {}
						}
					></AddCircleIcon>
				</IconButton>
			</Tooltip>
			<Tooltip title={'Settings'}>
				<IconButton
					className={classes.iconButton}
					onClick={handleSettingsOpen}
				>
					<SettingsIcon
						className={classes.icon}
						style={
							pathname === '/settings'
								? {
										color: theme.palette.primary.main,
								  }
								: {}
						}
					></SettingsIcon>
				</IconButton>
			</Tooltip>
			<Menu
				anchorEl={settingsAnchor}
				keepMounted
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={settingsOpen}
				onClose={handleSettingsClose}
				MenuListProps={{ onMouseLeave: handleSettingsClose }}
				style={{ maxHeight: 500, overflow: 'auto' }}
				PaperProps={{
					style: {
						boxShadow: '0px 2px 10px 1px rgba(0, 0, 0, 0.05)',
					},
				}}
			>
				<MenuItem
					className={classes.menuItem}
					onClick={() => {
						handleSettingsClose();
					}}
					component={Link}
					to="/settings"
				>
					Settings
				</MenuItem>
				<MenuItem
					className={classes.menuItem}
					onClick={() => {
						handleSettingsClose();
						handleDialogClick();
					}}
				>
					Sign out
				</MenuItem>
			</Menu>
			<Dialog
				handleDialogClick={handleDialogClick}
				handleDialogConfirmClick={handleDialogConfirmClick}
				open={openDialog}
				title="Sign out"
				content="Are you sure you want to sign out?"
				confirm="Confirm"
			/>
		</div>
	);
};

export default Icons;
