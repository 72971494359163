import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import { useStoreState } from '../../../../store';
import {
	formatDistance,
	formatElevation,
	formatTime,
} from '../../../../utils/formatters';

export interface Props {
	label: number;
	distance: number;
	pace: number;
	heartRate: number;
	elevation: number;
}

const TooltipContent: React.FC<Props> = ({
	label,
	distance,
	pace,
	heartRate,
	elevation,
}) => {
	const classes = useStyles();

	const paceUnit = useStoreState((state) => state.settings.paceUnit);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const elevationUnit = useStoreState(
		(state) => state.settings.elevationUnit,
	);

	return (
		<Paper className={classes.paper} variant="outlined">
			<div className={classes.tooltip}>
				<Typography className={classes.text}>
					{formatTime(label)}
				</Typography>
				<Typography className={classes.text}>
					{formatDistance(distance, distanceUnit)}
				</Typography>
				<Typography className={classes.text}>
					{formatTime(pace, paceUnit)}
				</Typography>
				{heartRate && (
					<Typography
						className={classes.text}
					>{`${heartRate} bpm`}</Typography>
				)}
				{(elevation || elevation === 0) && (
					<Typography className={classes.text}>
						{formatElevation(elevation, elevationUnit)}
					</Typography>
				)}
			</div>
		</Paper>
	);
};

export default TooltipContent;
