import { action, Action } from 'easy-peasy';

import { initialState } from '../store';
import log, { LogModel } from './log';
import day, { DayModel } from './day';
import tags, { TagsModel } from './tags';
import user, { UserModel } from './user';
import shoes, { ShoesModel } from './shoes';
import foods, { FoodsModel } from './foods';
import health, { HealthModel } from './health';
import uploads, { UploadsModel } from './uploads';
import activity, { ActivityModel } from './activity';
import settings, { SettingsModel } from './settings';
import dashboard, { DashboardModel } from './dashboard';
import activities, { ActivitiesModel } from './activities';
import notifications, { NotificationsModel } from './notifications';

export interface StoreModel {
	log: LogModel;
	day: DayModel;
	tags: TagsModel;
	user: UserModel;
	shoes: ShoesModel;
	foods: FoodsModel;
	health: HealthModel;
	uploads: UploadsModel;
	activity: ActivityModel;
	settings: SettingsModel;
	dashboard: DashboardModel;
	activities: ActivitiesModel;
	notifications: NotificationsModel;
	reset: Action<StoreModel>;
	resetMessage: boolean;
	setResetMessage: Action<StoreModel, boolean>;
}

const model: StoreModel = {
	log,
	day,
	tags,
	user,
	shoes,
	foods,
	health,
	uploads,
	activity,
	settings,
	dashboard,
	activities,
	notifications,
	reset: action(() => (initialState as unknown) as void),
	resetMessage: false,
	setResetMessage: action((state, value) => {
		state.resetMessage = value;
	}),
};

export default model;
