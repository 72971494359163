import { action, Action, thunk, Thunk } from 'easy-peasy';

import { StoreModel } from '.';
import { axiosService } from '../services/axios';

export interface Highlight {
	min: number;
	max: number;
}

export interface Position {
	latitude: number;
	longitude: number;
}

export interface PositionStream extends Position {
	time: number;
}

export interface Stream extends Position {
	time: number;
	distance: number;
	pace: number;
	heartRate?: number;
	elevation?: number;
}

export interface Lap {
	number: number;
	duration: number;
	distance: number;
	totalDistance: number;
	avgHeartRate?: number;
	avgCadence: number;
	avgPace: number;
	ascent?: number;
	descent?: number;
	start: number;
	end: number;
}

export interface ActivityShoe {
	name: string;
	distance: number;
}

export interface Activity extends ActivityData {
	id: string;
	name: string;
	date: string;
	distance: number;
	unit: string;
	time: number;
	duration: number;
	pauseTime: number;
	avgHeartRate?: number;
	maxHeartRate?: number;
	avgPace: number;
	maxPace: number;
	avgCadence: number;
	maxCadence: number;
	ascent?: number;
	descent?: number;
	calories: number;
	hasGps: boolean;
	description?: string;
	tags: string[];
	shoes: ActivityShoe[];
}

export interface ActivityDetails {
	id: string;
	name: string;
	date: string;
	distance: number;
	unit: string;
	time: number;
	duration: number;
	pauseTime: number;
	avgHeartRate?: number;
	maxHeartRate?: number;
	avgPace: number;
	maxPace: number;
	avgCadence: number;
	maxCadence: number;
	ascent?: number;
	descent?: number;
	calories: number;
	hasGps: boolean;
	description?: string;
	tags: string[];
	shoes: ActivityShoe[];
	laps?: Lap[];
}

export interface ActivityData {
	streams: Stream[];
	laps: Lap[];
}

export interface AddActivity {
	uploadId: string;
	base64: string;
}

export interface UpdateActivity {
	name?: string;
	tags?: string[];
	shoes?: ActivityShoe[];
	description?: string;
}

export interface ActivityModel {
	selected: string[];
	setSelected: Action<ActivityModel, string[]>;
	hoverHighlight: Highlight | undefined;
	setMapHighlight: Action<ActivityModel, Highlight | undefined>;
	mapPosition: number;
	setMapPosition: Action<ActivityModel, number>;
	graphSelected: number;
	setGraphSelected: Action<ActivityModel>;
	clickHighlight: Highlight | undefined;
	setGraphHighlight: Action<ActivityModel, Highlight | undefined>;
	pace: boolean;
	setPace: Action<ActivityModel>;
	heartRate: boolean;
	setHeartRate: Action<ActivityModel>;
	activity: Activity | undefined;
	setActivity: Action<ActivityModel, Activity>;
	clearActivity: Action<ActivityModel, string>;
	setActivityDetails: Action<ActivityModel, ActivityDetails>;
	addActivity: Thunk<
		ActivityModel,
		AddActivity,
		undefined,
		StoreModel,
		Promise<Activity>
	>;
	getActivity: Thunk<
		ActivityModel,
		string,
		undefined,
		StoreModel,
		Promise<Activity>
	>;
	updateActivity: Thunk<
		ActivityModel,
		{ activityId: string; params: UpdateActivity },
		undefined,
		StoreModel,
		Promise<ActivityDetails>
	>;
	removeActivity: Thunk<
		ActivityModel,
		string,
		undefined,
		StoreModel,
		Promise<void>
	>;
}

const addActivity = async ({
	uploadId,
	base64,
}: AddActivity): Promise<Activity> => {
	try {
		const response = await axiosService.post(
			'/api/activities',
			{ uploadId, base64 },
			{
				headers: {
					authorization: localStorage.getItem('JWT'),
				},
			},
		);
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const getActivity = async (activityId: string): Promise<Activity> => {
	try {
		const response = await axiosService.get(
			`/api/activities/${activityId}`,
			{
				headers: {
					authorization: localStorage.getItem('JWT'),
				},
			},
		);
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const updateActivity = async (
	activityId: string,
	params: UpdateActivity,
): Promise<ActivityDetails> => {
	try {
		const response = await axiosService.patch(
			`/api/activities/${activityId}`,
			{ ...params },
			{
				headers: {
					authorization: localStorage.getItem('JWT'),
				},
			},
		);
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const removeActivity = async (activityId: string): Promise<void> => {
	try {
		await axiosService.delete(`/api/activities/${activityId}`, {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
	} catch (err) {
		throw err;
	}
};

const activity: ActivityModel = {
	selected: [],
	setSelected: action((state, value) => {
		state.selected = value;
	}),
	clickHighlight: undefined,
	setGraphHighlight: action((state, value) => {
		state.clickHighlight = value;
	}),
	hoverHighlight: undefined,
	setMapHighlight: action((state, value) => {
		state.hoverHighlight = value;
	}),
	pace: true,
	setPace: action((state) => {
		state.pace = !state.pace;
	}),
	heartRate: true,
	setHeartRate: action((state) => {
		state.heartRate = !state.heartRate;
	}),
	graphSelected: 2,
	setGraphSelected: action((state) => {
		const { pace, heartRate } = state;
		let count = 0;
		if (pace) {
			count++;
		}
		if (heartRate) {
			count++;
		}
		state.graphSelected = count;
	}),
	mapPosition: 0,
	setMapPosition: action((state, value) => {
		state.mapPosition = value;
	}),
	activity: undefined,
	setActivity: action((state, value) => {
		state.activity = value;
	}),
	clearActivity: action((state, value) => {
		if (state.activity && state.activity.id === value) {
			state.activity = undefined;
		}
	}),
	setActivityDetails: action((state, value) => {
		if (state.activity) {
			const data = state.activity.streams;
			const laps = state.activity.laps;
			state.activity = {
				...value,
				streams: data,
				laps,
			};
		}
	}),
	addActivity: thunk(async (_actions, value) => {
		try {
			const activity = await addActivity(value);
			return activity;
		} catch (err) {
			throw err;
		}
	}),
	getActivity: thunk(async (_actions, value) => {
		try {
			const activity = await getActivity(value);
			return activity;
		} catch (err) {
			throw err;
		}
	}),
	updateActivity: thunk(async (_actions, value) => {
		try {
			const activity = await updateActivity(
				value.activityId,
				value.params,
			);
			return activity;
		} catch (err) {
			throw err;
		}
	}),
	removeActivity: thunk(async (actions, value) => {
		try {
			await removeActivity(value);
			actions.clearActivity(value);
		} catch (err) {
			throw err;
		}
	}),
};

export default activity;
