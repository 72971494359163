import React from 'react';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import { useStoreState } from '../../../../store';
import { formatDistance, formatTime } from '../../../../utils/formatters';

const Info: React.FC = () => {
	const classes = useStyles();

	const activity = useStoreState((state) => state.activity.activity);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const paceUnit = useStoreState((state) => state.settings.paceUnit);

	return (
		<div>
			{activity && (
				<div className={classes.header}>
					<div className={classes.headerBlock}>
						<Typography variant="subtitle1">Distance</Typography>
						<Typography
							variant="h5"
							style={{ fontWeight: 'lighter' }}
						>
							{formatDistance(activity.distance, distanceUnit)}
						</Typography>
					</div>
					<div className={classes.headerBlock}>
						<Typography variant="subtitle1">Pace</Typography>
						<Typography
							variant="h5"
							style={{ fontWeight: 'lighter' }}
						>
							{formatTime(activity.avgPace, paceUnit)}
						</Typography>
					</div>
					<div className={classes.headerBlock}>
						<Typography variant="subtitle1">Time</Typography>
						<Typography
							variant="h5"
							style={{ fontWeight: 'lighter' }}
						>
							{formatTime(activity.duration - activity.pauseTime)}
						</Typography>
					</div>
				</div>
			)}
		</div>
	);
};

export default Info;
