import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	day: {
		marginLeft: theme.spacing() / 2,
		marginRight: theme.spacing() / 2,
		marginBottom: theme.spacing(),
		boxShadow: '0px 2px 10px 1px rgba(0, 0, 0, 0.05)',
	},
	rowLink: {
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
		color: theme.palette.common.black,
	},
	successIconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $successIcon': {
				color: theme.palette.success.main,
			},
		},
		padding: 0,
		cursor: 'default',
	},
	errorIconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $errorIcon': {
				color: theme.palette.error.main,
			},
		},
		padding: 0,
		cursor: 'default',
	},
	successIcon: {
		color: theme.palette.success.light,
	},
	errorIcon: {
		color: theme.palette.error.light,
	},
}));
