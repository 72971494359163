import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import globals from '../../utils/globals';

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
	},
	container: {
		display: 'flex',
		maxWidth: globals.maxWidth,
		margin: 'auto',
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
	},
	text: {
		fontSize: 14,
	},
});

const Footer: React.FC = () => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<div className={classes.container}>
				<Typography className={classes.text}>
					Tuff Trainer - Joseph Tuffin 2021.
				</Typography>
			</div>
		</div>
	);
};

export default Footer;
