import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import { useStyles } from './styles';
import FootprintIcon from '../../../Icons/FootprintIcon';
import { TooltipNoMargin as Tooltip } from '../../../Tooltip';

export interface Props {
	handleExpandClick: () => void;
	handleRemoveClick: () => void;
	handleTagsClick: () => void;
	handleShoesClick: () => void;
}

const Icons: React.FC<Props> = ({
	handleExpandClick,
	handleRemoveClick,
	handleTagsClick,
	handleShoesClick,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Tooltip title={'Edit shoes'}>
				<IconButton
					className={classes.svgButton}
					onClick={handleShoesClick}
				>
					<FootprintIcon className={classes.svg} />
				</IconButton>
			</Tooltip>
			<Tooltip title={'Edit tags'}>
				<IconButton
					className={classes.iconButton}
					onClick={handleTagsClick}
				>
					<LocalOfferIcon className={classes.icon} />
				</IconButton>
			</Tooltip>
			<Tooltip title={'Remove'}>
				<IconButton
					className={classes.iconButton}
					onClick={handleRemoveClick}
				>
					<DeleteIcon className={classes.icon} />
				</IconButton>
			</Tooltip>
			<Tooltip title={'Details'}>
				<IconButton
					onClick={handleExpandClick}
					className={classes.iconButton}
				>
					<ExpandMoreIcon className={classes.icon} fontSize="large" />
				</IconButton>
			</Tooltip>
		</div>
	);
};

export default Icons;
