import moment from 'moment';

export const formatTime = (
	seconds: number,
	unit?: string,
	decimal = false,
): string => {
	let adjustedSeconds = seconds;
	if (unit && unit === '/mi') {
		adjustedSeconds = adjustedSeconds * 1.60934;
	}
	const h = Math.floor(adjustedSeconds / 3600).toString();
	const m = Math.floor((adjustedSeconds % 3600) / 60).toString();
	const s = Math.floor(adjustedSeconds % 60).toString();
	const f = ((adjustedSeconds % 60) - Number(s)).toFixed(2);

	if (unit) {
		const value = Number(h)
			? h + ':' + m.padStart(2, '0') + ':' + s.padStart(2, '0')
			: Number(m)
			? m + ':' + s.padStart(2, '0')
			: s;
		return `${value} ${unit}`;
	}

	const formatted =
		h.padStart(2, '0') +
		':' +
		m.padStart(2, '0') +
		':' +
		s.padStart(2, '0');

	if (decimal) {
		return formatted + '.' + f.substring(2);
	}

	return formatted;
};

export const formatDistance = (
	distance: number,
	unit: string,
	round = false,
): string => {
	return `${(distance / (unit === 'km' ? 1000 : 1609.34)).toFixed(
		round ? 0 : 2,
	)} ${unit}`;
};

export const formatDate = (date: string, format = 'DD/MM/YYYY'): string => {
	const formattedDate = moment(date).format(format);
	return formattedDate;
};

export const formatDateAsNumber = (date: string): number => {
	const formattedDate = moment(date).valueOf();
	return formattedDate;
};

export const formatElevation = (
	elevation: number | string,
	unit: string,
): string => {
	if (typeof elevation === 'string') {
		return elevation;
	}
	return unit === 'm'
		? `${elevation} m`
		: `${Math.round(elevation * 3.28084)} ft`;
};
