import { action, Action, thunk, Thunk } from 'easy-peasy';

import { StoreModel } from '.';
import { axiosService } from '../services/axios';

export interface Health {
	id: string;
	height: number;
	weight: number;
	calories: number;
	date: string;
	carbs: number;
	fat: number;
	protein: number;
}

export interface AddHealth {
	height: number;
	weight: number;
	calories: number;
	date: string;
	carbs: number;
	fat: number;
	protein: number;
}

export interface HealthModel {
	healthStats: Health[];
	setHealthStats: Action<HealthModel, Health[]>;
	addHealth: Thunk<
		HealthModel,
		AddHealth,
		undefined,
		StoreModel,
		Promise<Health>
	>;
	getHealthStats: Thunk<
		HealthModel,
		undefined,
		undefined,
		StoreModel,
		Promise<Health[]>
	>;
}

const addHealth = async (health: AddHealth): Promise<Health> => {
	try {
		const response = await axiosService.post('/api/health', health, {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const getHealthStats = async (): Promise<Health[]> => {
	try {
		const response = await axiosService.get('/api/health', {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const tag: HealthModel = {
	healthStats: [],
	setHealthStats: action((state, value) => {
		state.healthStats = value;
	}),
	addHealth: thunk(async (_actions, value) => {
		try {
			const tag = await addHealth(value);
			return tag;
		} catch (err) {
			throw err;
		}
	}),
	getHealthStats: thunk(async (actions) => {
		try {
			const healthStats = await getHealthStats();
			actions.setHealthStats(healthStats);
			return healthStats;
		} catch (err) {
			throw err;
		}
	}),
};

export default tag;
