import makeStyles from '@material-ui/core/styles/makeStyles';
import globals from '../../utils/globals';

export const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		height: 60,
		backgroundColor: theme.palette.background.paper,
		boxShadow: '0px 2px 10px 1px rgba(0, 0, 0, 0.05)',
		marginBottom: theme.spacing(),
	},
	toolbar: {
		maxWidth: globals.maxWidth,
		margin: 'auto',
		padding: 0,
		width: '100%',
		minHeight: 60,
	},
}));
