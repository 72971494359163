import React from 'react';
import { Button, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import Dialog from '../../Dialog';
import { useStoreActions, useStoreState } from '../../../store';
import Tooltip from '../../Tooltip';

export interface Props {
	handleRemoveSelected: () => Promise<void>;
	handleReset: () => void;
}

export const Selection: React.FC<Props> = ({
	handleRemoveSelected,
	handleReset,
}) => {
	const classes = useStyles();

	const theme = useStoreState((state) => state.settings.theme);
	const removeSelected = useStoreState(
		(state) => state.activities.removeSelected,
	);
	const length = useStoreState((state) => state.activities.length);
	const activities = useStoreState((state) => state.activities.activities);
	const selected = useStoreState((state) => state.activity.selected);
	const setRemoveSelected = useStoreActions(
		(actions) => actions.activities.setRemoveSelected,
	);
	const setCompare = useStoreActions(
		(actions) => actions.activities.setCompare,
	);
	const setCompareIds = useStoreActions(
		(actions) => actions.activities.setCompareIds,
	);

	return (
		<div className={classes.root}>
			<Tooltip
				title={
					selected.length < 1
						? 'Select at least one activity to compare'
						: selected.length > 5
						? 'Select five or less activities to compare'
						: 'Compare selected activities'
				}
				style={{ marginRight: theme.spacing() }}
			>
				<span>
					<Button
						className={classes.button}
						disabled={!selected.length || selected.length > 5}
						onClick={() => {
							setCompareIds(selected);
							setCompare();
						}}
					>
						Compare
					</Button>
				</span>
			</Tooltip>
			<Tooltip
				title="Remove selected activities"
				style={{ marginRight: theme.spacing() }}
			>
				<span>
					<Button
						className={classes.button}
						disabled={!selected.length}
						onClick={() => setRemoveSelected()}
					>
						Remove
					</Button>
				</span>
			</Tooltip>
			<Tooltip
				title="Reset to default"
				style={{ marginRight: theme.spacing() }}
			>
				<Button className={classes.button} onClick={handleReset}>
					Reset
				</Button>
			</Tooltip>
			<div className={classes.details}>
				<Typography>{`Selected ${selected.length} activities`}</Typography>
				<Typography
					className={classes.detail}
				>{`Showing ${length} of ${activities.length} activities`}</Typography>
			</div>
			<Dialog
				open={removeSelected}
				handleDialogConfirmClick={handleRemoveSelected}
				handleDialogClick={() => setRemoveSelected()}
				title="Remove selected Activities"
				content="Are you sure you want to remove the selected activities?"
				confirm="Remove"
			/>
		</div>
	);
};

export default Selection;
