import React, { useEffect } from 'react';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@material-ui/core';
import { useStyles } from './styles';
import { useStoreActions, useStoreState } from '../../../store';
import { getSelected } from '../../Activities/utils';

export interface Props {
	openTags: boolean;
	handleTagsClick: () => void;
	handleTagsConfirmClick: () => Promise<void>;
	title?: string;
}

const Tags: React.FC<Props> = ({
	openTags,
	handleTagsClick,
	handleTagsConfirmClick,
	title,
}) => {
	const classes = useStyles();

	const activity = useStoreState((state) => state.activity.activity);
	const tags = useStoreState((state) => state.tags.tags);
	const selected = useStoreState((state) => state.tags.selected);
	const setSelected = useStoreActions((actions) => actions.tags.setSelected);

	const isSelected = (name: string) => selected.indexOf(name) !== -1;

	const handleCheckbox = (name: string) => {
		const newSelected = getSelected(name, selected);
		setSelected(newSelected);
	};

	useEffect(() => {
		if (activity) {
			const newSelected: string[] = [];
			const usedTags = activity.tags;
			tags.forEach((tag) => {
				if (usedTags.includes(tag.name)) {
					newSelected.push(tag.name);
				}
			});
			setSelected(newSelected);
		}
	}, [setSelected, activity, tags]);

	return (
		<Dialog open={openTags} onClose={handleTagsClick}>
			<DialogTitle>{title ? title : 'Edit Tags'}</DialogTitle>
			<DialogContent
				style={{ maxHeight: 400, width: 250, overflow: 'auto' }}
			>
				{tags.map((tag) => {
					const isItemSelected = isSelected(tag.name);
					return (
						<div className={classes.tag} key={tag.name}>
							<Typography>{tag.name}</Typography>
							<Checkbox
								color="primary"
								checked={isItemSelected}
								onClick={() => handleCheckbox(tag.name)}
							/>
						</div>
					);
				})}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleTagsConfirmClick}
					className={classes.primaryButton}
				>
					Save
				</Button>
				<Button
					onClick={handleTagsClick}
					className={classes.secondaryButton}
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default Tags;
