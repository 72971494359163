import React from 'react';
import { Divider, Typography } from '@material-ui/core';

import {
	formatDate,
	formatDistance,
	formatElevation,
	formatTime,
} from '../../../../utils/formatters';
import { Lap } from '../../../../models/activity';
import { useStoreState } from '../../../../store';
import { TooltipNoMargin as Tooltip } from '../../../Tooltip';
import { useStyles } from './styles';

export interface Props {
	name: string;
	date: string;
	distance: number;
	avgPace: number;
	time: number;
	avgHeartRate?: number;
	avgCadence: number;
	ascent?: number;
	laps?: Lap[];
}

const Header: React.FC<Props> = ({
	name,
	date,
	distance,
	avgPace,
	time,
	avgHeartRate,
	ascent,
	laps,
}) => {
	const classes = useStyles();

	const paceUnit = useStoreState((state) => state.settings.paceUnit);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const elevationUnit = useStoreState(
		(state) => state.settings.elevationUnit,
	);

	return (
		<div className={classes.root}>
			<Tooltip title={name}>
				<Typography align="center" noWrap className={classes.title}>
					{name}
				</Typography>
			</Tooltip>
			<Divider style={{ width: '100%' }} />
			<Typography>{formatDate(date)}</Typography>
			<Typography>{formatDistance(distance, distanceUnit)}</Typography>
			<Typography>{formatTime(avgPace, paceUnit)}</Typography>
			<Typography>{formatTime(time)}</Typography>
			<Typography>
				{avgHeartRate ? `${avgHeartRate} bpm` : 'n/a'}
			</Typography>
			<Typography>
				{ascent || ascent === 0
					? formatElevation(ascent, elevationUnit)
					: 'n/a'}
			</Typography>
			<Divider style={{ width: '100%' }} />
			<Tooltip title={`${laps && laps.length} laps`}>
				<Typography align="center" className={classes.title}>
					Laps
				</Typography>
			</Tooltip>
			<Divider style={{ width: '100%' }} />
		</div>
	);
};

export default Header;
