import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { useStylesMobile as useStyles } from './styles';
import { menuItems } from './utils';
import { Link } from 'react-router-dom';

export interface Props {
	open: boolean;
	handleClose: () => void;
	handleOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
	anchorEl: Element | null;
}

const MobileNavigation: React.FC<Props> = ({
	open,
	handleClose,
	handleOpen,
	anchorEl,
}) => {
	const classes = useStyles();

	return (
		<>
			<IconButton
				edge="start"
				className={classes.menuIconButton}
				aria-label="menu"
				onClick={handleOpen}
			>
				<MenuIcon className={classes.menuIcon} />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={open}
				onClose={handleClose}
				PaperProps={{
					style: {
						boxShadow: '0px 2px 10px 1px rgba(0, 0, 0, 0.05)',
					},
				}}
				MenuListProps={{ onMouseLeave: handleClose }}
			>
				{menuItems.map((menuItem) => {
					const { menuTitle, pageURL } = menuItem;

					return (
						<MenuItem
							key={pageURL}
							onClick={handleClose}
							component={Link}
							to={pageURL}
						>
							{menuTitle}
						</MenuItem>
					);
				})}
			</Menu>
		</>
	);
};

export default MobileNavigation;
