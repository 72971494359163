import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';

import Graph from './Graph';
import Error from '../Error';
import Title from '../Title';
import Summary from './Summary';
import Loading from '../Loading';
import { useStyles } from './styles';
import useWindowDimensions from '../../utils/windowSize';
import { useStoreActions, useStoreState } from '../../store';

const Dashboard: React.FC = () => {
	const classes = useStyles();

	const { height } = useWindowDimensions();

	const theme = useStoreState((state) => state.settings.theme);
	const user = useStoreState((state) => state.user.user);
	const activities = useStoreState((state) => state.activities.activities);
	const allEntries = useStoreState((state) => state.foods.allEntries);
	const healthStats = useStoreState((state) => state.health.healthStats);
	const resetMessage = useStoreState((state) => state.resetMessage);
	const reset = useStoreState((state) => state.foods.reset);
	const setReset = useStoreActions((actions) => actions.foods.setReset);
	const getActivities = useStoreActions(
		(actions) => actions.activities.getActivities,
	);
	const getAllEntries = useStoreActions(
		(actions) => actions.foods.getAllEntries,
	);
	const getHealthStats = useStoreActions(
		(actions) => actions.health.getHealthStats,
	);
	const setResetMessage = useStoreActions(
		(actions) => actions.setResetMessage,
	);

	const [loading, setLoading] = useState(true);

	const handleClose = () => {
		setResetMessage(false);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (!activities.length) {
					await getActivities();
				}
				if (!allEntries.length || reset) {
					setReset(false);
					await getAllEntries();
				}
				if (!healthStats.length) {
					await getHealthStats();
				}
				setLoading(false);
			} catch (err) {}
		};
		fetchData();
	}, [getActivities, getAllEntries, getHealthStats]);

	return (
		<div className={classes.root}>
			{loading ? (
				<Loading />
			) : (
				<div
					className={classes.container}
					style={{
						maxHeight: height - 60 - theme.spacing(3),
						overflow: 'auto',
					}}
				>
					<Title title={`${user.name}'s Dashboard`} />
					<div className={classes.container}>
						<Paper className={classes.paper}>
							<Graph />
						</Paper>
						<Paper className={classes.paper}>
							<Summary />
						</Paper>
					</div>
				</div>
			)}
			{!loading && (
				<Error
					handleClose={handleClose}
					redirectError={resetMessage}
					title="New user logged in"
					severity="info"
				/>
			)}
		</div>
	);
};

export default Dashboard;
