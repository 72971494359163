import makeStyles from '@material-ui/core/styles/makeStyles';
import globals from '../../../utils/globals';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(),
	},
	text: {
		fontSize: 18,
	},
	icon: {
		color: theme.palette.primary.main,
	},
	alert: {
		margin: 'auto',
		maxWidth: globals.maxWidth,
		left: 0,
		right: 0,
	},
	chip: {
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
}));
