import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
	},
	paper: {
		width: '100%',
		maxWidth: '350px',
	},
	loginTitle: {
		fontSize: 24,
		fontWeight: 'bold',
		marginTop: theme.spacing(),
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		margin: theme.spacing(),
	},
	formItem: {
		width: '100%',
	},
	primaryButton: {
		marginTop: theme.spacing(),
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		color: theme.palette.defaultBlack,
	},
	secondaryButton: {
		marginTop: theme.spacing(),
		backgroundColor: theme.palette.grey[300],
		'&:hover': {
			backgroundColor: theme.palette.grey.A100,
		},
		color: theme.palette.defaultBlack,
	},
	error: {
		width: '100%',
		maxWidth: '350px',
		paddingLeft: 0,
		paddingRight: 0,
		marginTop: theme.spacing(),
	},
	errorPlaceholder: {
		height: 48,
		marginTop: theme.spacing(),
	},
}));
