import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useStyles } from './styles';
import { mapError } from './utils';

interface Props {
	error: number;
}

const Error: React.FC<Props> = ({ error }) => {
	const classes = useStyles();

	return error === 200 ? (
		<Alert severity="success" variant="filled" className={classes.error}>
			{mapError(error)}
		</Alert>
	) : error ? (
		<Alert severity="error" variant="filled" className={classes.error}>
			{mapError(error)}
		</Alert>
	) : (
		<div className={classes.errorPlaceholder}></div>
	);
};

export default Error;
