import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import { formatDistance, formatTime } from '../../../../utils/formatters';
import { useStoreState } from '../../../../store';

export interface Props {
	duration: number;
	distance: number;
	avgPace: number;
	avgHeartRate: number;
}

const TooltipContent: React.FC<Props> = ({
	duration,
	distance,
	avgHeartRate,
	avgPace,
}) => {
	const classes = useStyles();

	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const paceUnit = useStoreState((state) => state.settings.paceUnit);

	return (
		<Paper className={classes.paper} variant="outlined">
			<div className={classes.tooltip}>
				<Typography className={classes.text}>
					{formatTime(duration)}
				</Typography>
				<Typography className={classes.text}>{`${formatDistance(
					distance,
					distanceUnit,
				)}`}</Typography>
				<Typography className={classes.text}>
					{formatTime(avgPace, paceUnit)}
				</Typography>
				{avgHeartRate && (
					<Typography
						className={classes.text}
					>{`${avgHeartRate} bpm`}</Typography>
				)}
			</div>
		</Paper>
	);
};

export default TooltipContent;
