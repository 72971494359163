import React from 'react';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import { IconButton } from '@material-ui/core';

import TitleForm from './TitleForm';
import { useStyles } from './styles';
import Tooltip from '../../../Tooltip';
import { formatDate } from '../../../../utils/formatters';
import { useStoreState } from '../../../../store';

export interface Props {
	edit: boolean;
	setEdit: React.Dispatch<React.SetStateAction<boolean>>;
	date: string;
	name: string;
	handleEditClick: () => void;
}

const Title: React.FC<Props> = ({
	edit,
	setEdit,
	date,
	name,
	handleEditClick,
}) => {
	const classes = useStyles();

	const timeFormat = useStoreState((state) => state.settings.timeFormat);
	const theme = useStoreState((state) => state.settings.theme);

	return (
		<div className={classes.root}>
			<Typography variant="subtitle1">
				{formatDate(
					date,
					`dddd Do MMMM yyyy, ${
						timeFormat === '12h' ? 'h:mm:ss a' : 'HH:mm:ss'
					}`,
				)}
			</Typography>
			{edit ? (
				<TitleForm setEdit={setEdit} />
			) : (
				<div className={classes.title}>
					<Typography variant="h5">{name}</Typography>
					<Tooltip title={'Edit name'}>
						<IconButton
							className={classes.iconButton}
							style={{
								padding: 0,
								marginLeft: theme.spacing(),
							}}
							onClick={handleEditClick}
						>
							<EditIcon className={classes.icon} />
						</IconButton>
					</Tooltip>
				</div>
			)}
		</div>
	);
};

export default Title;
