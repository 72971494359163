import { action, Action, thunk, Thunk } from 'easy-peasy';

import { StoreModel } from '.';
import { ActivityDetails } from './activity';
import { FiltersData } from '../components/Activities/interfaces';
import { axiosService } from '../services/axios';

export type Order = 'asc' | 'desc';

export interface Filter {
	type: string;
	value?: string | number | Date | unknown[];
	operator: string;
	unit?: string;
	secondary?: string | number | Date;
	nesting?: string;
}

interface GetActivities {
	activityIds?: string[];
	date?: string;
}

export interface ActivitiesModel {
	redirectError: boolean;
	setRedirectError: Action<ActivitiesModel, boolean>;
	filtersData: FiltersData;
	setFiltersData: Action<ActivitiesModel, FiltersData>;
	filters: Filter[];
	setFilters: Action<ActivitiesModel, Filter[]>;
	order: Order;
	setOrder: Action<ActivitiesModel, Order>;
	orderBy: keyof ActivityDetails;
	setOrderBy: Action<ActivitiesModel, keyof ActivityDetails>;
	removeOne: boolean;
	setRemoveOne: Action<ActivitiesModel>;
	removeSelected: boolean;
	setRemoveSelected: Action<ActivitiesModel>;
	removeId: string | undefined;
	setRemoveId: Action<ActivitiesModel, string | undefined>;
	length: number;
	setLength: Action<ActivitiesModel, number>;
	expand: boolean;
	setExpand: Action<ActivitiesModel, boolean>;
	date: Date | null;
	setDate: Action<ActivitiesModel, Date | null>;
	dateSecondary: Date | null;
	setDateSecondary: Action<ActivitiesModel, Date | null>;
	selectedTags: string[];
	setSelectedTags: Action<ActivitiesModel, string[]>;
	selectedShoes: string[];
	setSelectedShoes: Action<ActivitiesModel, string[]>;
	page: number;
	setPage: Action<ActivitiesModel, number>;
	rowsPerPage: number;
	setRowsPerPage: Action<ActivitiesModel, number>;
	compare: boolean;
	setCompare: Action<ActivitiesModel>;
	compareIds: string[];
	setCompareIds: Action<ActivitiesModel, string[]>;
	filteredIds: string[];
	setFilteredIds: Action<ActivitiesModel, string[]>;
	activities: ActivityDetails[];
	compareActivities: ActivityDetails[];
	setCompareActivities: Action<ActivitiesModel, ActivityDetails[]>;
	getCompareActivities: Thunk<
		ActivitiesModel,
		GetActivities,
		undefined,
		StoreModel,
		Promise<ActivityDetails[]>
	>;
	setActivities: Action<ActivitiesModel, ActivityDetails[]>;
	getActivities: Thunk<
		ActivitiesModel,
		undefined,
		undefined,
		StoreModel,
		Promise<ActivityDetails[]>
	>;
}

export const getActivities = async (
	params?: GetActivities,
): Promise<ActivityDetails[]> => {
	try {
		const response = await axiosService.get('/api/activities', {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
			params,
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const activities: ActivitiesModel = {
	redirectError: false,
	setRedirectError: action((state, value) => {
		state.redirectError = value;
	}),
	filtersData: {},
	setFiltersData: action((state, value) => {
		state.filtersData = value;
	}),
	filters: [],
	setFilters: action((state, value) => {
		state.filters = value;
	}),
	order: 'desc',
	setOrder: action((state, value) => {
		state.order = value;
	}),
	orderBy: 'date',
	setOrderBy: action((state, value) => {
		state.orderBy = value;
	}),
	removeOne: false,
	setRemoveOne: action((state) => {
		state.removeOne = !state.removeOne;
	}),
	removeSelected: false,
	setRemoveSelected: action((state) => {
		state.removeSelected = !state.removeSelected;
	}),
	removeId: undefined,
	setRemoveId: action((state, value) => {
		state.removeId = value;
	}),
	length: 0,
	setLength: action((state, value) => {
		state.length = value;
	}),
	expand: false,
	setExpand: action((state, value) => {
		state.expand = value;
	}),
	date: null,
	setDate: action((state, value) => {
		state.date = value;
	}),
	dateSecondary: null,
	setDateSecondary: action((state, value) => {
		state.dateSecondary = value;
	}),
	selectedTags: [],
	setSelectedTags: action((state, value) => {
		state.selectedTags = value;
	}),
	selectedShoes: [],
	setSelectedShoes: action((state, value) => {
		state.selectedShoes = value;
	}),
	page: 0,
	setPage: action((state, value) => {
		state.page = value;
	}),
	rowsPerPage: 5,
	setRowsPerPage: action((state, value) => {
		state.rowsPerPage = value;
	}),
	compare: false,
	setCompare: action((state) => {
		state.compare = !state.compare;
	}),
	compareIds: [],
	setCompareIds: action((state, value) => {
		state.compareIds = value;
	}),
	filteredIds: [],
	setFilteredIds: action((state, value) => {
		state.filteredIds = value;
	}),
	activities: [],
	setActivities: action((state, value) => {
		state.activities = value;
	}),
	compareActivities: [],
	setCompareActivities: action((state, value) => {
		state.compareActivities = value;
	}),
	getActivities: thunk(async (actions) => {
		try {
			const activities = await getActivities();
			actions.setActivities(activities);
			return activities;
		} catch (err) {
			throw err;
		}
	}),
	getCompareActivities: thunk(async (actions, value) => {
		try {
			const activities = await getActivities(value);
			actions.setCompareActivities(activities);
			return activities;
		} catch (err) {
			throw err;
		}
	}),
};

export default activities;
