import React, { useState } from 'react';

import { useStoreState } from '../../../../store';
import { Activity } from '../../../../models/activity';
import PTitle, { Props } from './Title';

const Title: React.FC = () => {
	const activity = useStoreState(
		(state) => state.activity.activity,
	) as Activity;

	const [edit, setEdit] = useState(false);

	const handleEditClick = () => {
		setEdit((value) => !value);
	};

	const props: Props = {
		edit,
		setEdit,
		handleEditClick,
		date: activity.date,
		name: activity.name,
	};

	return <PTitle {...props} />;
};

export default Title;
