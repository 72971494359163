import React from 'react';
import { Link } from 'react-router-dom';
import { UseFormMethods } from 'react-hook-form';

import { useStyles } from './styles';
import Alert from '@material-ui/lab/Alert';
import { ResetFormData } from './interfaces';
import { Paper, Typography, TextField, Button } from '@material-ui/core';

export interface Props
	extends Pick<
		UseFormMethods<ResetFormData>,
		'handleSubmit' | 'register' | 'errors' | 'watch'
	> {
	error: number;
	handleFormSubmit: (data: ResetFormData) => Promise<void>;
}

const ResetPassword: React.FC<Props> = ({
	register,
	errors,
	watch,
	error,
	handleSubmit,
	handleFormSubmit,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Paper className={classes.paper}>
				<Typography className={classes.loginTitle} align="center">
					Reset Password
				</Typography>
				<form>
					<div className={classes.form}>
						<TextField
							inputRef={register({
								required: true,
								minLength: 8,
							})}
							label={
								errors.password
									? 'must be at least 8 characters'
									: 'Password'
							}
							name="password"
							type="password"
							className={classes.formItem}
							error={errors.password ? true : false}
						/>
						<TextField
							inputRef={register({
								validate: (value: string) => {
									return value === watch('password');
								},
							})}
							label={
								errors.confirmPassword
									? 'passwords must match'
									: 'Confirm password'
							}
							name="confirmPassword"
							type="password"
							className={classes.formItem}
							error={errors.confirmPassword ? true : false}
						/>
						<Button
							type="submit"
							onClick={handleSubmit(handleFormSubmit)}
							className={`${classes.formItem} ${classes.primaryButton}`}
						>
							Reset Password
						</Button>
						<Button
							component={Link}
							to={'/login'}
							className={`${classes.formItem} ${classes.secondaryButton}`}
						>
							Login
						</Button>
					</div>
				</form>
			</Paper>
			{error === 200 ? (
				<Alert
					severity="success"
					variant="filled"
					className={classes.error}
				>
					{'Password successfully updated'}
				</Alert>
			) : error ? (
				<Alert
					severity="error"
					variant="filled"
					className={classes.error}
				>
					{'Reset token invalid or expired'}
				</Alert>
			) : (
				<div className={classes.errorPlaceholder}></div>
			)}
		</div>
	);
};

export default ResetPassword;
