import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';

import { ShoeData } from './interfaces';
import PShoes, { Props } from './Shoes';
import { getSelected } from '../../Activities/utils';
import { useStoreState, useStoreActions } from '../../../store';

const Shoes: React.FC = () => {
	const shoes = useStoreState((state) => state.shoes.shoes);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const removeId = useStoreState((state) => state.shoes.removeId);
	const selectedShoes = useStoreState(
		(state) => state.settings.selectedShoes,
	);
	const setSelectedShoes = useStoreActions(
		(actions) => actions.settings.setSelectedShoes,
	);
	const addShoe = useStoreActions((actions) => actions.shoes.addShoe);
	const getShoes = useStoreActions((actions) => actions.shoes.getShoes);
	const removeShoe = useStoreActions((actions) => actions.shoes.removeShoe);
	const setRemoveOne = useStoreActions(
		(actions) => actions.shoes.setRemoveOne,
	);
	const setRemoveSelected = useStoreActions(
		(actions) => actions.shoes.setRemoveSelected,
	);

	const { handleSubmit, register, reset, watch, errors } = useForm<
		ShoeData
	>();

	const [error, setError] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const multiplier = distanceUnit === 'km' ? 1000 : 1609.34;
	const isSelected = (id: string) => selectedShoes.indexOf(id) !== -1;

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = shoes.map((n) => n.id);
			setSelectedShoes(newSelected);
			return;
		}
		setSelectedShoes([]);
	};

	const handleCheckbox = (id: string) => {
		const newSelected = getSelected(id, selectedShoes);
		setSelectedShoes(newSelected);
	};

	const handleChangePage = (_event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const checkPage = (length: number) => {
		setPage(Math.ceil(length / rowsPerPage) - 1);
	};

	const handleRemoveOne = async () => {
		try {
			if (removeId) {
				await removeShoe(removeId);
				const shoes = await getShoes();
				if (isSelected(removeId)) {
					handleCheckbox(removeId);
				}
				setRemoveOne();
				checkPage(shoes.length);
			}
		} catch (err) {}
	};

	const handleRemoveSelected = async () => {
		try {
			for (const id of selectedShoes) {
				try {
					await removeShoe(id);
				} catch (err) {}
			}

			const shoes = await getShoes();
			setRemoveSelected();
			setSelectedShoes([]);
			checkPage(shoes.length);
		} catch (err) {}
	};

	const handleFormSubmit = async (data: ShoeData) => {
		setError(0);
		const distance = data.distance
			? Number(data.distance) * multiplier
			: undefined;
		try {
			await addShoe({
				name: data.name,
				distance,
			});
			const shoes = await getShoes();
			reset({ name: '' });
			checkPage(shoes.length);
		} catch (err) {
			if (err && err.response) {
				setError((err.response as AxiosResponse).status);
			}
		}
	};

	const props: Props = {
		handleSubmit,
		register,
		watch,
		reset,
		errors,
		setError,
		isSelected,
		handleCheckbox,
		handleSelectAll,
		handleRemoveOne,
		handleChangePage,
		handleFormSubmit,
		handleRemoveSelected,
		handleChangeRowsPerPage,
		page,
		error,
		rowsPerPage,
	};

	return <PShoes {...props} />;
};

export default Shoes;
