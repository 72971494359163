import React from 'react';
import { List as MuiList, ListItem, Paper } from '@material-ui/core';

import { useStyles } from './styles';
import Header, { Props as HeaderProps } from '../Header';
import Laps, { Props as LapProps } from '../Laps';
import { Lap } from '../../../../models/activity';
import { useStoreState } from '../../../../store';

export interface Props {
	isSelected: (activityId: number, lapId: number) => boolean;
	handleSelect: (activityId: number, lapId: number) => void;
}

const List: React.FC<Props> = ({ isSelected, handleSelect }) => {
	const classes = useStyles();

	const activities = useStoreState(
		(state) => state.activities.compareActivities,
	);

	return (
		<MuiList
			component="div"
			className={classes.activities}
			style={{
				justifyContent:
					activities.length === 1 ? 'center' : 'flex-start',
			}}
		>
			{activities.map((activity, activityIndex) => {
				const {
					name,
					date,
					distance,
					time,
					avgPace,
					avgHeartRate,
					avgCadence,
					ascent,
					laps,
					id,
				} = activity;

				const headerProps: HeaderProps = {
					name,
					date,
					distance,
					time,
					avgPace,
					avgHeartRate,
					avgCadence,
					ascent,
					laps,
				};

				const lapProps: LapProps = {
					laps: laps as Lap[],
					activityIndex,
					isSelected,
					handleButton: handleSelect,
				};
				return (
					<ListItem
						key={id}
						dense
						disableGutters
						className={classes.activityContainer}
					>
						<Paper variant="outlined" className={classes.activity}>
							<Header {...headerProps} />
							{laps && <Laps {...lapProps} />}
						</Paper>
					</ListItem>
				);
			})}
		</MuiList>
	);
};

export default List;
