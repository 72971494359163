export const average = (array: number[]): number => {
	if (!array.length) {
		return 0;
	}
	const sum = array.reduce((a, b) => a + b);
	return sum / array.length;
};

export const paceAverage = (times: number[], distances: number[]): number => {
	if (!times.length) {
		return 0;
	}
	const time = times.reduce((a, b) => a + b);
	const distance = distances.reduce((a, b) => a + b);
	return (time / distance) * 1000;
};
