import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useStoreState } from '../../../store';
import { Shoe } from '../../Dashboard/Summary';
import PYearlyStats, { Props } from './YearlyStats';
import { ActivityDetails } from '../../../models/activity';
import { formatShoes, getActivities, getShoes } from './utils';
import { getComparator, stableSort } from '../../Activities/Table/utils';

const YearlyStats: React.FC = () => {
	const allActivities = useStoreState((state) => state.activities.activities);

	const [shoes, setShoes] = useState<Shoe[]>([]);
	const [years, setYears] = useState<string[]>([]);
	const [year, setYear] = useState<string>('');
	const [date, setDate] = useState<Date>(new Date());
	const [startDate, setStartDate] = useState<Date>(new Date());
	const [endDate, setEndDate] = useState<Date>(new Date());
	const [activities, setActivities] = useState<ActivityDetails[]>([]);

	const handleSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
		setYear(event.target.value as string);
	};

	useEffect(() => {
		setStartDate(moment(date).startOf('y').toDate());
		setEndDate(moment(date).endOf('y').toDate());
	}, [date]);

	useEffect(() => {
		setActivities(getActivities(allActivities, startDate, endDate));
	}, [allActivities, startDate, endDate]);

	useEffect(() => {
		const sorted = stableSort(allActivities, getComparator('asc', 'date'));
		const startYear = Number(moment(sorted[0].date).format('YYYY'));
		const endYear = Number(
			moment(sorted[sorted.length - 1].date).format('YYYY'),
		);
		const newYears = [];
		for (let i = startYear; i < endYear + 1; i++) {
			newYears.push(String(i));
		}
		setYears(newYears);
	}, [allActivities]);

	useEffect(() => {
		if (years.length) {
			setYear(years[years.length - 1]);
		}
	}, [years]);

	useEffect(() => {
		if (year) {
			setDate((value) => moment(value).set('y', Number(year)).toDate());
		}
	}, [year]);

	useEffect(() => {
		setShoes(formatShoes(getShoes(activities)));
	}, [activities]);

	const props: Props = {
		shoes,
		year,
		years,
		startDate,
		endDate,
		activities,
		date,
		handleSelect,
	};

	return <PYearlyStats {...props} />;
};

export default YearlyStats;
