import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Day from '../../Day';
import Reset from '../../Reset';
import Login from '../../Login';
import Import from '../../Import';
import Settings from '../../Settings';
import Activity from '../../Activity';
import Dashboard from '../../Dashboard';
import Activities from '../../Activities';
import Statistics from '../../Statistics';
import Log from '../../Log';
import ProtectedRoute from './ProtectedRoute';

const Routes: React.FC = () => {
	return (
		<Switch>
			<Route exact path="/login" component={Login} />
			<Route exact path="/reset/:resetId" component={Reset} />
			<ProtectedRoute exact path="/dashboard" component={Dashboard} />
			<ProtectedRoute exact path="/log" component={Log} />
			<ProtectedRoute exact path="/activities" component={Activities} />
			<ProtectedRoute exact path="/statistics" component={Statistics} />
			<ProtectedRoute exact path="/import" component={Import} />
			<ProtectedRoute exact path="/settings" component={Settings} />
			<ProtectedRoute
				exact
				path="/activities/:activityId"
				component={Activity}
			/>
			<ProtectedRoute exact path="/log/:dayId" component={Day} />
			<Redirect to="/login" />
		</Switch>
	);
};

export default Routes;
