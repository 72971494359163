import React from 'react';
import { Button, DialogActions } from '@material-ui/core';

import { useStyles } from './styles';
import { useStoreActions } from '../../../../store';

export interface Props {
	getAverages: () => void;
	resetSelected: () => void;
	scrollToBottom: () => void;
}

const Actions: React.FC<Props> = ({
	resetSelected,
	scrollToBottom,
	getAverages,
}) => {
	const classes = useStyles();

	const setCompare = useStoreActions(
		(actions) => actions.activities.setCompare,
	);

	return (
		<DialogActions>
			<Button
				onClick={() => {
					getAverages();
					scrollToBottom();
				}}
				className={classes.primaryButton}
			>
				Compare
			</Button>
			<Button
				onClick={() => {
					resetSelected();
					scrollToBottom();
				}}
				className={classes.primaryButton}
			>
				Reset
			</Button>
			<Button
				onClick={() => setCompare()}
				className={classes.secondaryButton}
			>
				Close
			</Button>
		</DialogActions>
	);
};

export default Actions;
