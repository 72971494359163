import moment from 'moment';

import { Day } from '../../models/log';

export const getYear = (start: Day, end: Day): string => {
	const startYear = moment(start.date).year();
	const endYear = moment(end.date).year();
	if (startYear === endYear) {
		return String(startYear);
	}
	return `${startYear} - ${endYear}`;
};

export const getMonth = (start: Day, end: Day): string => {
	const startMonth = moment(start.date).format('MMM');
	const endMonth = moment(end.date).format('MMM');
	if (startMonth === endMonth) {
		return startMonth;
	}
	return `${startMonth} - ${endMonth}`;
};

export const getDays = (current: Date, week: number): Day[] => {
	const last = moment(current).endOf('isoWeek').add(week, 'weeks');
	const first = last.clone().subtract(27, 'days');
	const days: Day[] = [];
	for (let i = 0; i < 28; i++) {
		const date = first.clone().add(i, 'days');
		if (
			date.format('DD/MM/YYYY') === moment(current).format('DD/MM/YYYY')
		) {
			days.push({ date: date.toISOString(), current: true });
		} else {
			days.push({ date: date.toISOString(), current: false });
		}
	}
	return days;
};
