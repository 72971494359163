import React from 'react';
import {
	FormControl,
	IconButton,
	Menu,
	MenuItem,
	Paper,
	Select,
	Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { Controller, UseFormMethods } from 'react-hook-form';

import { useStyles } from './styles';
import { useStoreState } from '../../../../store';
import { advancedArrayOperators } from './utils';
import TagArray from './TagArray';
import ShoeArray from './ShoeArray';
import FootprintIcon from '../../../Icons/FootprintIcon';
import { FiltersData } from '../../interfaces';

export interface Props extends Pick<UseFormMethods, 'control' | 'watch'> {
	operator: keyof FiltersData;
	operators: string[];
	label: string;
	dataName: string;
	handleReset: () => void;
	handleOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
	handleClose: () => void;
	anchorEl: Element | null;
	open: boolean;
}

const ArrayFilter: React.FC<Props> = ({
	watch,
	control,
	operator,
	label,
	operators,
	dataName,
	handleReset,
	handleOpen,
	handleClose,
	anchorEl,
	open,
}) => {
	const classes = useStyles();

	const filtersData = useStoreState((state) => state.activities.filtersData);

	return (
		<Paper variant="outlined" className={classes.paper}>
			<Typography
				className={classes.filterItem}
				style={{ fontWeight: 'bold' }}
			>
				{label}
			</Typography>
			<FormControl className={classes.filterItem}>
				<Controller
					name={operator}
					control={control}
					defaultValue={filtersData[operator] || ''}
					as={
						<Select className={classes.select}>
							{operators.map((operator, index) => (
								<MenuItem value={operator} key={index}>
									{operator}
								</MenuItem>
							))}
						</Select>
					}
				/>
			</FormControl>
			{(advancedArrayOperators.includes(watch(operator)) ||
				(filtersData[operator] &&
					advancedArrayOperators.includes(
						filtersData[operator] as string,
					))) &&
				(dataName === 'tags' ? (
					<IconButton
						className={classes.iconButton}
						onClick={handleOpen}
					>
						<LocalOfferIcon className={classes.icon} />
					</IconButton>
				) : (
					<IconButton
						className={classes.svgButton}
						onClick={handleOpen}
					>
						<FootprintIcon className={classes.svg} />
					</IconButton>
				))}
			<Menu
				anchorEl={anchorEl}
				keepMounted
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				open={open}
				onClose={handleClose}
				MenuListProps={{ onMouseLeave: handleClose }}
				PaperProps={{
					className: classes.menu,
					style: {
						boxShadow: '0px 2px 10px 1px rgba(0, 0, 0, 0.05)',
					},
				}}
			>
				<div>
					{dataName === 'tags' && <TagArray />}
					{dataName === 'shoes' && <ShoeArray />}
				</div>
			</Menu>
			{(watch(operator) || filtersData[operator]) && (
				<IconButton className={classes.iconButton}>
					<DeleteIcon
						className={classes.icon}
						onClick={handleReset}
					/>
				</IconButton>
			)}
		</Paper>
	);
};

export default ArrayFilter;
