import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const darkTheme = createMuiTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 490,
			md: 990,
			lg: 1280,
			xl: 1920,
		},
	},
	palette: {
		common: {
			black: '#FAFAFB',
			white: '#434649',
		},
		primary: {
			light: '#9ECCFA',
			main: '#3D99F5',
			dark: '#08529B',
		},
		secondary: {
			light: '#1F1F1F',
			main: '#1F1F1F',
			dark: '#1F1F1F',
		},
		error: {
			light: '#F96C6C',
			main: '#F84F4F',
			dark: '#F84F4F',
		},
		text: {
			primary: '#FAFAFB',
			secondary: '#FAFAFB',
		},
		background: {
			paper: '#292929',
			default: '#1F1F1F',
		},
		action: {
			disabledBackground: '#5C5C5C',
			disabled: '#A6A5B1',
		},
		defaultBlack: '#434649',
		defaultWhite: '#FAFAFB',
	},
	typography: {
		fontFamily: '"Poppins", sans-serif',
		fontSize: 16,
		button: {
			fontFamily: '"Poppins", serif',
			fontWeight: 500,
			textTransform: 'none',
		},
	},
	props: {
		MuiButton: {
			variant: 'contained',
			disableElevation: true,
		},
		MuiButtonGroup: {
			variant: 'outlined',
			disableElevation: true,
		},
	},
});

darkTheme.overrides = {
	...darkTheme.overrides,
	MuiInput: {
		underline: {
			'&:hover:not($disabled):before': {
				borderBottomColor: darkTheme.palette.primary.main,
			},
			'&:not($disabled):before': {
				borderBottomColor: darkTheme.palette.common.black,
				borderWidth: 2,
			},
		},
	},
	MuiOutlinedInput: {
		root: {
			'&:hover $notchedOutline': {
				borderColor: darkTheme.palette.secondary.main,
			},
			'&$focused $notchedOutline': {
				borderColor: darkTheme.palette.secondary.main,
			},
			backgroundColor: darkTheme.palette.secondary.light,
			'&:hover': {
				backgroundColor: darkTheme.palette.secondary.main,
			},
			'&$focused': {
				backgroundColor: darkTheme.palette.secondary.main,
			},
		},
		notchedOutline: {
			borderWidth: 2,
			borderColor: darkTheme.palette.secondary.light,
		},
	},
	MuiInputLabel: {
		root: {
			color: darkTheme.palette.common.black,
		},
	},
	MuiPaper: {
		elevation1: {
			boxShadow: '0px 2px 10px 1px rgba(0, 0, 0, 0.05)',
		},
		elevation8: {
			boxShadow: '0px 2px 10px 1px rgba(0, 0, 0, 0.05)',
		},
	},
	MuiButtonGroup: {
		groupedOutlined: {
			border: 1,
			borderColor: darkTheme.palette.primary.light,
			'&:hover': {
				borderColor: darkTheme.palette.primary.main,
			},
		},
	},
	MuiTableSortLabel: {
		root: {
			color: darkTheme.palette.defaultBlack,
			'&:hover': {
				color: darkTheme.palette.defaultBlack,
				'&& $icon': {
					color: darkTheme.palette.defaultBlack,
				},
			},
			'&$active': {
				color: darkTheme.palette.defaultBlack,
				'&& $icon': {
					color: darkTheme.palette.defaultBlack,
				},
			},
		},
	},
};

export default darkTheme;
