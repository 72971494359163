import { action, Action, thunk, Thunk } from 'easy-peasy';

import { StoreModel } from '.';
import { axiosService } from '../services/axios';

export interface Food {
	id: string;
	name: string;
	amount: number;
	unit: string;
	calories: number;
	carbs: number;
	fat: number;
	protein: number;
}

export interface Entry {
	id: string;
	date: string;
	name: string;
	amount: number;
	mealNumber: number;
	unit: string;
	calories: number;
	carbs: number;
	fat: number;
	protein: number;
}

export interface Meal {
	id: string;
	name: string;
	calories: number;
	carbs: number;
	fat: number;
	protein: number;
}

export interface AddFood {
	name: string;
	amount: number;
	unit: string;
	calories?: number;
	carbs?: number;
	fat?: number;
	protein?: number;
}

export interface AddEntry {
	date: string;
	name: string;
	amount?: number;
	mealNumber: number;
}

export interface AddItem {
	food: string;
	amount: number;
}

export interface AddMeal {
	name: string;
	foods: AddItem[];
}

export interface FoodsModel {
	foods: Food[];
	setFoods: Action<FoodsModel, Food[]>;
	meals: Meal[];
	setMeals: Action<FoodsModel, Meal[]>;
	entries: Entry[];
	setEntries: Action<FoodsModel, Entry[]>;
	allEntries: Entry[];
	setAllEntries: Action<FoodsModel, Entry[]>;
	selected: Record<number, string[]>;
	setSelected: Action<FoodsModel, Record<number, string[]>>;
	reset: boolean;
	setReset: Action<FoodsModel, boolean>;
	addFood: Thunk<FoodsModel, AddFood, undefined, StoreModel, Promise<Food>>;
	getFoods: Thunk<
		FoodsModel,
		undefined,
		undefined,
		StoreModel,
		Promise<Food[]>
	>;
	addMeal: Thunk<FoodsModel, AddMeal, undefined, StoreModel, Promise<Meal>>;
	getMeals: Thunk<
		FoodsModel,
		undefined,
		undefined,
		StoreModel,
		Promise<Meal[]>
	>;
	addEntry: Thunk<
		FoodsModel,
		AddEntry,
		undefined,
		StoreModel,
		Promise<Entry>
	>;
	getEntries: Thunk<
		FoodsModel,
		string,
		undefined,
		StoreModel,
		Promise<Entry[]>
	>;
	getAllEntries: Thunk<
		FoodsModel,
		undefined,
		undefined,
		StoreModel,
		Promise<Entry[]>
	>;
	removeEntry: Thunk<
		FoodsModel,
		string,
		undefined,
		StoreModel,
		Promise<void>
	>;
}

const addFood = async (food: AddFood): Promise<Food> => {
	try {
		const response = await axiosService.post('/api/foods', food, {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const getFoods = async (): Promise<Food[]> => {
	try {
		const response = await axiosService.get('/api/foods', {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const addEntry = async (meal: AddEntry): Promise<Entry> => {
	try {
		const response = await axiosService.post('/api/foods/entries', meal, {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const getEntries = async (date?: string): Promise<Entry[]> => {
	try {
		const response = await axiosService.get('/api/foods/entries', {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
			params: { date },
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const removeEntry = async (entryId: string): Promise<void> => {
	try {
		await axiosService.delete(`/api/foods/entries/${entryId}`, {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
	} catch (err) {
		throw err;
	}
};

const addMeal = async (meal: AddMeal): Promise<Meal> => {
	try {
		const response = await axiosService.post('/api/foods/meals', meal, {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const getMeals = async (): Promise<Meal[]> => {
	try {
		const response = await axiosService.get('/api/foods/meals', {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const foods: FoodsModel = {
	foods: [],
	setFoods: action((state, value) => {
		state.foods = value;
	}),
	meals: [],
	setMeals: action((state, value) => {
		state.meals = value;
	}),
	entries: [],
	setEntries: action((state, value) => {
		state.entries = value;
	}),
	allEntries: [],
	setAllEntries: action((state, value) => {
		state.allEntries = value;
	}),
	selected: {},
	setSelected: action((state, value) => {
		state.selected = value;
	}),
	reset: false,
	setReset: action((state, value) => {
		state.reset = value;
	}),
	addFood: thunk(async (_actions, value) => {
		try {
			const food = await addFood(value);
			return food;
		} catch (err) {
			throw err;
		}
	}),
	getFoods: thunk(async (actions) => {
		try {
			const food = await getFoods();
			actions.setFoods(food);
			return food;
		} catch (err) {
			throw err;
		}
	}),
	addMeal: thunk(async (_actions, value) => {
		try {
			const meal = await addMeal(value);
			return meal;
		} catch (err) {
			throw err;
		}
	}),
	getMeals: thunk(async (actions) => {
		try {
			const meals = await getMeals();
			actions.setMeals(meals);
			return meals;
		} catch (err) {
			throw err;
		}
	}),
	addEntry: thunk(async (_actions, value) => {
		try {
			const entry = await addEntry(value);
			return entry;
		} catch (err) {
			throw err;
		}
	}),
	getEntries: thunk(async (actions, value) => {
		try {
			const entries = await getEntries(value);
			actions.setEntries(entries);
			return entries;
		} catch (err) {
			throw err;
		}
	}),
	getAllEntries: thunk(async (actions) => {
		try {
			const entries = await getEntries();
			actions.setAllEntries(entries);
			return entries;
		} catch (err) {
			throw err;
		}
	}),
	removeEntry: thunk(async (actions, value) => {
		try {
			await removeEntry(value);
		} catch (err) {
			throw err;
		}
	}),
};

export default foods;
