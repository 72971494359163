import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	iconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
		'&:disabled': {
			'& $icon': {
				color: theme.palette.action.disabled,
			},
		},
		height: '100%',
	},
	icon: {
		color: theme.palette.common.black,
	},
}));
