import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';

import PTags, { Props } from './Tags';
import { TagData } from './interfaces';
import { getSelected } from '../../Activities/utils';
import { useStoreState, useStoreActions } from '../../../store';

const Tags: React.FC = () => {
	const tags = useStoreState((state) => state.tags.tags);
	const removeId = useStoreState((state) => state.tags.removeId);
	const selectedTags = useStoreState((state) => state.settings.selectedTags);
	const setSelectedTags = useStoreActions(
		(actions) => actions.settings.setSelectedTags,
	);
	const addTag = useStoreActions((actions) => actions.tags.addTag);
	const getTags = useStoreActions((actions) => actions.tags.getTags);
	const removeTag = useStoreActions((actions) => actions.tags.removeTag);
	const setRemoveOne = useStoreActions(
		(actions) => actions.tags.setRemoveOne,
	);
	const setRemoveSelected = useStoreActions(
		(actions) => actions.tags.setRemoveSelected,
	);

	const { handleSubmit, register, reset, watch, errors } = useForm<TagData>();

	const [error, setError] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const isSelected = (id: string) => selectedTags.indexOf(id) !== -1;

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = tags.map((n) => n.id);
			setSelectedTags(newSelected);
			return;
		}
		setSelectedTags([]);
	};

	const handleCheckbox = (id: string) => {
		const newSelected = getSelected(id, selectedTags);
		setSelectedTags(newSelected);
	};

	const handleChangePage = (_event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const checkPage = (length: number) => {
		setPage(Math.ceil(length / rowsPerPage) - 1);
	};

	const handleRemoveOne = async () => {
		try {
			if (removeId) {
				await removeTag(removeId);
				const tags = await getTags();
				if (isSelected(removeId)) {
					handleCheckbox(removeId);
				}
				setRemoveOne();
				checkPage(tags.length);
			}
		} catch (err) {}
	};

	const handleRemoveSelected = async () => {
		try {
			for (const id of selectedTags) {
				try {
					await removeTag(id);
				} catch (err) {}
			}

			const tags = await getTags();
			setRemoveSelected();
			setSelectedTags([]);
			checkPage(tags.length);
		} catch (err) {}
	};

	const handleFormSubmit = async (data: TagData) => {
		setError(0);
		try {
			await addTag(data.name);
			const tags = await getTags();
			reset({ name: '' });
			checkPage(tags.length);
		} catch (err) {
			if (err && err.response) {
				setError((err.response as AxiosResponse).status);
			}
		}
	};

	const props: Props = {
		handleSubmit,
		register,
		watch,
		reset,
		errors,
		setError,
		isSelected,
		handleCheckbox,
		handleSelectAll,
		handleRemoveOne,
		handleChangePage,
		handleFormSubmit,
		handleRemoveSelected,
		handleChangeRowsPerPage,
		page,
		error,
		rowsPerPage,
	};

	return <PTags {...props} />;
};

export default Tags;
