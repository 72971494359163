import { createStore, createTypedHooks } from 'easy-peasy';
import model, { StoreModel } from './models';

const { useStoreState, useStoreActions, useStoreDispatch } = createTypedHooks<
	StoreModel
>();

export { useStoreState, useStoreActions, useStoreDispatch };

export let initialState = {};

const store = createStore(model, {
	devTools: process.env.NODE_ENV !== 'production' ? true : false,
});

initialState = store.getState();

export default store;
