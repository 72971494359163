import React, { useEffect, useState } from 'react';

import {
	getBmr,
	getCalories,
	getDistance,
	getExtraCalories,
} from '../../../Log/Day/utils';
import PInfo, { Props } from './Info';
import { useStoreState } from '../../../../store';

const Info: React.FC = () => {
	const activities = useStoreState((state) => state.day.activities);
	const health = useStoreState((state) => state.day.health);
	const date = useStoreState((state) => state.day.date);
	const user = useStoreState((state) => state.user.user);

	const [distance, setDistance] = useState(0);
	const [totalCalories, setTotalCalories] = useState(0);

	useEffect(() => {
		setDistance(getDistance(activities));
	}, [activities]);

	useEffect(() => {
		setTotalCalories(
			getBmr(health, user, date) +
				getCalories(activities) +
				getExtraCalories(health, date),
		);
	}, [activities, date, health, user]);

	const props: Props = {
		distance,
		totalCalories,
	};

	return <PInfo {...props} />;
};

export default Info;
