import React, { useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	MenuItem,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';
import { Controller, UseFormMethods } from 'react-hook-form';

import { useStoreState } from '../../../store';
import { ShoeData } from '../Header/interfaces';
import { useStyles } from './styles';
import { validateDistance } from './utils';

export interface Props
	extends Pick<
		UseFormMethods,
		| 'control'
		| 'handleSubmit'
		| 'reset'
		| 'getValues'
		| 'register'
		| 'watch'
		| 'errors'
	> {
	title?: string;
	openShoes: boolean;
	handleShoesClick: () => void;
	handleShoesConfirmClick: (data: ShoeData) => Promise<void>;
}

const Shoes: React.FC<Props> = ({
	openShoes,
	handleShoesClick,
	handleShoesConfirmClick,
	title,
	control,
	handleSubmit,
	reset,
	getValues,
	register,
	errors,
	watch,
}) => {
	const classes = useStyles();

	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const activity = useStoreState((state) => state.activity.activity);
	const shoes = useStoreState((state) => state.shoes.shoes);

	const [multiple, setMultiple] = useState(false);

	const multiplier = distanceUnit === 'km' ? 1000 : 1609.34;

	const handleCheckbox = () => {
		if (multiple) {
			reset({
				...getValues(),
				secondaryShoe: '',
				secondaryShoeDistance: '',
			});
		}
		setMultiple((value) => !value);
	};

	useEffect(() => {
		if (activity) {
			if (activity.shoes.length > 1) {
				setMultiple(true);
			} else {
				setMultiple(false);
			}
		}
	}, [activity, openShoes]);

	return (
		<Dialog open={openShoes} onClose={handleShoesClick}>
			<form>
				<DialogTitle>{title ? title : 'Edit Shoes'}</DialogTitle>
				<DialogContent>
					<div className={classes.shoe}>
						<Typography
							className={classes.shoeItem}
							style={{ fontWeight: 'bold' }}
						>
							Multiple shoes
						</Typography>
						<Checkbox
							color="primary"
							checked={multiple}
							onClick={handleCheckbox}
						/>
					</div>
					<div className={classes.shoe}>
						<Typography className={classes.shoeItem}>
							Primary Shoe
						</Typography>
						<FormControl className={classes.shoeItem}>
							<Controller
								name="primaryShoe"
								control={control}
								as={
									<Select style={{ minWidth: 100 }}>
										{!multiple && (
											<MenuItem value={''}>None</MenuItem>
										)}
										{shoes.map((shoe, index) => (
											<MenuItem
												value={shoe.name}
												key={index}
											>
												{shoe.name}
											</MenuItem>
										))}
									</Select>
								}
							/>
						</FormControl>
					</div>
					{multiple && (
						<div className={classes.shoe}>
							<Typography className={classes.shoeItem}>
								Secondary Shoe
							</Typography>
							<FormControl className={classes.shoeItem}>
								<Controller
									name="secondaryShoe"
									control={control}
									as={
										<Select style={{ minWidth: 100 }}>
											{shoes.map((shoe, index) => (
												<MenuItem
													value={shoe.name}
													key={index}
												>
													{shoe.name}
												</MenuItem>
											))}
										</Select>
									}
								/>
							</FormControl>
						</div>
					)}
					{multiple && watch('secondaryShoe') && (
						<div className={classes.shoe}>
							<Typography className={classes.shoeItem}>
								Secondary Shoe Distance
							</Typography>
							<TextField
								autoComplete="off"
								className={classes.shoeItem}
								name="secondaryShoeDistance"
								label={
									errors.secondaryShoeDistance &&
									'invalid distance'
								}
								inputRef={register({
									validate: (value: string) =>
										validateDistance(
											value,
											activity,
											multiplier,
										),
								})}
								size="small"
								variant="outlined"
								style={{ width: 112 }}
								InputProps={{ style: { height: 40 } }}
								error={
									errors.secondaryShoeDistance ? true : false
								}
							/>
							<Typography className={classes.shoeItem}>
								{distanceUnit}
							</Typography>
						</div>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleSubmit(handleShoesConfirmClick)}
						type="submit"
						className={classes.primaryButton}
					>
						Save
					</Button>
					<Button
						onClick={handleShoesClick}
						className={classes.secondaryButton}
					>
						Cancel
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default Shoes;
