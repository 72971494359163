import { Stream } from '../../models/activity';

export const trimDataset = (activityData: Stream[]): Stream[] => {
	const data = activityData.concat();
	data.sort((a, b) => a.pace - b.pace);

	const lq = data[Math.floor(data.length / 4)].pace;
	const uq = data[Math.ceil(data.length * (3 / 4))].pace;
	const iqr = uq - lq;

	const max = uq + iqr * 1.5;
	const min = lq - iqr * 1.5;

	const filtered = activityData.filter((x, index) => {
		if (x.pace > min && x.pace < max) {
			return x;
		}
		const neighbours = activityData.slice(
			index - 5 < 0 ? 0 : index - 5,
			index + 6,
		);
		const count = neighbours.filter((x) => x.pace > min && x.pace < max)
			.length;
		if (count === 0 && index > 4) {
			return x;
		}
	});

	return filtered;
};
