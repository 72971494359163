import makeStyles from '@material-ui/core/styles/makeStyles';
import globals from '../../utils/globals';

export const useStyles = makeStyles({
	alert: {
		margin: 'auto',
		maxWidth: globals.maxWidth,
		left: 0,
		right: 0,
	},
});
