import React from 'react';
import {
	BarChart,
	ResponsiveContainer,
	CartesianGrid,
	Tooltip as ChartTooltip,
	XAxis,
	YAxis,
	Bar,
	Cell,
} from 'recharts';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { Week } from './interfaces';
import Tooltip from '../../Tooltip';
import { useStyles } from './styles';
import TooltipContent from './TooltipContent';
import { useStoreState } from '../../../store';

export interface Props {
	focusBar: number | null;
	ticks: number[];
	data: Week[];
	handleTick: (value: number) => string;
	handleYTick: (value: number) => string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleMouse: (state: any) => void;
	handleClick: () => void;
	handlePrevious: () => void;
	handleNext: () => void;
}

const Graph: React.FC<Props> = ({
	focusBar,
	data,
	ticks,
	handleTick,
	handleYTick,
	handleMouse,
	handleClick,
	handlePrevious,
	handleNext,
}) => {
	const classes = useStyles();

	const date = useStoreState((state) => state.dashboard.date);
	const theme = useStoreState((state) => state.settings.theme);
	const selectedBar = useStoreState((state) => state.dashboard.selectedBar);
	const selectedDate = useStoreState((state) => state.dashboard.selectedDate);

	return (
		<div
			style={{
				width: '100%',
				height: 120,
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<Tooltip title="Previous year">
				<IconButton
					style={{ height: 40 }}
					className={classes.iconButton}
					onClick={handlePrevious}
				>
					<NavigateBeforeIcon
						className={classes.icon}
						fontSize="large"
					/>
				</IconButton>
			</Tooltip>
			<div style={{ height: '100%', width: `calc(100% - ${64 * 2}px)` }}>
				<ResponsiveContainer width="100%" height="100%">
					<BarChart
						margin={{ top: theme.spacing(2) }}
						onMouseMove={handleMouse}
						onMouseLeave={handleMouse}
						onClick={handleClick}
						data={data}
						style={{ cursor: 'pointer' }}
					>
						<CartesianGrid vertical={false} horizontal={false} />
						<XAxis
							dataKey="id"
							ticks={ticks}
							tickFormatter={handleTick}
						/>
						<YAxis tickFormatter={handleYTick} />
						<ChartTooltip
							cursor={false}
							content={<TooltipContent />}
							animationDuration={100}
						/>
						<Bar
							dataKey="distance"
							fill={theme.palette.primary.light}
							radius={[2, 2, 0, 0]}
						>
							{data.map((_entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={
										focusBar === index ||
										(selectedBar === index &&
											Number(selectedDate) ===
												Number(date))
											? theme.palette.primary.main
											: theme.palette.primary.light
									}
								/>
							))}
						</Bar>
					</BarChart>
				</ResponsiveContainer>
			</div>
			<Tooltip title="Next year">
				<span style={{ height: 40 }}>
					<IconButton
						className={classes.iconButton}
						onClick={handleNext}
						disabled={
							moment(date).format('YYYY') ===
							moment(new Date()).format('YYYY')
						}
					>
						<NavigateNextIcon
							className={classes.icon}
							fontSize="large"
						/>
					</IconButton>
				</span>
			</Tooltip>
		</div>
	);
};

export default Graph;
