import React from 'react';
import { useLocation } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { useStyles } from './styles';
import Navigation, { Props as HeaderProps } from './Navigation';
import Icons from './Icons';
import Logo from './Logo';

interface Props {
	loading: boolean;
}

const Header: React.FC<Props> = ({ loading }) => {
	const classes = useStyles();

	const { pathname } = useLocation();
	const login = pathname === '/login' || pathname.split('/')[1] === 'reset';

	const props: HeaderProps = {
		loading,
		login,
	};

	return (
		<div className={classes.root}>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<Logo {...props} />
					<Navigation {...props} />
					<Icons {...props} />
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default Header;
