import React from 'react';
import Button from '@material-ui/core/Button';
import { Controller, UseFormMethods } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

import { RegisterFormData } from './interfaces';
import { useStylesForm as useStyles } from './styles';
import { validateEmail } from './utils';
import { FormControl, MenuItem, Select } from '@material-ui/core';

export interface Props
	extends Pick<
		UseFormMethods<RegisterFormData>,
		'register' | 'watch' | 'errors' | 'reset' | 'control'
	> {
	handleChangeForm: (type: string) => void;
	handleRegister: (
		// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
		e?: React.BaseSyntheticEvent<object, any, any> | undefined,
	) => Promise<void>;
}

const RegisterForm: React.FC<Props> = ({
	handleChangeForm,
	handleRegister,
	register,
	control,
	errors,
	watch,
}) => {
	const classes = useStyles();

	return (
		<form>
			<div className={classes.form}>
				<TextField
					inputRef={register({
						required: {
							value: true,
							message: 'required',
						},
						minLength: {
							value: 1,
							message: 'minimum 1 character',
						},
						maxLength: {
							value: 50,
							message: 'maximum 50 characters',
						},
					})}
					label={errors.name ? errors.name.message : 'Nickname'}
					name="name"
					className={classes.formItem}
					error={errors.name ? true : false}
				/>
				<FormControl
					className={classes.formItem}
					style={{ paddingTop: 16 }}
				>
					<Controller
						name="sex"
						control={control}
						defaultValue={'Male'}
						as={
							<Select>
								<MenuItem value="Male">Male</MenuItem>
								<MenuItem value="Female">Female</MenuItem>
							</Select>
						}
					/>
				</FormControl>
				<TextField
					inputRef={register({
						required: {
							value: true,
							message: 'required',
						},
						validate: (value: string) => {
							if (
								Number(value) &&
								Number(value) > 0 &&
								Number(value) % Math.floor(Number(value)) === 0
							) {
								return true;
							}
							return false;
						},
					})}
					label={
						errors.age
							? errors.age.message
								? errors.age.message
								: 'invalid age'
							: 'Age'
					}
					name="age"
					className={classes.formItem}
					error={errors.age ? true : false}
				/>
				<TextField
					inputRef={register({
						required: {
							value: true,
							message: 'required',
						},
						validate: (value: string) => {
							if (Number(value) && Number(value) > 0) {
								return true;
							}
							return false;
						},
					})}
					label={
						errors.weight
							? errors.weight.message
								? errors.weight.message
								: 'invalid weight'
							: 'Weight (kg)'
					}
					name="weight"
					className={classes.formItem}
					error={errors.weight ? true : false}
				/>
				<TextField
					inputRef={register({
						required: {
							value: true,
							message: 'required',
						},
						validate: (value: string) => {
							if (Number(value) && Number(value) > 0) {
								return true;
							}
							return false;
						},
					})}
					label={
						errors.height
							? errors.height.message
								? errors.height.message
								: 'invalid height'
							: 'Height (cm)'
					}
					name="height"
					className={classes.formItem}
					error={errors.height ? true : false}
				/>
				<TextField
					inputRef={register({
						required: true,
						validate: (value: string) => validateEmail(value),
					})}
					label={
						errors.email ? 'must be a valid email address' : 'Email'
					}
					name="email"
					className={classes.formItem}
					error={errors.email ? true : false}
				/>
				<TextField
					inputRef={register({ required: true, minLength: 8 })}
					label={
						errors.password
							? 'must be at least 8 characters'
							: 'Password'
					}
					name="password"
					type="password"
					className={classes.formItem}
					error={errors.password ? true : false}
				/>
				<TextField
					inputRef={register({
						validate: (value: string) => {
							return value === watch('password');
						},
					})}
					label={
						errors.confirmPassword
							? 'passwords must match'
							: 'Confirm password'
					}
					name="confirmPassword"
					type="password"
					className={classes.formItem}
					error={errors.confirmPassword ? true : false}
				/>
				<Button
					type="submit"
					onClick={handleRegister}
					className={`${classes.formItem} ${classes.primaryButton}`}
				>
					Register
				</Button>
				<Button
					onClick={() => handleChangeForm('signIn')}
					className={`${classes.formItem} ${classes.secondaryButton}`}
				>
					Sign In
				</Button>
			</div>
		</form>
	);
};

export default RegisterForm;
