import React from 'react';
import {
	FormControl,
	IconButton,
	MenuItem,
	Paper,
	Select,
	TextField,
	Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Controller, UseFormMethods } from 'react-hook-form';

import { useStyles } from './styles';
import { advancedOperators, validateNumber, validateTime } from './utils';
import { FilterData } from '../../interfaces';
import { useStoreState } from '../../../../store';

export interface Props
	extends FilterData,
		Pick<UseFormMethods, 'control' | 'register' | 'watch' | 'errors'> {
	handleReset: () => void;
}

const Filter: React.FC<Props> = ({
	control,
	register,
	errors,
	watch,
	type,
	operator,
	label,
	unit,
	secondary,
	operators,
	units,
	dataType,
	handleReset,
}) => {
	const classes = useStyles();

	const filtersData = useStoreState((state) => state.activities.filtersData);

	return (
		<Paper variant="outlined" className={classes.paper}>
			<Typography
				className={classes.filterItem}
				style={{ fontWeight: 'bold' }}
			>
				{label}
			</Typography>
			<FormControl className={classes.filterItem}>
				<Controller
					name={operator}
					control={control}
					defaultValue={filtersData[operator] || ''}
					as={
						<Select className={classes.select}>
							{operators.map((operator, index) => (
								<MenuItem value={operator} key={index}>
									{operator}
								</MenuItem>
							))}
						</Select>
					}
				/>
			</FormControl>
			{((watch(operator) &&
				!advancedOperators.includes(watch(operator))) ||
				(filtersData[operator] &&
					!advancedOperators.includes(
						filtersData[operator] as string,
					))) &&
				type && (
					<TextField
						className={classes.filterItem}
						autoComplete="off"
						name={type}
						inputRef={register(
							dataType === 'number'
								? validateNumber
								: dataType === 'time'
								? validateTime
								: {},
						)}
						error={!!errors[type]}
						style={{
							width: 100,
							height: '100%',
						}}
						defaultValue={filtersData[type]}
						size="small"
					/>
				)}
			{(watch(operator) === 'between' ||
				filtersData[operator] === 'between') && (
				<Typography className={classes.filterItem}>{'and'}</Typography>
			)}
			{(watch(operator) === 'between' ||
				filtersData[operator] === 'between') &&
				secondary && (
					<TextField
						className={classes.filterItem}
						autoComplete="off"
						name={secondary}
						inputRef={register(
							dataType === 'number'
								? validateNumber
								: dataType === 'time'
								? validateTime
								: {},
						)}
						error={!!errors[secondary]}
						style={{
							width: 100,
							height: '100%',
						}}
						defaultValue={filtersData[secondary]}
						InputProps={{ style: { height: 30 } }}
						size="small"
					/>
				)}
			{((type && watch(type)) || (type && filtersData[type])) && unit && (
				<FormControl className={classes.filterItem}>
					<Controller
						name={unit}
						control={control}
						defaultValue={filtersData[unit] || ''}
						as={
							<Select className={classes.select}>
								{units.map((unit, index) => (
									<MenuItem value={unit} key={index}>
										{unit}
									</MenuItem>
								))}
							</Select>
						}
					/>
				</FormControl>
			)}
			{((watch(operator) &&
				!advancedOperators.includes(watch(operator))) ||
				(filtersData[operator] &&
					!advancedOperators.includes(
						filtersData[operator] as string,
					))) &&
				!unit &&
				units.length === 1 && (
					<Typography className={classes.filterItem}>
						{units[0]}
					</Typography>
				)}
			{(watch(operator) || filtersData[operator]) && (
				<IconButton className={classes.iconButton}>
					<DeleteIcon
						className={classes.icon}
						onClick={handleReset}
					/>
				</IconButton>
			)}
		</Paper>
	);
};

export default Filter;
