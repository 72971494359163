import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		height: '100%',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	paper: {
		marginLeft: theme.spacing(),
		marginRight: theme.spacing(),
		marginBottom: theme.spacing(),
	},
	tagPaper: {
		marginRight: theme.spacing(),
		marginBottom: theme.spacing(),
	},
}));
