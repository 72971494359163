import React from 'react';
import { CanvasOverlay, CanvasRedrawOptions } from 'react-map-gl';

import { PositionStream } from '../../../../models/activity';
import { useStoreState } from '../../../../store';

interface Props {
	points: PositionStream[];
	color?: string;
	lineWidth?: number;
	highlight?: boolean;
	fixedHighlight?: boolean;
}

const Overlay: React.FC<Props> = (props) => {
	const hoverHighlight = useStoreState(
		(state) => state.activity.hoverHighlight,
	);
	const clickHighlight = useStoreState(
		(state) => state.activity.clickHighlight,
	);
	const theme = useStoreState((state) => state.settings.theme);

	const redraw = ({ width, height, ctx, project }: CanvasRedrawOptions) => {
		const {
			points,
			color = theme.palette.primary.dark,
			lineWidth = 4,
			highlight = false,
			fixedHighlight = false,
		} = props;
		ctx.clearRect(0, 0, width, height);
		ctx.globalCompositeOperation = 'lighter';

		if (points) {
			ctx.lineWidth = highlight ? 5 : lineWidth;
			ctx.strokeStyle = highlight ? theme.palette.error.main : color;
			ctx.beginPath();
			points.forEach((point) => {
				if (highlight) {
					if (fixedHighlight) {
						if (
							clickHighlight &&
							point.time >= clickHighlight.min &&
							point.time <= clickHighlight.max
						) {
							const pixel = project([
								point.longitude,
								point.latitude,
							]);
							ctx.lineTo(pixel[0], pixel[1]);
						}
					} else {
						if (
							hoverHighlight &&
							point.time >= hoverHighlight.min &&
							point.time <= hoverHighlight.max
						) {
							const pixel = project([
								point.longitude,
								point.latitude,
							]);
							ctx.lineTo(pixel[0], pixel[1]);
						}
					}
				} else {
					const pixel = project([point.longitude, point.latitude]);
					ctx.lineTo(pixel[0], pixel[1]);
				}
			});
			ctx.stroke();
		}
	};

	return <CanvasOverlay redraw={redraw} />;
};

export default Overlay;
