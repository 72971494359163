import React from 'react';

import PTable, { Props } from './Table';
import { Upload } from '../../../models/uploads';
import { useStoreActions, useStoreState } from '../../../store';
import { fileToBase64 } from '../utils';

const Table: React.FC = () => {
	const files = useStoreState((state) => state.uploads.files);
	const setFiles = useStoreActions((actions) => actions.uploads.setFiles);
	const selected = useStoreState((state) => state.uploads.selected);
	const setSelected = useStoreActions(
		(actions) => actions.uploads.setSelected,
	);
	const uploads = useStoreState((state) => state.uploads.uploads);
	const getUploads = useStoreActions((actions) => actions.uploads.getUploads);
	const removeUpload = useStoreActions(
		(actions) => actions.uploads.removeUpload,
	);
	const updateUpload = useStoreActions(
		(actions) => actions.uploads.updateUpload,
	);
	const addActivity = useStoreActions(
		(actions) => actions.activity.addActivity,
	);
	const addNotification = useStoreActions(
		(actions) => actions.notifications.addNotification,
	);
	const getNotifications = useStoreActions(
		(actions) => actions.notifications.getNotifications,
	);
	const getActivities = useStoreActions(
		(actions) => actions.activities.getActivities,
	);
	const setLoading = useStoreActions((actions) => actions.uploads.setLoading);

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	const checkUploads = async (uploads: Upload[]) => {
		try {
			await Promise.all(
				uploads.map(
					async (upload) =>
						upload.error &&
						upload.errorMessage === 'duplicate upload' &&
						(await updateUpload(upload.id)),
				),
			);
			await getUploads();
		} catch (err) {}
	};

	const handleImport = async (id: string) => {
		try {
			setLoading(true);
			try {
				const upload = uploads.filter((upload) => upload.id === id)[0];
				if (!upload) {
				} else if (!upload.error && !upload.success) {
					const fileToImport = files.filter(
						(file) => file.id === id,
					)[0];
					if (fileToImport) {
						const base64 = await fileToBase64(fileToImport.file);
						const activity = await addActivity({
							uploadId: id,
							base64,
						});
						await addNotification({
							message: `New activity: ${fileToImport.file.name}`,
							url: `/activities/${activity.id}`,
						});
					} else {
						await removeUpload(id);
					}
				}
			} catch (err) {}
			setFiles(files.filter((file) => id !== file.id));
			if (isSelected(id)) {
				handleCheckbox(id);
			}
			await getUploads();
			setLoading(false);
			await getNotifications();
			await getActivities();
		} catch (err) {}
	};

	const handleTableDelete = async (id: string) => {
		try {
			const deletedFile = files.filter((file) => file.id === id)[0];
			try {
				await removeUpload(id);
			} catch (err) {}
			const uploads = await getUploads();
			setSelected(selected.filter((row) => row !== id));
			if (deletedFile) {
				setFiles(files.filter((file) => file.id !== deletedFile.id));
			}
			await checkUploads(uploads);
		} catch (err) {}
	};

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = uploads.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleCheckbox = (id: string) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1),
			);
		}

		setSelected(newSelected);
	};

	const props: Props = {
		uploads,
		selected,
		isSelected,
		handleCheckbox,
		handleSelectAll,
		handleTableDelete,
		handleImport,
	};

	return <PTable {...props} />;
};

export default Table;
