import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(),
	},
	iconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
	},
	icon: {
		color: theme.palette.common.black,
	},
	icons: {
		display: 'flex',
		alignItems: 'center',
	},
	name: {
		display: 'flex',
		flexDirection: 'row',
	},
}));
