import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		height: '100%',
	},
	iconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
		padding: 0,
	},
	icon: {
		color: theme.palette.common.black,
	},
}));
