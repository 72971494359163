import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flex: 1,
		flexDirection: 'row',
		justifyContent: 'flex-end',
		margin: theme.spacing(),
	},
	iconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
	},
	icon: {
		color: theme.palette.common.black,
	},
	svg: {
		fill: theme.palette.common.black,
	},
	svgButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $svg': {
				fill: theme.palette.primary.main,
			},
		},
	},
	tooltip: {
		margin: 0,
	},
	successIconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $successIcon': {
				color: theme.palette.success.main,
			},
		},
	},
	errorIconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $errorIcon': {
				color: theme.palette.error.main,
			},
		},
	},
	successIcon: {
		color: theme.palette.success.light,
	},
	errorIcon: {
		color: theme.palette.error.light,
	},
}));
