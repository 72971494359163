import { action, Action, thunk, Thunk } from 'easy-peasy';

import { StoreModel } from '.';
import { axiosService } from '../services/axios';

export interface Shoe {
	id: string;
	name: string;
	distance: number;
}

export interface AddShoe {
	name: string;
	distance?: number;
}

export interface UpdateShoe {
	distance: number;
}

export interface ShoesModel {
	shoes: Shoe[];
	setShoes: Action<ShoesModel, Shoe[]>;
	removeId: string | undefined;
	setRemoveId: Action<ShoesModel, string | undefined>;
	removeOne: boolean;
	setRemoveOne: Action<ShoesModel>;
	removeSelected: boolean;
	setRemoveSelected: Action<ShoesModel>;
	addShoe: Thunk<ShoesModel, AddShoe, undefined, StoreModel, Promise<Shoe>>;
	getShoes: Thunk<
		ShoesModel,
		undefined,
		undefined,
		StoreModel,
		Promise<Shoe[]>
	>;
	updateShoe: Thunk<
		ShoesModel,
		{ shoeId: string; params: UpdateShoe },
		undefined,
		StoreModel,
		Promise<Shoe>
	>;
	removeShoe: Thunk<ShoesModel, string, undefined, StoreModel, Promise<void>>;
}

const addShoe = async (params: AddShoe): Promise<Shoe> => {
	try {
		const response = await axiosService.post(
			'/api/shoes',
			{ ...params },
			{
				headers: {
					authorization: localStorage.getItem('JWT'),
				},
			},
		);
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const getShoes = async (): Promise<Shoe[]> => {
	try {
		const response = await axiosService.get('/api/shoes', {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const updateShoe = async (
	shoeId: string,
	params: UpdateShoe,
): Promise<Shoe> => {
	try {
		const response = await axiosService.patch(
			`/api/shoes/${shoeId}/distance`,
			{ ...params },
			{
				headers: {
					authorization: localStorage.getItem('JWT'),
				},
			},
		);
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const removeShoe = async (shoeId: string): Promise<void> => {
	try {
		await axiosService.delete(`/api/shoes/${shoeId}`, {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
	} catch (err) {
		throw err;
	}
};

const shoes: ShoesModel = {
	shoes: [],
	setShoes: action((state, value) => {
		state.shoes = value;
	}),
	removeOne: false,
	setRemoveOne: action((state) => {
		state.removeOne = !state.removeOne;
	}),
	removeSelected: false,
	setRemoveSelected: action((state) => {
		state.removeSelected = !state.removeSelected;
	}),
	removeId: undefined,
	setRemoveId: action((state, value) => {
		state.removeId = value;
	}),
	addShoe: thunk(async (_actions, value) => {
		try {
			const shoe = await addShoe(value);
			return shoe;
		} catch (err) {
			throw err;
		}
	}),
	getShoes: thunk(async (actions) => {
		try {
			const shoes = await getShoes();
			actions.setShoes(shoes);
			return shoes;
		} catch (err) {
			throw err;
		}
	}),
	updateShoe: thunk(async (_actions, value) => {
		try {
			const shoe = await updateShoe(value.shoeId, value.params);
			return shoe;
		} catch (err) {
			throw err;
		}
	}),
	removeShoe: thunk(async (_actions, value) => {
		try {
			await removeShoe(value);
		} catch (err) {
			throw err;
		}
	}),
};

export default shoes;
