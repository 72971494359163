import { ActivityShoe } from '../../../models/activity';
import { ShoeData } from './interfaces';

export const getShoes = (
	data: ShoeData,
	distance: number,
	multiplier: number,
): ActivityShoe[] => {
	const shoes: ActivityShoe[] = [];
	if (data.primaryShoe && !data.secondaryShoe) {
		shoes.push({
			name: data.primaryShoe,
			distance,
		});
	} else if (
		data.primaryShoe &&
		data.secondaryShoe &&
		data.secondaryShoeDistance
	) {
		if (data.primaryShoe === data.secondaryShoe) {
			shoes.push({
				name: data.primaryShoe,
				distance,
			});
		} else {
			shoes.push(
				{
					name: data.primaryShoe,
					distance:
						distance -
						Number(data.secondaryShoeDistance) * multiplier,
				},
				{
					name: data.secondaryShoe,
					distance: Number(data.secondaryShoeDistance) * multiplier,
				},
			);
		}
	} else if (
		!data.primaryShoe &&
		data.secondaryShoe &&
		data.secondaryShoeDistance
	) {
		shoes.push({
			name: data.secondaryShoe,
			distance,
		});
	} else if (
		data.primaryShoe &&
		data.secondaryShoe &&
		!data.secondaryShoeDistance
	) {
		shoes.push({ name: data.primaryShoe, distance });
	}
	return shoes;
};

export const resetShoes = (
	shoes: ActivityShoe[],
	multiplier: number,
): {
	primaryShoe?: string;
	secondaryShoe?: string;
	secondaryShoeDistance?: string;
} => {
	return {
		primaryShoe: shoes.length ? shoes[0].name : '',
		secondaryShoe: shoes.length > 1 ? shoes[1].name : '',
		secondaryShoeDistance:
			shoes.length > 1 ? String(shoes[1].distance / multiplier) : '',
	};
};
