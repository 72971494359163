import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	activities: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		width: '100%',
		overflow: 'auto',
		paddingTop: 0,
		paddingBottom: 0,
	},
	activity: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: 240,
		overflow: 'hidden',
	},
	activityContainer: {
		width: 240,
		margin: theme.spacing(),
	},
}));
