import { Position } from '../../../models/activity';
import { WebMercatorViewport } from 'react-map-gl';

interface ViewportDetails extends Position {
	zoom: number;
}

export const centreViewport = (
	coordinates: Position[],
	width: number,
	height: number,
): ViewportDetails => {
	const latitudes = coordinates.map((coordinate) => {
		return coordinate.latitude as number;
	});
	const latitude = (Math.max(...latitudes) + Math.min(...latitudes)) / 2;
	const longitudes = coordinates.map((coordinate) => {
		return coordinate.longitude as number;
	});
	const longitude = (Math.max(...longitudes) + Math.min(...longitudes)) / 2;

	const maxLatitude = Math.max(...latitudes);
	const minLatitude = Math.min(...latitudes);
	const maxLongitude = Math.max(...longitudes);
	const minLongitude = Math.min(...longitudes);

	const viewport = new WebMercatorViewport({
		width,
		height,
	}).fitBounds([
		[minLongitude, minLatitude],
		[maxLongitude, maxLatitude],
	]);
	const { zoom } = viewport;

	return { latitude, longitude, zoom: zoom - 0.1 };
};
