import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { AxiosResponse } from 'axios';

import Map from './Map';
import Graph from './Graph';
import Header from './Header';
import Loading from '../Loading';
import LapGraph from './LapGraph';
import { useStyles } from './styles';
import { trimDataset } from './utils';
import GraphOptions from './GraphOptions';
import { useStoreActions, useStoreState } from '../../store';
import useWindowDimensions from '../../utils/windowSize';

const Activity: React.FC = () => {
	const classes = useStyles();
	const { height } = useWindowDimensions();

	const location = useLocation();
	const id = location.pathname.split('/').pop() as string;

	const theme = useStoreState((state) => state.settings.theme);
	const tags = useStoreState((state) => state.tags.tags);
	const shoes = useStoreState((state) => state.shoes.shoes);
	const getTags = useStoreActions((actions) => actions.tags.getTags);
	const getShoes = useStoreActions((actions) => actions.shoes.getShoes);
	const activity = useStoreState((state) => state.activity.activity);
	const getActivity = useStoreActions(
		(actions) => actions.activity.getActivity,
	);
	const setActivity = useStoreActions(
		(actions) => actions.activity.setActivity,
	);
	const setGraphHighlight = useStoreActions(
		(actions) => actions.activity.setGraphHighlight,
	);
	const setMapHighlight = useStoreActions(
		(actions) => actions.activity.setMapHighlight,
	);
	const setRedirectError = useStoreActions(
		(actions) => actions.activities.setRedirectError,
	);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(0);
	const [map, setMap] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setGraphHighlight(undefined);
				setMapHighlight(undefined);
				if (!tags.length) {
					await getTags();
				}
				if (!shoes.length) {
					await getShoes();
				}
				if (!activity || (activity && activity.id !== id)) {
					const newActivity = await getActivity(id);
					const trimmedData = trimDataset(newActivity.streams);
					newActivity.streams = trimmedData;

					if (newActivity.hasGps) {
						setMap(true);
					}

					setActivity(newActivity);
				} else {
					if (activity && activity.hasGps) {
						setMap(true);
					}
				}
			} catch (err) {
				if (err && err.response) {
					setError((err.response as AxiosResponse).status);
					setRedirectError(true);
				}
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, [
		getActivity,
		setActivity,
		id,
		setGraphHighlight,
		setMapHighlight,
		getTags,
		getShoes,
		setRedirectError,
	]);

	return (
		<div className={classes.root}>
			{error ? (
				<Redirect to={'/activities'} />
			) : loading ? (
				<Loading />
			) : (
				<div
					className={classes.container}
					style={{
						maxHeight: height - 60 - theme.spacing(3),
						overflow: 'auto',
					}}
				>
					<Paper className={classes.paper}>
						<Header />
					</Paper>
					<Paper className={classes.bottomPaper}>
						<div className={classes.mapGraph}>
							{map && <Map />}
							<LapGraph />
						</div>
						<Graph />
						<GraphOptions />
					</Paper>
				</div>
			)}
		</div>
	);
};

export default Activity;
