import { Order } from '../../../models/activities';
import { HeadCell } from './interfaces';

export const descendingComparator = <T>(
	a: T,
	b: T,
	orderBy: keyof T,
): -1 | 0 | 1 => {
	if (!b[orderBy]) {
		return 1;
	}
	if (!a[orderBy]) {
		return 1;
	}
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getComparator = <Key extends keyof any>(
	order: Order,
	orderBy: Key,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
): ((a: { [key in Key]?: any }, b: { [key in Key]?: any }) => number) => {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
};

export const stableSort = <T>(
	array: T[],
	comparator: (a: T, b: T) => number,
): T[] => {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
};

export const headCells: HeadCell[] = [
	{
		id: 'date',
		label: 'Date',
		width: '10%',
	},
	{
		id: 'name',
		label: 'Name',
		width: '20%',
	},
	{
		id: 'distance',
		label: 'Distance',
		width: '12%',
	},
	{
		id: 'duration',
		label: 'Time',
		width: '12%',
	},
	{
		id: 'avgPace',
		label: 'Pace',
		width: '12%',
	},
	{
		id: 'avgHeartRate',
		label: 'Heart Rate',
		width: '12%',
	},
	{
		id: 'ascent',
		label: 'Elevation',
		width: '12%',
	},
];
