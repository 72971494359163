import React from 'react';
import {
	Button,
	Dialog as MuiDialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core';
import { useStyles } from './styles';

export interface Props {
	open: boolean;
	handleDialogClick: () => void;
	handleDialogConfirmClick: () => Promise<void> | void;
	title: string;
	content: string;
	confirm: string;
}

const Dialog: React.FC<Props> = ({
	open: openRemove,
	handleDialogClick,
	handleDialogConfirmClick,
	title,
	content,
	confirm,
}) => {
	const classes = useStyles();

	return (
		<MuiDialog open={openRemove} onClose={handleDialogClick}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleDialogConfirmClick}
					className={classes.primaryButton}
				>
					{confirm}
				</Button>
				<Button
					onClick={handleDialogClick}
					className={classes.secondaryButton}
				>
					Cancel
				</Button>
			</DialogActions>
		</MuiDialog>
	);
};

export default Dialog;
