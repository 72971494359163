import React from 'react';
import { Checkbox, MenuItem, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { getSelected } from '../../utils';
import { useStoreState, useStoreActions } from '../../../../store';

const ShoeArray: React.FC = () => {
	const classes = useStyles();

	const shoes = useStoreState((state) => state.shoes.shoes);
	const selectedShoes = useStoreState(
		(state) => state.activities.selectedShoes,
	);
	const setSelectedShoes = useStoreActions(
		(actions) => actions.activities.setSelectedShoes,
	);

	const isSelected = (name: string) => selectedShoes.indexOf(name) !== -1;

	const handleCheckbox = (name: string) => {
		const newSelected = getSelected(name, selectedShoes);
		setSelectedShoes(newSelected);
	};

	return (
		<div>
			{shoes.map((shoe) => {
				const { id, name } = shoe;
				const isItemSelected = isSelected(name);

				return (
					<MenuItem key={id} className={classes.menuItem}>
						<Typography>{name}</Typography>
						<Checkbox
							color="primary"
							checked={isItemSelected}
							onClick={() => handleCheckbox(name)}
						/>
					</MenuItem>
				);
			})}
		</div>
	);
};

export default ShoeArray;
