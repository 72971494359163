import React, { useEffect, useState } from 'react';

import Loading from '../../Loading';
import { getRange } from './utils';
import { useStyles } from './styles';
import PLapGraph, { Props } from './LapGraph';
import { Lap } from '../../../models/activity';
import { useStoreActions, useStoreState } from '../../../store';
import { useMediaQuery } from '@material-ui/core';

const LapGraph: React.FC = () => {
	const classes = useStyles();

	const theme = useStoreState((state) => state.settings.theme);
	const activity = useStoreState((state) => state.activity.activity);
	const hoverHighlight = useStoreState(
		(state) => state.activity.hoverHighlight,
	);

	const setMapHighlight = useStoreActions(
		(actions) => actions.activity.setMapHighlight,
	);
	const setGraphHighlight = useStoreActions(
		(actions) => actions.activity.setGraphHighlight,
	);

	const [loading, setLoading] = useState(true);
	const [graphData, setGraphData] = useState<Lap[]>([]);
	const [focusBar, setFocusBar] = useState<number | null>(null);
	const [paceRange, setPaceRange] = useState<number[]>([]);
	const [clickedBar, setClickedBar] = useState<number | null>(null);

	const isTablet = useMediaQuery(theme.breakpoints.down('sm'));

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleMouse = (state: any) => {
		if (state.isTooltipActive) {
			if (activity && focusBar !== state.activeTooltipIndex) {
				const lap = activity.laps[state.activeTooltipIndex];
				let start = lap.start;
				const end = lap.end;

				setMapHighlight({
					min: start,
					max: end,
				});
				setFocusBar(state.activeTooltipIndex);

				const {
					streams: [first],
				} = activity;
				if (start < first.time) {
					start = first.time;

					setMapHighlight({
						min: start,
						max: end,
					});
				}
			}
		} else {
			setMapHighlight(undefined);
			setFocusBar(null);
		}
	};

	const handleClick = () => {
		if (focusBar === clickedBar) {
			setClickedBar(null);
			setGraphHighlight(undefined);
		} else {
			setClickedBar(focusBar);
			setGraphHighlight(hoverHighlight);
		}
	};

	useEffect(() => {
		if (activity) {
			const laps = activity.laps;
			const formattedLaps = laps.map((lap) => {
				return {
					...lap,
					avgPace: 1 / lap.avgPace,
				};
			});
			if (formattedLaps[formattedLaps.length - 1].duration < 1) {
				formattedLaps.pop();
			}
			setGraphData(formattedLaps);
			setPaceRange(getRange(formattedLaps));
			setLoading(false);
		}
	}, [activity]);

	const props: Props = {
		data: graphData,
		handleMouse,
		handleClick,
		focusBar,
		clickedBar,
		paceRange,
	};

	return (
		<div
			className={classes.root}
			style={{
				width: isTablet
					? `calc(100% - ${theme.spacing(2)}px)`
					: `calc(50% - ${theme.spacing(1.5)}px)`,
			}}
		>
			{loading ? <Loading /> : <PLapGraph {...props} />}
		</div>
	);
};

export default LapGraph;
