import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogActions,
	Button,
	DialogContent,
	Typography,
	TextField,
	Slider,
} from '@material-ui/core';
import { Controller, UseFormMethods } from 'react-hook-form';

import { HealthData } from '../interfaces';
import { useStyles } from './styles';
import { validateNumber, validateZeroNumber } from './utils';
import { useStoreState } from '../../../../store';
import Alert from '@material-ui/lab/Alert';
import { getExtraCalories } from '../../../Log/Day/utils';

export interface Props
	extends Pick<
		UseFormMethods<HealthData>,
		| 'control'
		| 'handleSubmit'
		| 'reset'
		| 'getValues'
		| 'register'
		| 'watch'
		| 'errors'
	> {
	openHealth: boolean;
	handleHealthClick: () => void;
	handleHealthConfirmClick: (data: HealthData) => Promise<void>;
}

const Health: React.FC<Props> = ({
	openHealth,
	handleHealthClick,
	handleHealthConfirmClick,
	errors,
	control,
	register,
	handleSubmit,
}) => {
	const classes = useStyles();

	const date = useStoreState((state) => state.day.date);
	const health = useStoreState((state) => state.day.health);
	const macroError = useStoreState((state) => state.day.macroError);

	return (
		<Dialog open={openHealth} onClose={handleHealthClick}>
			<form>
				<DialogTitle>Edit Health</DialogTitle>
				<DialogContent>
					<div className={classes.health}>
						<Typography
							className={classes.healthItem}
							style={{ fontWeight: 'bold' }}
						>
							Height (cm)
						</Typography>
						<TextField
							autoComplete="off"
							className={classes.healthItem}
							name="height"
							inputRef={register(validateNumber)}
							size="small"
							variant="outlined"
							style={{ width: 112 }}
							InputProps={{ style: { height: 40 } }}
							defaultValue={health ? health.height : ''}
							error={errors.height ? true : false}
						/>
					</div>
					<div className={classes.health}>
						<Typography
							className={classes.healthItem}
							style={{ fontWeight: 'bold' }}
						>
							Weight (kg)
						</Typography>
						<TextField
							autoComplete="off"
							className={classes.healthItem}
							name="weight"
							inputRef={register(validateNumber)}
							size="small"
							variant="outlined"
							style={{ width: 112 }}
							defaultValue={health ? health.weight : ''}
							InputProps={{ style: { height: 40 } }}
							error={errors.weight ? true : false}
						/>
					</div>
					<div className={classes.health}>
						<Typography
							className={classes.healthItem}
							style={{ fontWeight: 'bold' }}
						>
							Extra Calories (C)
						</Typography>
						<TextField
							autoComplete="off"
							className={classes.healthItem}
							name="calories"
							inputRef={register(validateZeroNumber)}
							size="small"
							variant="outlined"
							style={{ width: 112 }}
							defaultValue={
								health && getExtraCalories(health, date)
									? health.calories
									: ''
							}
							InputProps={{ style: { height: 40 } }}
							error={errors.calories ? true : false}
						/>
					</div>
					<div className={classes.health} style={{ paddingTop: 32 }}>
						<Typography
							className={classes.healthItem}
							style={{ fontWeight: 'bold', minWidth: 110 }}
						>
							Carbs (%)
						</Typography>
						<Controller
							name="carbs"
							control={control}
							onChange={([, value]: [number, number]) => value}
							defaultValue={health ? health.carbs : 0}
							render={(props) => (
								<Slider
									{...props}
									onChange={(_, value) => {
										props.onChange(value);
									}}
									valueLabelDisplay="on"
									max={100}
									step={5}
								/>
							)}
						/>
					</div>
					<div className={classes.health} style={{ paddingTop: 32 }}>
						<Typography
							className={classes.healthItem}
							style={{ fontWeight: 'bold', minWidth: 110 }}
						>
							Fat (%)
						</Typography>
						<Controller
							name="fat"
							control={control}
							onChange={([, value]: [number, number]) => value}
							defaultValue={health ? health.fat : 0}
							render={(props) => (
								<Slider
									{...props}
									onChange={(_, value) => {
										props.onChange(value);
									}}
									valueLabelDisplay="on"
									max={100}
									step={5}
								/>
							)}
						/>
					</div>
					<div className={classes.health} style={{ paddingTop: 32 }}>
						<Typography
							className={classes.healthItem}
							style={{ fontWeight: 'bold', minWidth: 110 }}
						>
							Protein (%)
						</Typography>
						<Controller
							name="protein"
							control={control}
							onChange={([, value]: [number, number]) => value}
							defaultValue={health ? health.protein : 0}
							render={(props) => (
								<Slider
									{...props}
									onChange={(_, value) => {
										props.onChange(value);
									}}
									valueLabelDisplay="on"
									max={100}
									step={5}
								/>
							)}
						/>
					</div>
					{macroError && (
						<div className={classes.health}>
							<Alert
								severity="error"
								variant="filled"
								className={classes.alert}
							>
								Macros must add to 100%
							</Alert>
						</div>
					)}
				</DialogContent>
				<DialogActions>
					<Button
						type="submit"
						className={classes.primaryButton}
						onClick={handleSubmit(handleHealthConfirmClick)}
					>
						Save
					</Button>
					<Button
						onClick={handleHealthClick}
						className={classes.secondaryButton}
					>
						Cancel
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default Health;
