import React from 'react';
import { FileObject } from 'material-ui-dropzone';

import PDropzone, { Props } from './Dropzone';
import { fileToBase64 } from '../utils';
import { Upload, UploadFile } from '../../../models/uploads';
import { useStoreActions, useStoreState } from '../../../store';

const Dropzone: React.FC = () => {
	const files = useStoreState((state) => state.uploads.files);
	const setFiles = useStoreActions((actions) => actions.uploads.setFiles);
	const selected = useStoreState((state) => state.uploads.selected);
	const setSelected = useStoreActions(
		(actions) => actions.uploads.setSelected,
	);
	const addUpload = useStoreActions((actions) => actions.uploads.addUpload);
	const getUploads = useStoreActions((actions) => actions.uploads.getUploads);
	const removeUpload = useStoreActions(
		(actions) => actions.uploads.removeUpload,
	);
	const updateUpload = useStoreActions(
		(actions) => actions.uploads.updateUpload,
	);
	const setLoading = useStoreActions((actions) => actions.uploads.setLoading);

	const checkUploads = async (uploads: Upload[]) => {
		try {
			await Promise.all(
				uploads.map(
					async (upload) =>
						upload.error &&
						upload.errorMessage === 'duplicate upload' &&
						(await updateUpload(upload.id)),
				),
			);
			await getUploads();
		} catch (err) {}
	};

	const handleAdd = async (addedFiles: FileObject[]) => {
		setLoading(true);
		try {
			const newFiles = await Promise.all(
				addedFiles.map(async (file) => {
					try {
						const base64 = await fileToBase64(file.file);
						const upload = await addUpload({
							base64: base64 ? base64 : 'invalid',
							filename: file.file.name,
						});
						const uploadFile = {
							...file,
							id: upload.id,
						};
						return uploadFile;
					} catch (err) {
						throw err;
					}
				}),
			);
			const uploads = await getUploads();
			setFiles([...files, ...newFiles]);
			await checkUploads(uploads);
			setLoading(false);
		} catch (err) {}
	};

	const handleDelete = async (deleted: FileObject) => {
		setLoading(true);
		try {
			const deletedFile = deleted as UploadFile;
			try {
				await removeUpload(deletedFile.id);
			} catch (err) {}
			const uploads = await getUploads();
			setSelected(selected.filter((row) => row !== deletedFile.id));
			setFiles(files.filter((file) => file.id !== deletedFile.id));
			await checkUploads(uploads);
			setLoading(false);
		} catch (err) {}
	};

	const props: Props = {
		files,
		handleAdd,
		handleDelete,
	};

	return <PDropzone {...props} />;
};

export default Dropzone;
