import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(),
	},
	rowLink: {
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
		color: theme.palette.common.black,
	},
	row: {
		'&$selected, &$selected:hover': {
			backgroundColor: theme.palette.action.hover,
		},
	},
	header: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.defaultBlack,
	},
	checkbox: {
		color: theme.palette.defaultBlack,
	},
	selected: {},
	iconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
		paddingTop: 0,
		paddingBottom: 0,
	},
	icon: {
		color: theme.palette.common.black,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	filterBar: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		height: '100%',
		marginBottom: theme.spacing(),
	},
	tableSelection: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		height: '100%',
		marginTop: theme.spacing(),
	},
	activityCount: {
		marginLeft: 'auto',
		fontWeight: 'bold',
	},
	filterDetails: {
		marginTop: theme.spacing(),
		marginBottom: theme.spacing(),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
	},
}));
