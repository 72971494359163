import React from 'react';
import { useForm } from 'react-hook-form';

import { DescriptionData } from './interfaces';
import { useStoreActions, useStoreState } from '../../../../store';
import PDetails, { Props as DetailsProps } from './Details';

export interface Props {
	expand: boolean;
	setExpand: React.Dispatch<React.SetStateAction<boolean>>;
}

const Details: React.FC<Props> = ({ expand, setExpand }) => {
	const activity = useStoreState((state) => state.activity.activity);
	const updateActivity = useStoreActions(
		(actions) => actions.activity.updateActivity,
	);
	const setActivityDetails = useStoreActions(
		(actions) => actions.activity.setActivityDetails,
	);

	const { handleSubmit, register, errors, reset } = useForm<
		DescriptionData
	>();

	const handleFormSubmit = async (data: DescriptionData) => {
		try {
			if (activity) {
				const updatedActivity = await updateActivity({
					activityId: activity.id,
					params: { ...data },
				});
				setActivityDetails(updatedActivity);
				setExpand(false);
			}
		} catch (err) {}
	};

	const handleFormCancel = () => {
		if (activity) {
			reset({ description: activity.description });
		}
		setExpand(false);
	};

	const props: DetailsProps = {
		expand,
		errors,
		register,
		handleSubmit,
		handleFormSubmit,
		handleFormCancel,
	};

	return <PDetails {...props} />;
};

export default Details;
