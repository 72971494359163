import { Lap } from '../../../models/activity';

export const getRange = (laps: Lap[]): number[] => {
	const data = laps.concat();
	data.sort((a, b) => a.avgPace - b.avgPace);

	const min = data[0];
	const max = data[data.length - 1];

	return [min.avgPace, max.avgPace];
};
