import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

interface Props {
	size?: number;
	thickness?: number;
}

const Loading: React.FC<Props> = ({ size, thickness }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CircularProgress
				className={classes.loading}
				size={size ? size : 100}
				thickness={thickness ? thickness : 2}
			></CircularProgress>
		</div>
	);
};

export default Loading;
