import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(),
		marginLeft: theme.spacing(),
		marginRight: theme.spacing(),
		height: 350,
	},
}));
