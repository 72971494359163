import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Collapse, Divider } from '@material-ui/core';

import { useStyles } from './styles';
import { useStoreState } from '../../../../store';
import { getBmr, getCalories, getExtraCalories } from '../../../Log/Day/utils';

export interface Props {
	expand: boolean;
}

const Details: React.FC<Props> = ({ expand }) => {
	const classes = useStyles();

	const activities = useStoreState((state) => state.day.activities);
	const theme = useStoreState((state) => state.settings.theme);
	const health = useStoreState((state) => state.day.health);
	const user = useStoreState((state) => state.user.user);
	const date = useStoreState((state) => state.day.date);

	return (
		<Collapse in={expand}>
			<Divider className={classes.divider} />
			<div className={classes.headerDetailsContainer}>
				<div>
					<Typography variant="h5">Details</Typography>
				</div>
				<div className={classes.headerDetails}>
					{health && (
						<div className={classes.headerBlock}>
							<Typography variant="subtitle1">Health</Typography>
							<div className={classes.blockDetails}>
								<div
									style={{
										marginRight: theme.spacing(),
									}}
								>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{`${health.height} cm`}
									</Typography>
									<Typography variant="subtitle2">
										Height
									</Typography>
								</div>
								<div>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{`${health.weight} kg`}
									</Typography>
									<Typography variant="subtitle2">
										Weight
									</Typography>
								</div>
							</div>
						</div>
					)}
					{health && (
						<div className={classes.headerBlock}>
							<Typography variant="subtitle1">
								Resting Calories
							</Typography>
							<div className={classes.blockDetails}>
								<div
									style={{
										marginRight: theme.spacing(),
									}}
								>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{`${Math.round(
											getBmr(health, user, date) / 1.2,
										)} C +`}
									</Typography>
									<Typography variant="subtitle2">
										BMR
									</Typography>
								</div>
								<div
									style={{
										marginRight: theme.spacing(),
									}}
								>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{`${Math.round(
											getBmr(health, user, date) -
												getBmr(health, user, date) /
													1.2,
										)} C =`}
									</Typography>
									<Typography variant="subtitle2">
										Adjusted
									</Typography>
								</div>
								<div>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{`${getBmr(health, user, date)} C`}
									</Typography>
									<Typography variant="subtitle2">
										Total
									</Typography>
								</div>
							</div>
						</div>
					)}
					<div className={classes.headerBlock}>
						<Typography variant="subtitle1">
							Active Calories
						</Typography>
						<div className={classes.blockDetails}>
							<div
								style={{
									marginRight: theme.spacing(),
								}}
							>
								<Typography
									variant="h5"
									style={{
										fontWeight: 'lighter',
									}}
								>
									{`${getCalories(activities)} C +`}
								</Typography>
								<Typography variant="subtitle2">
									Activities
								</Typography>
							</div>
							<div
								style={{
									marginRight: theme.spacing(),
								}}
							>
								<Typography
									variant="h5"
									style={{
										fontWeight: 'lighter',
									}}
								>
									{`${getExtraCalories(health, date)} C =`}
								</Typography>
								<Typography variant="subtitle2">
									Extra
								</Typography>
							</div>
							<div>
								<Typography
									variant="h5"
									style={{
										fontWeight: 'lighter',
									}}
								>
									{`${
										getCalories(activities) +
										getExtraCalories(health, date)
									} C`}
								</Typography>
								<Typography variant="subtitle2">
									Total
								</Typography>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Collapse>
	);
};

export default Details;
