import React from 'react';
import moment from 'moment';
import { useMediaQuery } from '@material-ui/core';

import PHeader, { Props } from './Header';
import { useStoreState, useStoreActions } from '../../../store';

const Header: React.FC = () => {
	const theme = useStoreState((state) => state.settings.theme);
	const week = useStoreState((state) => state.log.week);
	const setWeek = useStoreActions((actions) => actions.log.setWeek);
	const setDate = useStoreActions((actions) => actions.log.setDate);

	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

	const handleNext = () => {
		setWeek(week + 1);
	};

	const handlePrevious = () => {
		setWeek(week - 1);
	};

	const handleCurrent = () => {
		setWeek(0);
	};

	const handleDateChange = (newDate: Date | null): void => {
		if (newDate && moment(newDate).isValid()) {
			setDate(newDate);
			setWeek(0);
		}
	};

	const handleDateReset = () => {
		const current = new Date();
		setDate(current);
		setWeek(0);
	};

	const props: Props = {
		isMobile,
		handleNext,
		handlePrevious,
		handleCurrent,
		handleDateReset,
		handleDateChange,
	};

	return <PHeader {...props} />;
};

export default Header;
