import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	controls: {
		margin: theme.spacing(),
	},
	control: {
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		width: 100,
		color: theme.palette.defaultBlack,
	},
	item: {
		margin: theme.spacing(),
	},
	food: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
	},
	datePicker: {
		color: theme.palette.common.black,
		'&:hover': {
			color: theme.palette.primary.main,
		},
		padding: 0,
	},
}));
