import React from 'react';
import moment from 'moment';
import { Chip, Divider, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { useStoreState } from '../../../store';
import {
	formatDistance,
	formatElevation,
	formatTime,
} from '../../../utils/formatters';
import { Shoe } from '../../../models/shoes';
import FootprintIcon from '../../Icons/FootprintIcon';
import { getDistance, getTime, getElevation, getCalories } from './utils';

export interface Props {
	startDate: Date | undefined;
	endDate: Date;
	shoes: Shoe[];
}

const AllTimeStats: React.FC<Props> = ({ startDate, endDate, shoes }) => {
	const classes = useStyles();

	const theme = useStoreState((state) => state.settings.theme);
	const activities = useStoreState((state) => state.activities.activities);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const elevationUnit = useStoreState(
		(state) => state.settings.elevationUnit,
	);

	return (
		<div className={classes.root}>
			<div className={classes.header}>
				<div className={classes.title}>
					<Typography variant="subtitle1">
						{startDate !== undefined &&
							`${moment(startDate).format(
								'Do MMM yyyy',
							)} - ${moment(endDate).format('Do MMM yyyy')}`}
					</Typography>
					<Typography variant="h5">All-Time Stats</Typography>
				</div>
			</div>
			<Divider className={classes.divider} />
			<div style={{ margin: theme.spacing() }}>
				<div>
					<Typography variant="h5">Activities</Typography>
				</div>
				<div className={classes.header}>
					<div style={{ margin: theme.spacing() }}>
						<Typography variant="subtitle1">Activities</Typography>
						<Typography
							variant="h5"
							style={{ fontWeight: 'lighter' }}
						>
							{activities.length}
						</Typography>
					</div>
					<div style={{ margin: theme.spacing() }}>
						<Typography variant="subtitle1">Distance</Typography>
						<Typography
							variant="h5"
							style={{ fontWeight: 'lighter' }}
						>
							{formatDistance(
								getDistance(activities),
								distanceUnit,
							)}
						</Typography>
					</div>
					<div style={{ margin: theme.spacing() }}>
						<Typography variant="subtitle1">Time</Typography>
						<Typography
							variant="h5"
							style={{ fontWeight: 'lighter' }}
						>
							{`${formatTime(getTime(activities))} hrs`}
						</Typography>
					</div>
					<div style={{ margin: theme.spacing() }}>
						<Typography variant="subtitle1">Elevation</Typography>
						<Typography
							variant="h5"
							style={{ fontWeight: 'lighter' }}
						>
							{formatElevation(
								getElevation(activities),
								elevationUnit,
							)}
						</Typography>
					</div>
					<div style={{ margin: theme.spacing() }}>
						<Typography variant="subtitle1">Calories</Typography>
						<Typography
							variant="h5"
							style={{ fontWeight: 'lighter' }}
						>
							{`${getCalories(activities)} C`}
						</Typography>
					</div>
				</div>
			</div>
			<div className={classes.shoes}>
				{shoes.map((shoe, index) => {
					return (
						<div key={index} style={{ margin: theme.spacing() }}>
							<Chip
								className={classes.chip}
								label={`${shoe.name} - ${formatDistance(
									shoe.distance,
									distanceUnit,
								)}`}
								icon={<FootprintIcon className={classes.svg} />}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default AllTimeStats;
