import React, { useEffect, useRef, useState } from 'react';
import { CartesianGrid, Dot } from 'recharts';

import Loading from '../../Loading';
import { useStyles } from './styles';
import PGraph, { Props } from './Graph';
import { Stream } from '../../../models/activity';
import { formatTime } from '../../../utils/formatters';
import useWindowDimensions from '../../../utils/windowSize';
import { useStoreActions, useStoreState } from '../../../store';
import { getDomain, getRange } from './utils';

const Graph: React.FC = () => {
	const classes = useStyles();

	const { width } = useWindowDimensions();

	const activity = useStoreState((state) => state.activity.activity);
	const setMapPosition = useStoreActions(
		(actions) => actions.activity.setMapPosition,
	);

	const graphRef = useRef<CartesianGrid | null>(null);
	const [refVisible, setRefVisible] = useState(false);
	const [loading, setLoading] = useState(true);
	const [multiplier, setMultiplier] = useState(1);
	const [domain, setDomain] = useState<number[]>([]);
	const [graphData, setGraphData] = useState<Stream[]>([]);
	const [paceRange, setPaceRange] = useState<number[]>([]);
	const [heartRateRange, setHeartRateRange] = useState<number[]>([]);
	const [elevationRange, setAltitudeRange] = useState<number[]>([]);

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const CustomDot = (props: any & { index?: number }) => {
		useEffect(() => {
			if (props.index) {
				setMapPosition(props.index * multiplier);
			}
		});
		return <Dot {...props} r={8} />;
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const HiddenCustomDot = (props: any & { index?: number }) => {
		useEffect(() => {
			if (props.index) {
				setMapPosition(props.index * multiplier);
			}
		});
		return <Dot {...props} r={0} />;
	};

	const handleRef = (el: CartesianGrid | null): void => {
		graphRef.current = el;
		setRefVisible(!!el);
	};

	const handleTick = (value: number): string => {
		return formatTime(value);
	};

	const handleMouseLeave = () => {
		setMapPosition(0);
	};

	useEffect(() => {
		if (activity && !graphData.length) {
			const { streams: data, duration, pauseTime } = activity;
			setDomain(getDomain(duration - pauseTime, data[0].time));
			setPaceRange(getRange(data, 'pace'));
			setHeartRateRange(getRange(data, 'heartRate'));
			setAltitudeRange(getRange(data, 'elevation'));
			setGraphData(data);
			setLoading(false);
		}
	}, [activity, graphData]);

	useEffect(() => {
		if (!refVisible) {
			return;
		}
		if (graphRef.current && graphRef.current.props.width && activity) {
			const { streams } = activity;
			const newMultiplier = Math.ceil(
				streams.length / graphRef.current.props.width,
			);
			if (newMultiplier !== multiplier) {
				setMultiplier(newMultiplier);
				const data = streams.filter(
					(_dataEntry, index) => index % newMultiplier === 0,
				);
				setGraphData(data);
				setPaceRange(getRange(data, 'pace'));
				setHeartRateRange(getRange(data, 'heartRate'));
				setAltitudeRange(getRange(data, 'elevation'));
			}
		}
	}, [activity, graphRef, multiplier, width, refVisible]);

	const props: Props = {
		data: graphData,
		handleRef,
		handleTick,
		handleMouseLeave,
		CustomDot,
		HiddenCustomDot,
		domain,
		paceRange,
		elevationRange,
		heartRateRange,
	};

	return (
		<div className={classes.root}>
			{loading ? <Loading /> : <PGraph {...props} />}
		</div>
	);
};

export default Graph;
