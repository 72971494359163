import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import { Button, Collapse, Divider, TextField } from '@material-ui/core';

import { useStyles } from './styles';
import { DescriptionData } from './interfaces';
import { useStoreState } from '../../../../store';
import { formatElevation, formatTime } from '../../../../utils/formatters';

export interface Props
	extends Pick<UseFormMethods, 'handleSubmit' | 'register' | 'errors'> {
	expand: boolean;
	handleFormSubmit: (data: DescriptionData) => Promise<void>;
	handleFormCancel: () => void;
}

const Details: React.FC<Props> = ({
	expand,
	register,
	errors,
	handleSubmit,
	handleFormSubmit,
	handleFormCancel,
}) => {
	const classes = useStyles();

	const theme = useStoreState((state) => state.settings.theme);
	const activity = useStoreState((state) => state.activity.activity);
	const elevationUnit = useStoreState(
		(state) => state.settings.elevationUnit,
	);

	return (
		<Collapse in={expand}>
			<Divider className={classes.divider} />
			<div className={classes.headerDetailsContainer}>
				<div>
					<Typography variant="h5">Details</Typography>
				</div>
				{activity && (
					<div className={classes.headerDetails}>
						<div className={classes.headerBlock}>
							<Typography variant="subtitle1">Timing</Typography>
							<div className={classes.headerDetails}>
								<div
									style={{
										marginRight: theme.spacing(),
									}}
								>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{formatTime(activity.pauseTime)}
									</Typography>
									<Typography variant="subtitle2">
										Paused
									</Typography>
								</div>
								<div>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{formatTime(activity.duration)}
									</Typography>
									<Typography variant="subtitle2">
										Duration
									</Typography>
								</div>
							</div>
						</div>
						<div className={classes.headerBlock}>
							<Typography variant="subtitle1">
								Heart Rate
							</Typography>
							<div className={classes.headerDetails}>
								<div
									style={{
										marginRight: theme.spacing(),
									}}
								>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{activity.avgHeartRate
											? `${activity.avgHeartRate} bpm`
											: 'n/a'}
									</Typography>
									<Typography variant="subtitle2">
										Avg
									</Typography>
								</div>
								<div>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{activity.maxHeartRate
											? `${activity.maxHeartRate} bpm`
											: 'n/a'}
									</Typography>
									<Typography variant="subtitle2">
										Max
									</Typography>
								</div>
							</div>
						</div>
						<div className={classes.headerBlock}>
							<Typography variant="subtitle1">
								Elevation
							</Typography>
							<div className={classes.headerDetails}>
								<div
									style={{
										marginRight: theme.spacing(),
									}}
								>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{activity.ascent ||
										activity.ascent === 0
											? formatElevation(
													activity.ascent,
													elevationUnit,
											  )
											: 'n/a'}
									</Typography>
									<Typography variant="subtitle2">
										Gain
									</Typography>
								</div>
								<div>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{activity.descent ||
										activity.descent === 0
											? formatElevation(
													activity.descent,
													elevationUnit,
											  )
											: 'n/a'}
									</Typography>
									<Typography variant="subtitle2">
										Loss
									</Typography>
								</div>
							</div>
						</div>
						<div className={classes.headerBlock}>
							<Typography variant="subtitle1">Cadence</Typography>
							<div className={classes.headerDetails}>
								<div
									style={{
										marginRight: theme.spacing(),
									}}
								>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{`${activity.avgCadence * 2} spm`}
									</Typography>
									<Typography variant="subtitle2">
										Avg
									</Typography>
								</div>
								<div>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{`${activity.maxCadence * 2} spm`}
									</Typography>
									<Typography variant="subtitle2">
										Max
									</Typography>
								</div>
							</div>
						</div>
						<div className={classes.headerBlock}>
							<Typography variant="subtitle1">
								Calories
							</Typography>
							<div className={classes.headerDetails}>
								<div
									style={{
										marginRight: theme.spacing(),
									}}
								>
									<Typography
										variant="h5"
										style={{
											fontWeight: 'lighter',
										}}
									>
										{`${activity.calories} C`}
									</Typography>
									<Typography variant="subtitle2">
										Calories Burned
									</Typography>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
			<Divider className={classes.divider} />
			<form>
				<div className={classes.headerDetailsContainer}>
					<Typography variant="h5">Description</Typography>
					<div className={classes.description}>
						<TextField
							autoComplete="off"
							className={classes.descriptionInput}
							placeholder="Enter a description..."
							name="description"
							inputRef={register({
								required: {
									value: true,
									message: 'required',
								},
								minLength: {
									value: 1,
									message: 'minimum 1 character',
								},
								maxLength: {
									value: 1000,
									message: 'maximum 1000 characters',
								},
							})}
							variant="outlined"
							multiline
							rows={4}
							rowsMax={4}
							defaultValue={
								activity && activity.description
									? activity.description
									: ''
							}
							label={
								errors.description && errors.description.message
							}
							error={errors.description ? true : false}
							InputProps={{ style: { padding: 8 } }}
						/>
						<div style={{ marginLeft: 'auto' }}>
							<Button
								className={classes.button}
								type="submit"
								onClick={handleSubmit(handleFormSubmit)}
							>
								Save
							</Button>
							<Button
								className={classes.secondaryButton}
								onClick={handleFormCancel}
							>
								Cancel
							</Button>
						</div>
					</div>
				</div>
			</form>
		</Collapse>
	);
};

export default Details;
