export const mapError = (errorStatus: number): string => {
	if ([401, 404].includes(errorStatus)) {
		return 'Incorrect email or password';
	} else if (errorStatus === 409) {
		return 'Email already registered';
	} else if (errorStatus === 400) {
		return 'Email required';
	} else if (errorStatus === 500) {
		return '500: server error';
	} else if (errorStatus === 200) {
		return 'Reset email sent';
	} else if (errorStatus === 4040) {
		return 'Email not registered';
	}
	return '';
};
