import { axiosService } from '../../services/axios';

import { ResetFormData } from './interfaces';

export const resetPassword = async (data: ResetFormData): Promise<void> => {
	try {
		const response = await axiosService.post('/api/user/password/reset', {
			password: data.password,
			token: data.token,
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};
