import { action, Action, thunk, Thunk } from 'easy-peasy';

import { StoreModel } from '.';
import { axiosService } from '../services/axios';

export interface Tag {
	id: string;
	name: string;
}

export interface TagsModel {
	tags: Tag[];
	setTags: Action<TagsModel, Tag[]>;
	selected: string[];
	setSelected: Action<TagsModel, string[]>;
	removeId: string | undefined;
	setRemoveId: Action<TagsModel, string | undefined>;
	removeOne: boolean;
	setRemoveOne: Action<TagsModel>;
	removeSelected: boolean;
	setRemoveSelected: Action<TagsModel>;
	addTag: Thunk<TagsModel, string, undefined, StoreModel, Promise<Tag>>;
	getTags: Thunk<TagsModel, undefined, undefined, StoreModel, Promise<Tag[]>>;
	removeTag: Thunk<TagsModel, string, undefined, StoreModel, Promise<void>>;
}

const addTag = async (name: string): Promise<Tag> => {
	try {
		const response = await axiosService.post(
			'/api/tags',
			{ name },
			{
				headers: {
					authorization: localStorage.getItem('JWT'),
				},
			},
		);
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const getTags = async (): Promise<Tag[]> => {
	try {
		const response = await axiosService.get('/api/tags', {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const removeTag = async (tagId: string): Promise<void> => {
	try {
		await axiosService.delete(`/api/tags/${tagId}`, {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
	} catch (err) {
		throw err;
	}
};

const tags: TagsModel = {
	tags: [],
	setTags: action((state, value) => {
		state.tags = value;
	}),
	selected: [],
	setSelected: action((state, value) => {
		state.selected = value;
	}),
	removeOne: false,
	setRemoveOne: action((state) => {
		state.removeOne = !state.removeOne;
	}),
	removeSelected: false,
	setRemoveSelected: action((state) => {
		state.removeSelected = !state.removeSelected;
	}),
	removeId: undefined,
	setRemoveId: action((state, value) => {
		state.removeId = value;
	}),
	addTag: thunk(async (_actions, value) => {
		try {
			const tag = await addTag(value);
			return tag;
		} catch (err) {
			throw err;
		}
	}),
	getTags: thunk(async (actions) => {
		try {
			const tags = await getTags();
			actions.setTags(tags);
			return tags;
		} catch (err) {
			throw err;
		}
	}),
	removeTag: thunk(async (_actions, value) => {
		try {
			await removeTag(value);
		} catch (err) {
			throw err;
		}
	}),
};

export default tags;
