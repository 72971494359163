import React from 'react';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import { formatDistance } from '../../../../utils/formatters';
import { useStoreState } from '../../../../store';

export interface Props {
	id: number;
	distance: number;
}

const TooltipContent: React.FC<Props> = ({ distance, id }) => {
	const classes = useStyles();

	const date = useStoreState((state) => state.dashboard.date);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);

	return (
		<Paper className={classes.paper} variant="outlined">
			<div className={classes.tooltip}>
				<Typography className={classes.text}>
					{`${moment(date)
						.subtract(Math.abs(id), 'w')
						.startOf('w')
						.format('Do MMM')} - ${moment(date)
						.subtract(Math.abs(id), 'w')
						.endOf('w')
						.format('Do MMM')}`}
				</Typography>
				<Typography className={classes.text}>{`${formatDistance(
					distance,
					distanceUnit,
				)}`}</Typography>
			</div>
		</Paper>
	);
};

export default TooltipContent;
