import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(),
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
		flexWrap: 'wrap',
	},
	title: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'flex-start',
		margin: theme.spacing(),
	},
	icons: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end',
		margin: theme.spacing(),
	},
	iconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
		padding: 0,
	},
	icon: {
		color: theme.palette.common.black,
	},
	infoItem: {
		margin: theme.spacing(),
	},
	info: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
	},
	divider: {
		marginTop: 8,
		marginBottom: 8,
	},
	detailsHeader: {
		margin: theme.spacing(),
	},
	details: {
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
	},
	link: {
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline',
		},
		color: theme.palette.common.black,
	},
	detailStats: {
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
	},
	svg: {
		fill: theme.palette.defaultBlack,
	},
	chip: {
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
	},
}));
