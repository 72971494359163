import { Entry } from '../../../models/foods';

export const formatMealNumber = (number: number): number =>
	Math.round(number * 10) / 10;

export const getMealEntries = (entries: Entry[], meal: number): Entry[] =>
	entries.filter((entry) => entry.mealNumber === meal);

export const getMealTotal = (
	entries: Entry[],
	field: 'calories' | 'carbs' | 'fat' | 'protein',
	number: number,
): number => {
	const filtered = getMealEntries(entries, number);
	return formatMealNumber(
		filtered.reduce((acc, curr) => acc + formatMealNumber(curr[field]), 0),
	);
};
