import React from 'react';
import MuiTooltip, { TooltipProps } from '@material-ui/core/Tooltip';

import { useStyles } from './styles';

export const Tooltip: React.FC<TooltipProps> = ({ ...props }) => {
	const classes = useStyles();

	return (
		<MuiTooltip
			{...props}
			arrow
			classes={{
				tooltip: classes.tooltip,
				arrow: classes.arrow,
			}}
		/>
	);
};

export const TooltipNoMargin: React.FC<TooltipProps> = ({ ...props }) => {
	const classes = useStyles();

	return (
		<MuiTooltip
			{...props}
			arrow
			classes={{
				tooltipPlacementBottom: classes.tooltipPlacementBottom,
				tooltip: classes.tooltip,
				arrow: classes.arrow,
			}}
		/>
	);
};

export default Tooltip;
