import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';

export interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	component: any;
	to: string | undefined;
	isMobile: boolean;
}

const Logo: React.FC<Props> = ({ isMobile, ...props }) => {
	const classes = useStyles();

	return (
		<Button
			{...props}
			className={classes.logoButton}
			variant="text"
			disableRipple={true}
		>
			<Typography
				className={classes.tuff}
				style={{ fontSize: isMobile ? 24 : 48 }}
			>
				Tuff
			</Typography>
			<Typography
				className={classes.trainer}
				style={{ fontSize: isMobile ? 24 : 48 }}
			>
				Trainer
			</Typography>
		</Button>
	);
};

export default Logo;
