import React from 'react';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import { useStoreState } from '../../../../store';
import { formatDistance } from '../../../../utils/formatters';
import { getCaloriesEaten } from './utils';

export interface Props {
	distance: number;
	totalCalories: number;
}

const Info: React.FC<Props> = ({ distance, totalCalories }) => {
	const classes = useStyles();

	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const entries = useStoreState((state) => state.foods.entries);

	return (
		<div>
			<div className={classes.header}>
				<div className={classes.headerBlock}>
					<Typography variant="subtitle1">Total Distance</Typography>
					<Typography variant="h5" style={{ fontWeight: 'lighter' }}>
						{formatDistance(distance, distanceUnit)}
					</Typography>
				</div>
				<div className={classes.headerBlock}>
					<Typography variant="subtitle1">Calories Eaten</Typography>
					<Typography variant="h5" style={{ fontWeight: 'lighter' }}>
						{`${getCaloriesEaten(entries)} C`}
					</Typography>
				</div>
				<div className={classes.headerBlock}>
					<Typography variant="subtitle1">Total Calories</Typography>
					<Typography variant="h5" style={{ fontWeight: 'lighter' }}>
						{`${totalCalories} C`}
					</Typography>
				</div>
			</div>
		</div>
	);
};

export default Info;
