import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(),
	},
	title: {
		fontSize: 24,
		fontWeight: 'bold',
	},
}));
