import React from 'react';
import { ReactComponent as Footprint } from '../../../images/footprint_icon.svg';

interface Props {
	className: string;
}

const FootprintIcon: React.FC<Props> = ({ className }) => {
	return <Footprint className={className} width={35.42} height={35.42} />;
};

export default FootprintIcon;
