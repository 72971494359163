import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'flex-start',
		width: '100%',
		overflow: 'auto',
	},
	activity: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: '100%',
	},
	activityContainer: {
		width: 240,
		margin: theme.spacing(),
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: 240,
		height: 109,
		overflow: 'hidden',
	},
	title: {
		width: '100%',
		fontWeight: 'bold',
		backgroundColor: theme.palette.background.default,
	},
}));
