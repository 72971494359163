export const fileToBase64 = (file: File): Promise<string> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve((reader.result as string).split(',')[1]);
		reader.onerror = (error) => reject(error);
	});

export const convertDistance = (distance: number, unit: string): string => {
	if (unit === 'm') {
		return `${(distance / 1000).toFixed(2)}km`;
	}
	return `${distance.toFixed(2)}${unit}`;
};
