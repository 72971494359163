import React from 'react';
import {
	Divider,
	FormControl,
	Radio,
	FormLabel,
	RadioGroup,
	Typography,
	FormControlLabel,
} from '@material-ui/core';

import { useStyles } from './styles';
import { useStoreState } from '../../../store';

export interface Props {
	handleDistanceChange: (
		event: React.ChangeEvent<HTMLInputElement>,
	) => Promise<void>;
	handlePaceChange: (
		event: React.ChangeEvent<HTMLInputElement>,
	) => Promise<void>;
	handleElevationChange: (
		event: React.ChangeEvent<HTMLInputElement>,
	) => Promise<void>;
	handleTimeFormatChange: (
		event: React.ChangeEvent<HTMLInputElement>,
	) => Promise<void>;
	handleThemeChange: (
		event: React.ChangeEvent<HTMLInputElement>,
	) => Promise<void>;
}

const Display: React.FC<Props> = ({
	handleDistanceChange,
	handlePaceChange,
	handleElevationChange,
	handleTimeFormatChange,
	handleThemeChange,
}) => {
	const classes = useStyles();

	const themeType = useStoreState((state) => state.settings.themeType);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const paceUnit = useStoreState((state) => state.settings.paceUnit);
	const elevationUnit = useStoreState(
		(state) => state.settings.elevationUnit,
	);
	const timeFormat = useStoreState((state) => state.settings.timeFormat);

	return (
		<div className={classes.root}>
			<Typography variant="h5" className={classes.header}>
				Display preferences
			</Typography>
			<Divider />
			<div className={classes.content}>
				<FormControl>
					<FormLabel className={classes.formLabel}>
						Distance unit
					</FormLabel>
					<RadioGroup
						value={distanceUnit}
						onChange={handleDistanceChange}
						style={{ flexDirection: 'row' }}
					>
						<FormControlLabel
							value="km"
							control={<Radio color="primary" />}
							label={
								<Typography className={classes.radio}>
									km
								</Typography>
							}
						/>
						<FormControlLabel
							value="mi"
							control={<Radio color="primary" />}
							label={
								<Typography className={classes.radio}>
									mi
								</Typography>
							}
						/>
					</RadioGroup>
				</FormControl>
				<FormControl>
					<FormLabel className={classes.formLabel}>
						Pace unit
					</FormLabel>
					<RadioGroup
						value={paceUnit}
						onChange={handlePaceChange}
						style={{ flexDirection: 'row' }}
					>
						<FormControlLabel
							value="/km"
							control={<Radio color="primary" />}
							label={
								<Typography className={classes.radio}>
									/km
								</Typography>
							}
						/>
						<FormControlLabel
							value="/mi"
							control={<Radio color="primary" />}
							label={
								<Typography className={classes.radio}>
									/mi
								</Typography>
							}
						/>
					</RadioGroup>
				</FormControl>
				<FormControl>
					<FormLabel className={classes.formLabel}>
						Elevation unit
					</FormLabel>
					<RadioGroup
						value={elevationUnit}
						onChange={handleElevationChange}
						style={{ flexDirection: 'row' }}
					>
						<FormControlLabel
							value="m"
							control={<Radio color="primary" />}
							label={
								<Typography className={classes.radio}>
									m
								</Typography>
							}
						/>
						<FormControlLabel
							value="ft"
							control={<Radio color="primary" />}
							label={
								<Typography className={classes.radio}>
									ft
								</Typography>
							}
						/>
					</RadioGroup>
				</FormControl>
				<FormControl>
					<FormLabel className={classes.formLabel}>
						Time format
					</FormLabel>
					<RadioGroup
						value={timeFormat}
						onChange={handleTimeFormatChange}
						style={{ flexDirection: 'row' }}
					>
						<FormControlLabel
							value="12h"
							control={<Radio color="primary" />}
							label={
								<Typography className={classes.radio}>
									12h
								</Typography>
							}
						/>
						<FormControlLabel
							value="24h"
							control={<Radio color="primary" />}
							label={
								<Typography className={classes.radio}>
									24h
								</Typography>
							}
						/>
					</RadioGroup>
				</FormControl>
				<FormControl className={classes.formLabel}>
					<FormLabel>Theme</FormLabel>
					<RadioGroup
						value={themeType}
						onChange={handleThemeChange}
						style={{ flexDirection: 'row' }}
					>
						<FormControlLabel
							value="light"
							control={<Radio color="primary" />}
							label={
								<Typography className={classes.radio}>
									light
								</Typography>
							}
						/>
						<FormControlLabel
							value="dark"
							control={<Radio color="primary" />}
							label={
								<Typography className={classes.radio}>
									dark
								</Typography>
							}
						/>
					</RadioGroup>
				</FormControl>
			</div>
		</div>
	);
};

export default Display;
