import React from 'react';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import { formatDate } from '../../../../utils/formatters';
import { useStoreState } from '../../../../store';

const Title: React.FC = () => {
	const classes = useStyles();

	const date = useStoreState((state) => state.day.date);

	return (
		<div className={classes.root}>
			<Typography variant="subtitle1">
				{formatDate(date, 'dddd Do MMMM yyyy')}
			</Typography>
			<div className={classes.title}>
				<Typography variant="h5">Daily Summary</Typography>
			</div>
		</div>
	);
};

export default Title;
