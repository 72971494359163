import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Info from './Info';
import Title from './Title';
import { getShoes, resetShoes } from './utils';
import Loading from '../../Loading';
import { useStyles } from './styles';
import { ShoeData } from './interfaces';
import Tags, { Props as TagsProps } from '../Tags';
import Icons, { Props as IconsProps } from './Icons';
import Shoes, { Props as ShoesProps } from '../Shoes';
import Details, { Props as DetailsProps } from './Details';
import Dialog, { Props as RemoveProps } from '../../Dialog';
import { useStoreActions, useStoreState } from '../../../store';

const Header: React.FC = () => {
	const classes = useStyles();

	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const getAllShoes = useStoreActions((actions) => actions.shoes.getShoes);
	const activity = useStoreState((state) => state.activity.activity);
	const selected = useStoreState((state) => state.tags.selected);
	const removeActivity = useStoreActions(
		(actions) => actions.activity.removeActivity,
	);
	const updateActivity = useStoreActions(
		(actions) => actions.activity.updateActivity,
	);
	const getActivities = useStoreActions(
		(actions) => actions.activities.getActivities,
	);
	const setActivityDetails = useStoreActions(
		(actions) => actions.activity.setActivityDetails,
	);

	const [loading, setLoading] = useState(true);
	const [expand, setExpand] = useState(false);
	const [openRemove, setOpenRemove] = useState(false);
	const [openTags, setOpenTags] = useState(false);
	const [openShoes, setOpenShoes] = useState(false);

	const multiplier = distanceUnit === 'km' ? 1000 : 1609.34;

	const {
		handleSubmit,
		control,
		reset,
		getValues,
		register,
		errors,
		watch,
	} = useForm<ShoeData>();

	const handleExpandClick = () => {
		setExpand((value) => !value);
	};

	const handleRemoveClick = () => {
		setOpenRemove((value) => !value);
	};

	const handleTagsClick = () => {
		setOpenTags((value) => !value);
	};

	const handleShoesClick = () => {
		reset(resetShoes(activity ? activity.shoes : [], multiplier));
		setOpenShoes((value) => !value);
	};

	const handleRemoveConfirmClick = async () => {
		try {
			if (activity) {
				await removeActivity(activity.id);
				await getActivities();
				await getAllShoes();
			}
		} catch (err) {
		} finally {
			handleRemoveClick();
		}
	};

	const handleTagsConfirmClick = async () => {
		try {
			if (activity) {
				const updatedActivity = await updateActivity({
					activityId: activity.id,
					params: { tags: selected },
				});
				setActivityDetails(updatedActivity);
				await getActivities();
			}
		} catch (err) {
		} finally {
			handleTagsClick();
		}
	};

	const handleShoesConfirmClick = async (data: ShoeData) => {
		try {
			if (activity) {
				const shoes = getShoes(data, activity.distance, multiplier);
				const updatedActivity = await updateActivity({
					activityId: activity.id,
					params: { shoes },
				});
				setActivityDetails(updatedActivity);
				await getActivities();
				await getAllShoes();
			}
		} catch (err) {
		} finally {
			handleShoesClick();
		}
	};

	useEffect(() => {
		setLoading(false);
	}, []);

	const iconsProps: IconsProps = {
		handleExpandClick,
		handleRemoveClick,
		handleTagsClick,
		handleShoesClick,
	};

	const detailsProps: DetailsProps = {
		expand,
		setExpand,
	};

	const removeProps: RemoveProps = {
		open: openRemove,
		handleDialogClick: handleRemoveClick,
		handleDialogConfirmClick: handleRemoveConfirmClick,
		title: 'Remove Activity',
		content: 'Are you sure you want to remove this activity?',
		confirm: 'Remove',
	};

	const tagsProps: TagsProps = {
		openTags,
		handleTagsClick,
		handleTagsConfirmClick,
	};

	const shoesProps: ShoesProps = {
		openShoes,
		handleShoesClick,
		handleShoesConfirmClick,
		control,
		handleSubmit,
		reset,
		getValues,
		register,
		errors,
		watch,
	};

	return (
		<div className={classes.root}>
			{loading ? (
				<Loading />
			) : !activity ? (
				<Redirect to={'/activities'} />
			) : (
				<div>
					<div className={classes.header}>
						<Title />
						<Info />
						<Icons {...iconsProps} />
					</div>
					<Details {...detailsProps} />
					<Dialog {...removeProps} />
					<Tags {...tagsProps} />
					<Shoes {...shoesProps} />
				</div>
			)}
		</div>
	);
};

export default Header;
