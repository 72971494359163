import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import Title from '../Title';
import Loading from '../Loading';
import { useStyles } from './styles';
import { useStoreActions, useStoreState } from '../../store';
import { filter } from './utils';
import Table from './Table';
import { FiltersData } from './interfaces';
import Filters, { Props as FiltersProps } from './Filters';
import Selection, { Props as SelectionProps } from './Selection';
import useWindowDimensions from '../../utils/windowSize';
import Compare from './Compare';
import Error from '../Error';

const Activities: React.FC = () => {
	const classes = useStyles();
	const { height } = useWindowDimensions();

	const [loading, setLoading] = useState(true);

	const theme = useStoreState((state) => state.settings.theme);
	const tags = useStoreState((state) => state.tags.tags);
	const shoes = useStoreState((state) => state.shoes.shoes);
	const getTags = useStoreActions((actions) => actions.tags.getTags);
	const filters = useStoreState((state) => state.activities.filters);
	const getShoes = useStoreActions((actions) => actions.shoes.getShoes);
	const activities = useStoreState((state) => state.activities.activities);
	const getActivities = useStoreActions(
		(actions) => actions.activities.getActivities,
	);
	const setLength = useStoreActions(
		(actions) => actions.activities.setLength,
	);
	const setFilteredIds = useStoreActions(
		(actions) => actions.activities.setFilteredIds,
	);
	const redirectError = useStoreState(
		(state) => state.activities.redirectError,
	);
	const setRedirectError = useStoreActions(
		(actions) => actions.activities.setRedirectError,
	);

	const {
		handleSubmit,
		register,
		reset,
		control,
		getValues,
		errors,
		watch,
	} = useForm<FiltersData>();

	const handleClose = () => {
		setRedirectError(false);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (!tags.length) {
					await getTags();
				}
				if (!shoes.length) {
					await getShoes();
				}
				if (!activities.length) {
					await getActivities();
				}
				setLoading(false);
			} catch (err) {}
		};
		fetchData();
	}, [getActivities, getTags, getShoes]);

	useEffect(() => {
		const filtered = filter(activities, filters);
		setLength(filtered.length);
		setFilteredIds(filtered.map((activity) => activity.id));
	}, [filters, activities, setLength, setFilteredIds]);

	const filtersProps: FiltersProps = {
		control,
		register,
		errors,
		handleSubmit,
		getValues,
		reset,
		watch,
	};

	const selectionProps: SelectionProps = {
		reset,
	};

	return (
		<div className={classes.root}>
			{loading ? (
				<Loading />
			) : (
				<div
					className={classes.container}
					style={{
						maxHeight: height - 60 - theme.spacing(3),
						overflow: 'auto',
					}}
				>
					<Title title="Activities" />
					<div className={classes.container}>
						<Paper className={classes.paper}>
							<Filters {...filtersProps} />
						</Paper>
						<Paper className={classes.paper}>
							<Table />
							<Selection {...selectionProps} />
							<Compare />
						</Paper>
					</div>
				</div>
			)}
			{!loading && (
				<Error
					handleClose={handleClose}
					redirectError={redirectError}
					title="Activity does not exist"
				/>
			)}
		</div>
	);
};

export default Activities;
