import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles({
	activity: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-start',
		alignItems: 'center',
		width: '100%',
		overflow: 'hidden',
	},
});
