import { action, Action } from 'easy-peasy';

import { ActivityDetails } from './activity';
import { Health } from './health';

export interface DayModel {
	date: string;
	setDate: Action<DayModel, string>;
	activities: ActivityDetails[];
	setActivities: Action<DayModel, ActivityDetails[]>;
	health: Health | undefined;
	setHealth: Action<DayModel, Health>;
	macroError: boolean;
	setMacroError: Action<DayModel, boolean>;
}

const day: DayModel = {
	date: '',
	setDate: action((state, value) => {
		state.date = value;
	}),
	activities: [],
	setActivities: action((state, value) => {
		state.activities = value;
	}),
	health: undefined,
	setHealth: action((state, value) => {
		state.health = value;
	}),
	macroError: false,
	setMacroError: action((state, value) => {
		state.macroError = value;
	}),
};

export default day;
