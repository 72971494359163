import React from 'react';
import { UseFormMethods } from 'react-hook-form';

import { resetAllFilters } from '../Filters/utils';
import { useStoreActions, useStoreState } from '../../../store';
import PSelection, { Props as SelectionProps } from './Selection';

export type Props = Pick<UseFormMethods, 'reset'>;

export const Selection: React.FC<Props> = ({ reset }) => {
	const selected = useStoreState((state) => state.activity.selected);
	const setSelected = useStoreActions(
		(actions) => actions.activity.setSelected,
	);
	const getActivities = useStoreActions(
		(actions) => actions.activities.getActivities,
	);
	const removeActivity = useStoreActions(
		(actions) => actions.activity.removeActivity,
	);
	const setFilters = useStoreActions(
		(actions) => actions.activities.setFilters,
	);
	const setOrder = useStoreActions((actions) => actions.activities.setOrder);
	const setOrderBy = useStoreActions(
		(actions) => actions.activities.setOrderBy,
	);
	const setRemoveSelected = useStoreActions(
		(actions) => actions.activities.setRemoveSelected,
	);
	const setExpand = useStoreActions(
		(actions) => actions.activities.setExpand,
	);
	const setDate = useStoreActions((actions) => actions.activities.setDate);
	const setDateSecondary = useStoreActions(
		(actions) => actions.activities.setDateSecondary,
	);
	const setSelectedTags = useStoreActions(
		(actions) => actions.activities.setSelectedTags,
	);
	const setSelectedShoes = useStoreActions(
		(actions) => actions.activities.setSelectedShoes,
	);
	const rowsPerPage = useStoreState((state) => state.activities.rowsPerPage);
	const setPage = useStoreActions((actions) => actions.activities.setPage);
	const setRowsPerPage = useStoreActions(
		(actions) => actions.activities.setRowsPerPage,
	);
	const setFiltersData = useStoreActions(
		(actions) => actions.activities.setFiltersData,
	);
	const getAllShoes = useStoreActions((actions) => actions.shoes.getShoes);

	const handleReset = () => {
		setFilters([]);
		setFiltersData({});
		setSelected([]);
		setSelectedTags([]);
		setSelectedShoes([]);
		setDate(null);
		setDateSecondary(null);
		reset({
			...resetAllFilters,
		});
		setOrder('desc');
		setOrderBy('date');
		setExpand(false);
		setPage(0);
		setRowsPerPage(5);
	};

	const checkPage = (length: number) => {
		setPage(Math.ceil(length / rowsPerPage) - 1);
	};

	const handleRemoveSelected = async () => {
		try {
			for (const id of selected) {
				try {
					await removeActivity(id);
				} catch (err) {}
			}

			const activities = await getActivities();
			await getAllShoes();
			setRemoveSelected();
			setSelected([]);
			checkPage(activities.length);
		} catch (err) {}
	};

	const props: SelectionProps = {
		handleReset,
		handleRemoveSelected,
	};

	return <PSelection {...props} />;
};

export default Selection;
