import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(),
	},
	valid: {
		color: theme.palette.primary.main,
	},
	invalid: {
		color: theme.palette.error.main,
	},
	success: {
		color: theme.palette.success.main,
	},
	row: {
		'&$selected, &$selected:hover': {
			backgroundColor: theme.palette.action.hover,
		},
	},
	header: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.defaultBlack,
	},
	checkbox: {
		color: theme.palette.defaultBlack,
	},
	iconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
		paddingTop: 0,
		paddingBottom: 0,
	},
	icon: {
		color: theme.palette.common.black,
	},
	selected: {},
}));
