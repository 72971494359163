import { action, Action, thunk, Thunk } from 'easy-peasy';
import { FileObject } from 'material-ui-dropzone';

import { StoreModel } from '.';
import { axiosService } from '../services/axios';
import globals from '../utils/globals';

export interface Upload {
	id: string;
	filename: string;
	date: string;
	distance: number;
	unit: string;
	error?: number;
	errorMessage?: string;
	success?: boolean;
}

export interface AddUpload {
	base64: string;
	filename: string;
}

export interface UploadFile extends FileObject {
	id: string;
}

export interface RemoveOptions {
	removeSession?: boolean;
}

export interface UploadsModel {
	files: UploadFile[];
	setFiles: Action<UploadsModel, UploadFile[]>;
	selected: string[];
	setSelected: Action<UploadsModel, string[]>;
	loading: boolean;
	setLoading: Action<UploadsModel, boolean>;
	uploads: Upload[];
	setUploads: Action<UploadsModel, Upload[]>;
	getUploads: Thunk<
		UploadsModel,
		undefined,
		undefined,
		StoreModel,
		Promise<Upload[]>
	>;
	addUpload: Thunk<
		UploadsModel,
		AddUpload,
		undefined,
		StoreModel,
		Promise<Upload>
	>;
	updateUpload: Thunk<
		UploadsModel,
		string,
		undefined,
		StoreModel,
		Promise<Upload>
	>;
	removeUploads: Thunk<UploadsModel, RemoveOptions>;
	removeUpload: Thunk<UploadsModel, string>;
}

const addUpload = async ({ base64, filename }: AddUpload): Promise<Upload> => {
	try {
		const response = await axiosService.post(
			'/api/uploads',
			{ base64, filename },
			{
				headers: {
					authorization: localStorage.getItem('JWT'),
				},
				params: {
					session: globals.session,
				},
			},
		);
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const getUploads = async (): Promise<Upload[]> => {
	try {
		const response = await axiosService.get('/api/uploads', {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
			params: {
				session: globals.session,
			},
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const removeUploads = async (removeSession?: boolean): Promise<void> => {
	try {
		await axiosService.delete('/api/uploads', {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
			params: {
				session: removeSession ? globals.session : undefined,
			},
		});
	} catch (err) {
		throw err;
	}
};

const removeUpload = async (uploadId: string): Promise<void> => {
	try {
		await axiosService.delete(`/api/uploads/${uploadId}`, {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
	} catch (err) {
		throw err;
	}
};

const updateUpload = async (uploadId: string): Promise<Upload> => {
	try {
		const response = await axiosService.patch(
			`/api/uploads/${uploadId}/status`,
			{},
			{
				headers: {
					authorization: localStorage.getItem('JWT'),
				},
			},
		);
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const uploads: UploadsModel = {
	files: [],
	setFiles: action((state, value) => {
		state.files = value;
	}),
	selected: [],
	setSelected: action((state, value) => {
		state.selected = value;
	}),
	loading: false,
	setLoading: action((state, value) => {
		state.loading = value;
	}),
	uploads: [],
	setUploads: action((state, value) => {
		state.uploads = value;
	}),
	getUploads: thunk(async (actions) => {
		try {
			const uploads = await getUploads();
			actions.setUploads(uploads);
			return uploads;
		} catch (err) {
			throw err;
		}
	}),
	addUpload: thunk(
		async (_actions, value): Promise<Upload> => {
			try {
				const upload = await addUpload(value);
				return upload;
			} catch (err) {
				throw err;
			}
		},
	),
	updateUpload: thunk(async (_actions, value) => {
		try {
			const upload = await updateUpload(value);
			return upload;
		} catch (err) {
			throw err;
		}
	}),
	removeUploads: thunk(async (_actions, value) => {
		try {
			await removeUploads(value.removeSession);
		} catch (err) {
			throw err;
		}
	}),
	removeUpload: thunk(async (_actions, value) => {
		try {
			await removeUpload(value);
		} catch (err) {
			throw err;
		}
	}),
};

export default uploads;
