export const validateNumber = {
	validate: (value: string): boolean => !!Number(value) || value === '',
};

export const validateTime = {
	validate: (value: string): boolean => {
		if (Number(value) || value === '') {
			return true;
		}
		const [min, sec] = value.split(':');
		if (Number(min) && (Number(sec) || Number(sec) === 0)) {
			return true;
		}
		return false;
	},
};

export const basicOperators = [
	'less than',
	'greater than',
	'equal to',
	'between',
];

export const advancedOperators = ['has data', 'no data'];

export const basicDateOperators = ['before', 'after', 'on', 'between'];
