import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';

import Title from '../Title';
import Loading from '../Loading';
import { useStyles } from './styles';
import YearlyStats from './YearlyStats';
import AllTimeStats from './AllTimeStats';
import useWindowDimensions from '../../utils/windowSize';
import { useStoreActions, useStoreState } from '../../store';

const Statistics: React.FC = () => {
	const classes = useStyles();

	const { height } = useWindowDimensions();

	const theme = useStoreState((state) => state.settings.theme);
	const activities = useStoreState((state) => state.activities.activities);
	const shoes = useStoreState((state) => state.shoes.shoes);
	const getShoes = useStoreActions((actions) => actions.shoes.getShoes);
	const getActivities = useStoreActions(
		(actions) => actions.activities.getActivities,
	);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			if (!activities.length) {
				await getActivities();
			}
			if (!shoes.length) {
				await getShoes();
			}
			setLoading(false);
		};
		fetchData();
	}, [getActivities, getShoes]);

	return (
		<div className={classes.root}>
			{loading ? (
				<Loading />
			) : (
				<div
					className={classes.container}
					style={{
						maxHeight: height - 60 - theme.spacing(3),
						overflow: 'auto',
					}}
				>
					<Title title="Statistics" />
					<div className={classes.container}>
						<Paper className={classes.paper}>
							<AllTimeStats />
						</Paper>
						{!!activities.length && (
							<Paper className={classes.paper}>
								<YearlyStats />
							</Paper>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default Statistics;
