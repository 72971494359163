import { Filter } from '../../models/activities';

export const filter = <T>(array: T[], filters: Filter[]): T[] => {
	let filtered: T[] = array;
	filters.forEach((filter) => {
		const newFiltered = filtered.filter((element) => {
			let value: T[keyof T] | Date = element[filter.type as keyof T];
			if (
				typeof value === 'string' ||
				typeof value === 'number' ||
				typeof value === 'undefined' ||
				typeof value === 'boolean'
			) {
				if (filter.value && filter.operator === 'includes') {
					return String(value)
						.toString()
						.toUpperCase()
						.includes(filter.value.toString().toUpperCase());
				}
				if (filter.value instanceof Date) {
					value = new Date(String(value));
					value.setHours(0, 0, 0, 0);
					filter.value.setHours(0, 0, 0, 0);
				}
				if (['less than', 'before'].includes(filter.operator)) {
					return Number(value) < Number(filter.value);
				}
				if (['greater than', 'after'].includes(filter.operator)) {
					return Number(value) > Number(filter.value);
				}
				if (['equal to', 'on'].includes(filter.operator)) {
					return Number(value) === Number(filter.value);
				}
				if (filter.operator === 'between') {
					return (
						Number(value) >= Number(filter.value) &&
						Number(value) <= Number(filter.secondary)
					);
				}
				if (filter.operator === 'has data') {
					return (
						typeof value !== 'undefined' &&
						!(typeof value === 'boolean' && !value)
					);
				}
				if (filter.operator === 'no data') {
					return (
						typeof value === 'undefined' ||
						(typeof value === 'boolean' && !value)
					);
				}
			} else if (Array.isArray(value)) {
				const valueAsArray = value as unknown[];
				let mappedArray = valueAsArray.slice(0);
				if (filter.nesting) {
					const key = filter.nesting;
					mappedArray = valueAsArray.map(
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						(item) => (item as any)[key],
					);
				}
				const filterArray = filter.value as unknown[];
				if (filter.operator === 'at least one') {
					return !!mappedArray.length;
				}
				if (filter.operator === 'none') {
					return !mappedArray.length;
				}
				if (filter.operator === 'has any of') {
					return filterArray.some((item) =>
						mappedArray.includes(item),
					);
				}
				if (filter.operator === 'has all of') {
					return (
						filterArray.filter((item) => mappedArray.includes(item))
							.length === filterArray.length
					);
				}
				if (filter.operator === 'has none of') {
					return (
						filterArray.filter((item) => mappedArray.includes(item))
							.length === 0
					);
				}
			}
		});
		filtered = newFiltered;
	});
	return filtered;
};

export const getSelected = (id: string, selected: string[]): string[] => {
	const selectedIndex = selected.indexOf(id);
	let newSelected: string[] = [];

	if (selectedIndex === -1) {
		newSelected = newSelected.concat(selected, id);
	} else if (selectedIndex === 0) {
		newSelected = newSelected.concat(selected.slice(1));
	} else if (selectedIndex === selected.length - 1) {
		newSelected = newSelected.concat(selected.slice(0, -1));
	} else if (selectedIndex > 0) {
		newSelected = newSelected.concat(
			selected.slice(0, selectedIndex),
			selected.slice(selectedIndex + 1),
		);
	}

	return newSelected;
};
