import React from 'react';

import { useStoreActions, useStoreState } from '../../../store';
import { ActivityDetails } from '../../../models/activity';
import { getSelected } from '../utils';
import PTable, { Props } from './Table';

const Table: React.FC = () => {
	const removeId = useStoreState((state) => state.activities.removeId);
	const filteredIds = useStoreState((state) => state.activities.filteredIds);
	const selected = useStoreState((state) => state.activity.selected);
	const setSelected = useStoreActions(
		(actions) => actions.activity.setSelected,
	);
	const order = useStoreState((state) => state.activities.order);
	const orderBy = useStoreState((state) => state.activities.orderBy);
	const setOrder = useStoreActions((actions) => actions.activities.setOrder);
	const setOrderBy = useStoreActions(
		(actions) => actions.activities.setOrderBy,
	);
	const setRemoveOne = useStoreActions(
		(actions) => actions.activities.setRemoveOne,
	);
	const getActivities = useStoreActions(
		(actions) => actions.activities.getActivities,
	);
	const removeActivity = useStoreActions(
		(actions) => actions.activity.removeActivity,
	);
	const rowsPerPage = useStoreState((state) => state.activities.rowsPerPage);
	const setPage = useStoreActions((actions) => actions.activities.setPage);
	const setRowsPerPage = useStoreActions(
		(actions) => actions.activities.setRowsPerPage,
	);

	const handleChangePage = (_event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const checkPage = (length: number) => {
		setPage(Math.ceil(length / rowsPerPage) - 1);
	};

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	const handleRequestSort = (
		_event: React.MouseEvent<unknown>,
		property: keyof ActivityDetails,
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const createSortHandler = (property: keyof ActivityDetails) => (
		event: React.MouseEvent<unknown>,
	) => {
		handleRequestSort(event, property);
	};

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelected(filteredIds);
			return;
		}
		setSelected([]);
	};

	const handleCheckbox = (id: string) => {
		const newSelected = getSelected(id, selected);
		setSelected(newSelected);
	};

	const handleRemoveOne = async () => {
		try {
			if (removeId) {
				await removeActivity(removeId);
				const activities = await getActivities();
				if (isSelected(removeId)) {
					handleCheckbox(removeId);
				}
				setRemoveOne();
				checkPage(activities.length);
			}
		} catch (err) {}
	};

	const props: Props = {
		handleChangePage,
		handleChangeRowsPerPage,
		createSortHandler,
		isSelected,
		handleSelectAll,
		handleCheckbox,
		handleRemoveOne,
	};

	return <PTable {...props} />;
};

export default Table;
