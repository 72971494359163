import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';

import Table from './Table';
import Title from '../Title';
import Loading from '../Loading';
import Dropzone from './Dropzone';
import Selection from './Selection';
import { useStyles } from './styles';
import useWindowDimensions from '../../utils/windowSize';
import { useStoreActions, useStoreState } from '../../store';

const Import: React.FC = () => {
	const classes = useStyles();
	const { height } = useWindowDimensions();

	const theme = useStoreState((state) => state.settings.theme);
	const setFiles = useStoreActions((actions) => actions.uploads.setFiles);
	const setSelected = useStoreActions(
		(actions) => actions.uploads.setSelected,
	);
	const setUploads = useStoreActions((actions) => actions.uploads.setUploads);
	const removeUploads = useStoreActions(
		(actions) => actions.uploads.removeUploads,
	);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const startup = async () => {
			await removeUploads({ removeSession: true });
			setUploads([]);
			setFiles([]);
			setSelected([]);
			setLoading(false);
		};
		startup();
	}, [setUploads, removeUploads, setFiles, setSelected]);

	return (
		<div className={classes.root}>
			{loading ? (
				<Loading />
			) : (
				<div
					className={classes.container}
					style={{
						maxHeight: height - 60 - theme.spacing(3),
						overflow: 'auto',
					}}
				>
					<Title title="Import Data" />
					<Paper className={classes.paper}>
						<Dropzone />
					</Paper>
					<Paper className={classes.paper}>
						<Table />
						<Selection />
					</Paper>
				</div>
			)}
		</div>
	);
};

export default Import;
