import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	logoButton: {
		marginRight: theme.spacing(),
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	tuff: {
		fontWeight: 500,
		fontSize: 48,
		color: theme.palette.primary.main,
		paddingRight: theme.spacing(),
	},
	trainer: {
		fontWeight: 500,
		fontSize: 48,
	},
}));
