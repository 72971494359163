import { Filter } from '../../../models/activities';
import { FilterData, FiltersData } from '../interfaces';
import {
	advancedOperators,
	basicDateOperators,
	basicOperators,
} from './Filter/utils';
import {
	advancedArrayOperators,
	basicArrayOperators,
} from './ArrayFilter/utils';

export const buildFilters = (
	data: FiltersData,
	date: Date | null,
	dateSecondary: Date | null,
	selectedTags: string[],
	selectedShoes: string[],
): Filter[] => {
	const newFilters: Filter[] = [];

	if (data.name) {
		newFilters.push({
			type: 'name',
			operator: 'includes',
			value: data.name,
		});
	}

	if (data.distance && data.distanceOperator && data.distanceUnit) {
		if (data.distanceOperator === 'between' && !data.distanceSecondary) {
		} else {
			let formattedValue = Number(data.distance) * 1000;
			if (data.distanceUnit === 'mi') {
				formattedValue = formattedValue * 1.609;
			}
			let formattedSecondary = Number(data.distanceSecondary) * 1000;
			if (data.distanceSecondary && data.distanceUnit === 'mi') {
				formattedSecondary = formattedSecondary * 1.609;
			}

			newFilters.push({
				type: 'distance',
				value: formattedValue,
				operator: data.distanceOperator,
				unit: data.distanceUnit,
				secondary: formattedSecondary,
			});
		}
	}

	if (data.time && data.timeOperator && data.timeUnit) {
		if (data.timeOperator === 'between' && !data.timeSecondary) {
		} else {
			let formattedValue = Number(data.time) * 60;
			if (data.timeUnit === 'hours') {
				formattedValue = formattedValue * 60;
			}
			let formattedSecondary = Number(data.timeSecondary) * 60;
			if (data.timeSecondary && data.timeUnit === 'hours') {
				formattedSecondary = formattedSecondary * 60;
			}

			newFilters.push({
				type: 'time',
				value: formattedValue,
				operator: data.timeOperator,
				unit: data.timeUnit,
				secondary: formattedSecondary,
			});
		}
	}

	if (data.pace && data.paceOperator && data.paceUnit) {
		if (data.paceOperator === 'between' && !data.paceSecondary) {
		} else {
			let formattedValue = 0;
			if (Number(data.pace)) {
				formattedValue = Number(data.pace) * 60;
			} else {
				const [min, sec] = data.pace.split(':');
				formattedValue = Number(min) * 60 + Number(sec);
			}
			let formattedSecondary = 0;
			if (data.paceSecondary) {
				if (Number(data.paceSecondary)) {
					formattedSecondary = Number(data.paceSecondary) * 60;
				} else {
					const [min, sec] = (data.paceSecondary as string).split(
						':',
					);
					formattedSecondary = Number(min) * 60 + Number(sec);
				}
				if (data.paceUnit === 'min /mi') {
					formattedValue = formattedValue * 1.609;
					formattedSecondary = formattedSecondary * 1.609;
				}
			}

			newFilters.push({
				type: 'avgPace',
				value: formattedValue,
				operator: data.paceOperator,
				unit: data.paceUnit,
				secondary: formattedSecondary,
			});
		}
	}

	if (date && data.dateOperator) {
		if (data.dateOperator === 'between' && !dateSecondary) {
		} else {
			newFilters.push({
				type: 'date',
				value: date,
				operator: data.dateOperator,
				secondary: dateSecondary ? dateSecondary : undefined,
			});
		}
	}

	if (
		(data.heartRate && data.heartRateOperator) ||
		(data.heartRateOperator &&
			advancedOperators.includes(data.heartRateOperator))
	) {
		if (data.heartRateOperator === 'between' && !data.heartRateSecondary) {
		} else {
			const formattedValue = Number(data.heartRate);
			const formattedSecondary = Number(data.heartRateSecondary);

			newFilters.push({
				type: 'avgHeartRate',
				value: formattedValue,
				operator: data.heartRateOperator,
				secondary: formattedSecondary,
			});
		}
	}

	if (
		(data.elevation && data.elevationOperator && data.elevationUnit) ||
		(data.elevationOperator &&
			advancedOperators.includes(data.elevationOperator))
	) {
		if (data.elevationOperator === 'between' && !data.elevationSecondary) {
		} else {
			let formattedValue = Number(data.elevation);
			if (data.elevationUnit === 'feet') {
				formattedValue = formattedValue * 0.3048;
			}
			let formattedSecondary = Number(data.elevationSecondary);
			if (data.distanceSecondary && data.elevationUnit === 'feet') {
				formattedSecondary = formattedSecondary * 0.3048;
			}

			newFilters.push({
				type: 'ascent',
				value: formattedValue,
				operator: data.elevationOperator,
				unit: data.elevationUnit,
				secondary: formattedSecondary,
			});
		}
	}

	if (data.tagOperator) {
		if (basicArrayOperators.includes(data.tagOperator)) {
			newFilters.push({
				type: 'tags',
				operator: data.tagOperator,
			});
		} else if (selectedTags.length) {
			newFilters.push({
				type: 'tags',
				value: selectedTags,
				operator: data.tagOperator,
			});
		}
	}

	if (data.shoeOperator) {
		if (basicArrayOperators.includes(data.shoeOperator)) {
			newFilters.push({
				type: 'shoes',
				operator: data.shoeOperator,
			});
		} else if (selectedShoes.length) {
			newFilters.push({
				type: 'shoes',
				value: selectedShoes,
				operator: data.shoeOperator,
				nesting: 'name',
			});
		}
	}

	if (data.gpsOperator) {
		newFilters.push({
			type: 'hasGps',
			operator: data.gpsOperator,
		});
	}

	return newFilters;
};

export const distanceFilterReset: FiltersData = {
	distance: '',
	distanceOperator: '',
	distanceUnit: '',
	distanceSecondary: '',
};

export const distanceFilter: FilterData = {
	label: 'Distance',
	type: 'distance',
	operator: 'distanceOperator',
	unit: 'distanceUnit',
	secondary: 'distanceSecondary',
	operators: [...basicOperators],
	units: ['km', 'mi'],
	dataType: 'number',
	dataName: 'distance',
};

export const timeFilterReset: FiltersData = {
	time: '',
	timeOperator: '',
	timeUnit: '',
	timeSecondary: '',
};

export const timeFilter: FilterData = {
	label: 'Time',
	type: 'time',
	operator: 'timeOperator',
	unit: 'timeUnit',
	secondary: 'timeSecondary',
	operators: [...basicOperators],
	units: ['minutes', 'hours'],
	dataType: 'number',
	dataName: 'time',
};

export const paceFilterReset: FiltersData = {
	pace: '',
	paceOperator: '',
	paceUnit: '',
	paceSecondary: '',
};

export const paceFilter: FilterData = {
	label: 'Pace',
	type: 'pace',
	operator: 'paceOperator',
	unit: 'paceUnit',
	secondary: 'paceSecondary',
	operators: [...basicOperators],
	units: ['min /km', 'min /mi'],
	dataType: 'time',
	dataName: 'avgPace',
};

export const dateFilterReset: FiltersData = {
	dateOperator: '',
};

export const dateFilter: FilterData = {
	label: 'Date',
	operator: 'dateOperator',
	operators: [...basicDateOperators],
	units: [],
	dataType: 'date',
	dataName: 'date',
};

export const heartRateFilterReset: FiltersData = {
	heartRate: '',
	heartRateOperator: '',
	heartRateSecondary: '',
};

export const heartRateFilter: FilterData = {
	label: 'Heart Rate',
	type: 'heartRate',
	operator: 'heartRateOperator',
	secondary: 'heartRateSecondary',
	operators: [...basicOperators, ...advancedOperators],
	units: ['bpm'],
	dataType: 'number',
	dataName: 'avgHeartRate',
};

export const nameFilterReset: FiltersData = {
	name: '',
};

export const elevationFilterReset: FiltersData = {
	elevation: '',
	elevationOperator: '',
	elevationUnit: '',
	elevationSecondary: '',
};

export const elevationFilter: FilterData = {
	label: 'Elevation',
	type: 'elevation',
	operator: 'elevationOperator',
	unit: 'elevationUnit',
	secondary: 'elevationSecondary',
	operators: [...basicOperators, ...advancedOperators],
	units: ['metres', 'feet'],
	dataType: 'number',
	dataName: 'ascent',
};

export const tagFilterReset: FiltersData = {
	tagOperator: '',
};

export const tagFilter: FilterData = {
	label: 'Tags',
	operator: 'tagOperator',
	operators: [...basicArrayOperators, ...advancedArrayOperators],
	units: [],
	dataType: 'string',
	dataName: 'tags',
};

export const shoeFilterReset: FiltersData = {
	shoeOperator: '',
};

export const shoeFilter: FilterData = {
	label: 'Shoes',
	operator: 'shoeOperator',
	operators: [...basicArrayOperators, ...advancedArrayOperators],
	units: [],
	dataType: 'string',
	dataName: 'shoes',
};

export const gpsFilterReset: FiltersData = {
	gpsOperator: '',
};

export const gpsFilter: FilterData = {
	label: 'GPS',
	operator: 'gpsOperator',
	operators: [...advancedOperators],
	units: [],
	dataType: 'boolean',
	dataName: 'hasGps',
};

export const resetAllFilters: FiltersData = {
	...distanceFilterReset,
	...nameFilterReset,
	...timeFilterReset,
	...paceFilterReset,
	...dateFilterReset,
	...heartRateFilterReset,
	...elevationFilterReset,
	...tagFilterReset,
	...shoeFilterReset,
	...gpsFilterReset,
};
