import {
	ForgottenFormData,
	RegisterFormData,
	SignInFormData,
} from './interfaces';
import emailValidator from 'email-validator';
import { axiosService } from '../../../services/axios';

export interface Authenticated {
	token: string;
	user: {
		id: string;
		name: string;
		sex: string;
		age: number;
		email: string;
		joined: string;
	};
}

export const loginUser = async (
	data: SignInFormData,
): Promise<Authenticated> => {
	try {
		const response = await axiosService.post(
			'/api/user/authenticate',
			data,
		);
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

export const registerUser = async ({
	name,
	sex,
	age,
	height,
	weight,
	email,
	password,
}: RegisterFormData): Promise<Authenticated> => {
	try {
		const response = await axiosService.post('/api/user/register', {
			name,
			sex,
			age: Number(age),
			height: Number(height),
			weight: Number(weight),
			email,
			password,
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

export const forgotPassword = async (
	data: ForgottenFormData,
): Promise<void> => {
	try {
		const response = await axiosService.post(
			'/api/user/password/forgot',
			data,
		);
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

export const validateEmail = (email: string): boolean => {
	if (!emailValidator.validate(email)) {
		return false;
	}
	return true;
};
