import React from 'react';
import {
	Divider,
	Table,
	TableContainer,
	Typography,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Checkbox,
	IconButton,
	Button,
	TextField,
	TablePagination,
	Paper,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import HelpIcon from '@material-ui/icons/Help';

import Dialog from '../../Dialog';
import Tooltip from '../../Tooltip';
import { useStyles } from './styles';
import { TagData } from './interfaces';
import Alert from '@material-ui/lab/Alert';
import { UseFormMethods } from 'react-hook-form';
import { useStoreState, useStoreActions } from '../../../store';

export interface Props
	extends Pick<
		UseFormMethods,
		'handleSubmit' | 'register' | 'watch' | 'errors' | 'reset'
	> {
	page: number;
	error: number;
	rowsPerPage: number;
	isSelected: (id: string) => boolean;
	handleCheckbox: (id: string) => void;
	handleRemoveOne: () => Promise<void>;
	handleRemoveSelected: () => Promise<void>;
	handleFormSubmit: (data: TagData) => Promise<void>;
	setError: React.Dispatch<React.SetStateAction<number>>;
	handleChangePage: (_event: unknown, newPage: number) => void;
	handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleChangeRowsPerPage: (
		event: React.ChangeEvent<HTMLInputElement>,
	) => void;
}

const Tags: React.FC<Props> = ({
	handleSubmit,
	register,
	watch,
	reset,
	errors,
	setError,
	isSelected,
	handleCheckbox,
	handleSelectAll,
	handleRemoveOne,
	handleChangePage,
	handleFormSubmit,
	handleRemoveSelected,
	handleChangeRowsPerPage,
	page,
	error,
	rowsPerPage,
}) => {
	const classes = useStyles();

	const tags = useStoreState((state) => state.tags.tags);
	const theme = useStoreState((state) => state.settings.theme);
	const removeOne = useStoreState((state) => state.tags.removeOne);
	const removeSelected = useStoreState((state) => state.tags.removeSelected);
	const selectedTags = useStoreState((state) => state.settings.selectedTags);
	const setRemoveId = useStoreActions((actions) => actions.tags.setRemoveId);
	const setRemoveOne = useStoreActions(
		(actions) => actions.tags.setRemoveOne,
	);
	const setRemoveSelected = useStoreActions(
		(actions) => actions.tags.setRemoveSelected,
	);

	return (
		<div className={classes.root}>
			<div className={classes.headerContainer}>
				<Typography variant="h5" className={classes.header}>
					Tags
				</Typography>
				<Tooltip title="Add a new tag to classify runs and make searching easier.">
					<IconButton
						className={classes.iconButton}
						disableRipple={true}
						style={{ padding: 0, cursor: 'default' }}
					>
						<HelpIcon className={classes.icon} />
					</IconButton>
				</Tooltip>
			</div>
			<Divider />
			<TableContainer
				className={classes.table}
				component={Paper}
				variant="outlined"
				style={{ borderWidth: 0 }}
			>
				<Table stickyHeader size="small">
					<TableHead>
						<TableRow>
							<TableCell
								width="10%"
								classes={{ head: classes.tableHeader }}
							>
								<Checkbox
									color="primary"
									checked={
										tags.length > 0 &&
										selectedTags.length === tags.length
									}
									classes={{
										root: classes.checkbox,
									}}
									onChange={handleSelectAll}
									style={{ padding: 4 }}
								/>
							</TableCell>
							<TableCell
								width="80%"
								classes={{ head: classes.tableHeader }}
							>
								Name
							</TableCell>
							<TableCell
								width="10%"
								align="right"
								classes={{ head: classes.tableHeader }}
							>
								Remove
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{tags
							.slice(
								page * rowsPerPage,
								page * rowsPerPage + rowsPerPage,
							)
							.map((tag) => {
								const { name, id } = tag;
								const isItemSelected = isSelected(id);
								return (
									<TableRow
										key={id}
										hover
										selected={isItemSelected}
										className={classes.row}
										classes={{ selected: classes.selected }}
									>
										<TableCell>
											<Checkbox
												color="primary"
												onClick={() =>
													handleCheckbox(id)
												}
												checked={isItemSelected}
												style={{ padding: 4 }}
											/>
										</TableCell>
										<TableCell>{name}</TableCell>
										<TableCell align="right">
											<IconButton
												className={classes.iconButton}
												onClick={() => {
													setRemoveId(id);
													setRemoveOne();
												}}
											>
												<DeleteIcon
													className={classes.icon}
												></DeleteIcon>
											</IconButton>
										</TableCell>
									</TableRow>
								);
							})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[5, 10, 25, 50]}
				component="div"
				count={tags.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
			/>
			<div className={classes.selection}>
				<Tooltip
					title="Remove selected tags"
					style={{ marginTop: theme.spacing() }}
				>
					<span>
						<Button
							className={classes.button}
							disabled={!selectedTags.length}
							onClick={() => setRemoveSelected()}
						>
							Remove
						</Button>
					</span>
				</Tooltip>
				<form
					style={{
						marginLeft: 'auto',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<TextField
						name="name"
						autoComplete="off"
						className={classes.input}
						inputRef={register({
							required: {
								value: true,
								message: 'required',
							},
							minLength: {
								value: 1,
								message: 'minimum 1 character',
							},
							maxLength: {
								value: 50,
								message: 'maximum 50 characters',
							},
						})}
						error={!!errors['name']}
						placeholder="Tag name"
						size="small"
						variant="outlined"
						style={{
							width: 200,
							marginTop: theme.spacing(),
						}}
						InputProps={{ style: { height: 40 } }}
					/>
					<Button
						type="submit"
						className={classes.button}
						disabled={!watch('name')}
						onClick={handleSubmit(handleFormSubmit)}
						style={{
							marginRight: theme.spacing(),
							marginTop: theme.spacing(),
						}}
					>
						Add
					</Button>
					<Button
						className={classes.secondaryButton}
						disabled={!watch('name')}
						onClick={() => {
							reset({ name: '' });
							setError(0);
						}}
						style={{
							marginTop: theme.spacing(),
							marginRight: error === 409 ? theme.spacing() : 0,
						}}
					>
						Cancel
					</Button>
				</form>
				{error === 409 && (
					<Alert
						severity="error"
						variant="filled"
						className={classes.alert}
					>
						Tag already exists
					</Alert>
				)}
			</div>
			<Dialog
				open={removeOne}
				handleDialogConfirmClick={handleRemoveOne}
				handleDialogClick={() => setRemoveOne()}
				title="Remove Tag"
				content="Are you sure you want to remove this tag?"
				confirm="Remove"
			/>
			<Dialog
				open={removeSelected}
				handleDialogConfirmClick={handleRemoveSelected}
				handleDialogClick={() => setRemoveSelected()}
				title="Remove selected Tags"
				content="Are you sure you want to remove the selected tags?"
				confirm="Remove"
			/>
		</div>
	);
};

export default Tags;
