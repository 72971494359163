import makeStyles from '@material-ui/core/styles/makeStyles';
import globals from '../../utils/globals';

export const useStyles = makeStyles({
	root: {
		flexGrow: 1,
	},
	content: {
		maxWidth: globals.maxWidth,
		height: 'calc(100vh - 60px)',
		margin: 'auto',
	},
});
