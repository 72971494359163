import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	divider: {
		marginTop: 8,
		marginBottom: 8,
	},
	headerBlock: {
		margin: theme.spacing(),
	},
	headerDetailsContainer: {
		margin: theme.spacing(),
	},
	headerDetails: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: '100%',
		flexWrap: 'wrap',
	},
	descriptionInput: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(),
		width: '50%',
	},
	button: {
		backgroundColor: theme.palette.primary.light,
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		minWidth: 100,
		color: theme.palette.defaultBlack,
		marginLeft: theme.spacing(),
		marginTop: theme.spacing(),
	},
	secondaryButton: {
		minWidth: 100,
		backgroundColor: theme.palette.grey[300],
		'&:hover': {
			backgroundColor: theme.palette.grey.A100,
		},
		color: theme.palette.defaultBlack,
		marginLeft: theme.spacing(),
		marginTop: theme.spacing(),
	},
	description: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'flex-end',
	},
}));
