import { ActivityDetails } from '../../../models/activity';
import { Shoe } from '../../../models/shoes';

export const getDistance = (activities: ActivityDetails[]): number => {
	return activities.reduce((acc, curr) => {
		return acc + curr.distance;
	}, 0);
};

export const getTime = (activities: ActivityDetails[]): number => {
	return activities.reduce((acc, curr) => {
		return acc + curr.time;
	}, 0);
};

export const getElevation = (activities: ActivityDetails[]): number => {
	return activities.reduce((acc, curr) => {
		return acc + (Number(curr.ascent) || 0);
	}, 0);
};

export const getCalories = (activities: ActivityDetails[]): number => {
	return activities.reduce((acc, curr) => {
		return acc + curr.calories;
	}, 0);
};

export const formatShoes = (shoes: Shoe[]): Shoe[] => {
	return shoes
		.sort((a: Shoe, b: Shoe) => b.distance - a.distance)
		.filter((shoe) => !!shoe.distance);
};
