import React from 'react';
import Button from '@material-ui/core/Button';

import Loading from '../../Loading';
import { useStyles } from './styles';
import { useStoreState } from '../../../store';
import Tooltip from '../../Tooltip';

export interface Props {
	selected: string[];
	handleImportSelected: () => Promise<void>;
	handleDeleteSelected: () => Promise<void>;
}

const Selection: React.FC<Props> = ({
	selected,
	handleImportSelected,
	handleDeleteSelected,
}) => {
	const classes = useStyles();

	const loading = useStoreState((state) => state.uploads.loading);

	return (
		<div id="import-selection" className={classes.root}>
			<Tooltip title="Import selected files">
				<span>
					<Button
						className={classes.uploadButton}
						disabled={!selected.length}
						onClick={handleImportSelected}
					>
						Import
					</Button>
				</span>
			</Tooltip>
			<Tooltip title="Remove selected files">
				<span>
					<Button
						className={classes.uploadButton}
						disabled={!selected.length}
						onClick={handleDeleteSelected}
					>
						Remove
					</Button>
				</span>
			</Tooltip>
			{loading && (
				<div style={{ marginLeft: 'auto' }}>
					<Loading size={32} thickness={4} />
				</div>
			)}
		</div>
	);
};

export default Selection;
