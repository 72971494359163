import React, { useEffect, useRef, useState } from 'react';

import PMap, { Props } from './Map';
import Loading from '../../Loading';
import { useStyles } from './styles';
import { centreViewport } from './utils';
import { PositionStream } from '../../../models/activity';
import useWindowDimensions from '../../../utils/windowSize';
import { useStoreActions, useStoreState } from '../../../store';
import { ViewportProps } from 'react-map-gl';
import { useMediaQuery } from '@material-ui/core';

const Map: React.FC = () => {
	const classes = useStyles();

	const { width, height } = useWindowDimensions();

	const theme = useStoreState((state) => state.settings.theme);
	const themeType = useStoreState((state) => state.settings.themeType);
	const activity = useStoreState((state) => state.activity.activity);
	const setMapPosition = useStoreActions(
		(actions) => actions.activity.setMapPosition,
	);

	const containerRef = useRef<HTMLDivElement | null>(null);
	const [refVisible, setRefVisible] = useState(false);
	const [loading, setLoading] = useState(true);
	const [coordinates, setCoordinates] = useState<PositionStream[]>([]);
	const [viewport, setViewPort] = useState<ViewportProps>(
		{} as ViewportProps,
	);

	const isTablet = useMediaQuery(theme.breakpoints.down('sm'));

	const map = {
		mapboxApiAccessToken:
			'pk.eyJ1Ijoia29tb2pvIiwiYSI6ImNraDk5c3V0cDAxZ24zMW0zbnYxZGVxczEifQ.ejlGe8YywupRoxONCoeBFQ',
		mapStyle: `mapbox://styles/mapbox/${
			themeType === 'light' ? 'outdoors-v11' : 'dark-v10'
		}`,
	};

	const handleViewportChange = (viewport: ViewportProps) =>
		setViewPort({
			...viewport,
		});

	const handleResize = ({
		width,
		height,
	}: {
		width: number;
		height: number;
	}) => {
		if (viewport) {
			setViewPort({
				...viewport,
				width,
				height,
			});
		}
	};

	useEffect(() => {
		if (activity) {
			const positionalData = activity.streams.map((dataEntry) => {
				return {
					time: dataEntry.time,
					latitude: dataEntry.latitude,
					longitude: dataEntry.longitude,
				};
			});
			setMapPosition(0);
			setCoordinates(positionalData);
			const { latitude, longitude, zoom } = centreViewport(
				positionalData,
				350,
				350,
			);
			setViewPort(({
				width: '100%',
				height: '100%',
				zoom,
				latitude,
				longitude,
			} as unknown) as ViewportProps);
			setLoading(false);
		}
	}, [activity, setMapPosition]);

	useEffect(() => {
		setViewPort((prev) =>
			prev
				? (({
						...prev,
						width: '100%',
						height: '100%',
				  } as unknown) as ViewportProps)
				: prev,
		);
	}, [width]);

	useEffect(() => {
		if (!refVisible) {
			return;
		}

		if (containerRef.current) {
			const { zoom } = centreViewport(
				coordinates,
				containerRef.current.offsetWidth,
				containerRef.current.offsetHeight,
			);
			setViewPort((prev) =>
				prev
					? {
							...prev,
							zoom,
					  }
					: prev,
			);
		}
	}, [width, height, refVisible, coordinates]);

	const props: Props = {
		coordinates,
		viewport,
		map,
		handleViewportChange,
		handleResize,
	};

	return (
		<div
			className={classes.root}
			ref={(el) => {
				containerRef.current = el;
				setRefVisible(!!el);
			}}
			style={{
				width: isTablet
					? `calc(100% - ${theme.spacing(2)}px)`
					: `calc(50% - ${theme.spacing(1.5)}px)`,
			}}
		>
			{loading ? <Loading /> : <PMap {...props} />}
		</div>
	);
};

export default Map;
