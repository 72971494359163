import { MealFood } from './interfaces';

export const units = ['g', 'ml'];

export const steps = ['Type', 'Create', 'Confirm'];

export const formatMealNumber = (number: number): number =>
	Math.round(number * 10) / 10;

export const getMealTotal = (
	mealFood: MealFood[],
	field: 'calories' | 'carbs' | 'fat' | 'protein',
): number => {
	return formatMealNumber(
		mealFood.reduce((acc, curr) => {
			if (curr.unit === 'quantity') {
				return (
					acc +
					formatMealNumber(curr.food[field]) * Number(curr.value)
				);
			}
			return (
				acc +
				formatMealNumber(curr.food[field]) *
					(Number(curr.value) / Number(curr.food.amount))
			);
		}, 0),
	);
};
