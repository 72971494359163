import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';

import Days from './Days';
import Title from '../Title';
import Error from '../Error';
import Header from './Header';
import Loading from '../Loading';
import { getDays } from './utils';
import { useStyles } from './styles';
import useWindowDimensions from '../../utils/windowSize';
import { useStoreState, useStoreActions } from '../../store';

const TrainingLog: React.FC = () => {
	const classes = useStyles();

	const { height } = useWindowDimensions();

	const theme = useStoreState((state) => state.settings.theme);
	const week = useStoreState((state) => state.log.week);
	const date = useStoreState((state) => state.log.date);
	const allEntries = useStoreState((state) => state.foods.allEntries);
	const healthStats = useStoreState((state) => state.health.healthStats);
	const activities = useStoreState((state) => state.activities.activities);
	const reset = useStoreState((state) => state.foods.reset);
	const setDays = useStoreActions((actions) => actions.log.setDays);
	const setReset = useStoreActions((actions) => actions.foods.setReset);
	const getActivities = useStoreActions(
		(actions) => actions.activities.getActivities,
	);
	const getAllEntries = useStoreActions(
		(actions) => actions.foods.getAllEntries,
	);
	const getHealthStats = useStoreActions(
		(actions) => actions.health.getHealthStats,
	);
	const redirectError = useStoreState((state) => state.log.redirectError);
	const setRedirectError = useStoreActions(
		(actions) => actions.log.setRedirectError,
	);

	const [loading, setLoading] = useState(true);

	const handleClose = () => {
		setRedirectError(false);
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (!activities.length) {
					await getActivities();
				}
				if (!allEntries.length || reset) {
					setReset(false);
					await getAllEntries();
				}
				if (!healthStats.length) {
					await getHealthStats();
				}
				setLoading(false);
			} catch (err) {}
		};
		fetchData();
	}, [getActivities, getHealthStats, getAllEntries]);

	useEffect(() => {
		setDays(getDays(date, week));
	}, [date, setDays, week]);

	return (
		<div className={classes.root}>
			{loading ? (
				<Loading />
			) : (
				<div
					className={classes.container}
					style={{
						maxHeight: height - 60 - theme.spacing(3),
						overflow: 'auto',
					}}
				>
					<Title title="Training Log" />
					<Paper className={classes.paper}>
						<Header />
					</Paper>
					<Days />
				</div>
			)}
			{!loading && (
				<Error
					handleClose={handleClose}
					redirectError={redirectError}
					title="Day does not exist"
				/>
			)}
		</div>
	);
};

export default TrainingLog;
