import { Typography } from '@material-ui/core';
import React from 'react';

import { useStyles } from './styles';

interface Props {
	title: string;
}

const Title: React.FC<Props> = ({ title }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Typography className={classes.title}>{title}</Typography>
		</div>
	);
};

export default Title;
