import React from 'react';
import { Link } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import { Card, CardContent, IconButton, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { getDistance } from './utils';
import globals from '../../../utils/globals';
import { useStoreState } from '../../../store';
import { Tooltip, TooltipNoMargin } from '../../Tooltip';
import { ActivityDetails } from '../../../models/activity';
import { Day as DayInterface } from '../../../models/log';
import { formatDate, formatDistance } from '../../../utils/formatters';

export interface Props {
	day: DayInterface;
	loading: boolean;
	inFuture: boolean;
	activities: ActivityDetails[];
	bmr: number;
	calories: number;
	extraCalories: number;
	caloriesEaten: number;
	numberMeals: number;
	caloriePercentage: number;
}

const Day: React.FC<Props> = ({
	day,
	loading,
	inFuture,
	activities,
	bmr,
	calories,
	extraCalories,
	caloriesEaten,
	numberMeals,
	caloriePercentage,
}) => {
	const classes = useStyles();

	const theme = useStoreState((state) => state.settings.theme);
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);

	return loading ? (
		<Card
			variant="outlined"
			className={classes.day}
			style={{
				width: (globals.maxWidth - theme.spacing(8)) / 7,
				height: (globals.maxHeight - 152 - theme.spacing(10)) / 4,
				borderWidth: 0,
			}}
		/>
	) : (
		<Card
			variant="outlined"
			className={classes.day}
			style={{
				width: (globals.maxWidth - theme.spacing(8)) / 7,
				height: (globals.maxHeight - 152 - theme.spacing(10)) / 4,
				borderWidth: 0,
			}}
		>
			<CardContent style={{ padding: 8 }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-start',
						marginBottom: theme.spacing(),
					}}
				>
					<Typography
						style={{
							fontSize: 16,
							paddingBottom: theme.spacing(),
							fontWeight: day.current ? 'bold' : 'normal',
						}}
					>
						{!inFuture ? (
							<Link
								className={classes.rowLink}
								to={`/log/${formatDate(
									day.date,
									'DD-MM-YYYY',
								)}`}
							>
								{formatDate(day.date, 'ddd Do')}
							</Link>
						) : (
							formatDate(day.date, 'ddd Do')
						)}
					</Typography>
					{!inFuture &&
						caloriesEaten > 0 &&
						(caloriePercentage > 10 ? (
							<Tooltip title="High calorie intake">
								<IconButton
									className={classes.errorIconButton}
									disableRipple={true}
									style={{ marginLeft: 'auto' }}
								>
									<ErrorIcon className={classes.errorIcon} />
								</IconButton>
							</Tooltip>
						) : caloriePercentage < -10 ? (
							<Tooltip title="Low calorie intake">
								<IconButton
									className={classes.errorIconButton}
									disableRipple={true}
									style={{ marginLeft: 'auto' }}
								>
									<ErrorIcon className={classes.errorIcon} />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title="Good calorie intake">
								<IconButton
									className={classes.successIconButton}
									disableRipple={true}
									style={{ marginLeft: 'auto' }}
								>
									<CheckCircleIcon
										className={classes.successIcon}
									/>
								</IconButton>
							</Tooltip>
						))}
				</div>
				<div
					style={{
						display: 'flex',
						height: 27.42,
						justifyContent: 'space-evenly',
					}}
				>
					{!!activities.length && (
						<div style={{ display: 'flex' }}>
							<DirectionsRunIcon />
							<Typography
								style={{
									fontWeight: 600,
									fontSize: 18,
									marginLeft: 1,
								}}
							>{`x ${activities.length}`}</Typography>
						</div>
					)}
					{!!numberMeals && (
						<div style={{ display: 'flex' }}>
							<RestaurantIcon />
							<Typography
								style={{
									fontWeight: 600,
									fontSize: 18,
									marginLeft: 1,
								}}
							>{`x ${numberMeals}`}</Typography>
						</div>
					)}
				</div>
				<div
					style={{
						display: 'flex',
						height: 48,
						justifyContent: 'space-evenly',
					}}
				>
					{!!activities.length && (
						<Typography style={{ fontWeight: 400, fontSize: 32 }}>
							{formatDistance(
								getDistance(activities),
								distanceUnit,
							)}
						</Typography>
					)}
				</div>
				{!inFuture && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<TooltipNoMargin title="Calories eaten">
							<Typography
								style={{
									fontWeight: 400,
									fontSize: 22,
									marginLeft: 8,
									paddingBottom: 8,
								}}
							>
								{caloriesEaten}
							</Typography>
						</TooltipNoMargin>
						<Typography
							style={{
								fontWeight: 400,
								fontSize: 22,
								marginLeft: 8,
							}}
						>
							/
						</Typography>
						<TooltipNoMargin title="Total calories">
							<Typography
								style={{
									fontWeight: 400,
									fontSize: 22,
									marginLeft: 8,
									paddingBottom: 8,
								}}
							>
								{`${bmr + calories + extraCalories} C`}
							</Typography>
						</TooltipNoMargin>
					</div>
				)}
			</CardContent>
		</Card>
	);
};

export default Day;
