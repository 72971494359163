import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	paper: {
		borderColor: theme.palette.secondary.light,
		backgroundColor: theme.palette.secondary.light,
		marginRight: theme.spacing(2),
		marginTop: theme.spacing(2),
		borderWidth: 2,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
		alignItems: 'center',
		height: '100%',
	},
	filterItem: {
		marginLeft: theme.spacing(),
		marginRight: theme.spacing(),
		marginTop: theme.spacing() / 2,
		marginBottom: theme.spacing() / 2,
	},
	iconButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			'& $icon': {
				color: theme.palette.primary.main,
			},
		},
		padding: 0,
		marginLeft: theme.spacing(),
		marginRight: theme.spacing(),
	},
	icon: {
		color: theme.palette.common.black,
	},
	select: {
		minWidth: 75,
	},
}));
