import React, { useEffect } from 'react';
import { Switch } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { useStyles } from './styles';
import { useStoreState } from '../../../store';

export interface Props {
	handlePace: () => void;
	handleHeartRate: () => void;
}

const GraphOptions: React.FC<Props> = ({ handlePace, handleHeartRate }) => {
	const classes = useStyles();

	const pace = useStoreState((state) => state.activity.pace);
	const activity = useStoreState((state) => state.activity.activity);
	const heartRate = useStoreState((state) => state.activity.heartRate);

	useEffect(() => {
		if (activity && !activity.avgHeartRate && heartRate) {
			handleHeartRate();
		}
		if (activity && activity.avgHeartRate && !heartRate) {
			handleHeartRate();
		}
		if (!pace) {
			handlePace();
		}
	}, []);

	return (
		<div className={classes.root}>
			<Typography>Pace</Typography>
			<Switch color="primary" checked={pace} onClick={handlePace} />
			{!!activity && !!activity.avgHeartRate && (
				<Typography>Heart Rate</Typography>
			)}
			{!!activity && !!activity.avgHeartRate && (
				<Switch
					color="secondary"
					checked={heartRate}
					onClick={handleHeartRate}
				/>
			)}
		</div>
	);
};

export default GraphOptions;
