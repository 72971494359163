import React from 'react';
import { ReactComponent as Health } from '../../../images/health_icon.svg';

interface Props {
	className: string;
}

const HealthIcon: React.FC<Props> = ({ className }) => {
	return <Health className={className} width={27.42} height={27.42} />;
};

export default HealthIcon;
