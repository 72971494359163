import React, { useState } from 'react';
import { AxiosResponse } from 'axios';
import { useForm } from 'react-hook-form';

import { resetPassword } from './utils';
import PResetPassword, { Props } from './ResetPassword';

export interface ResetFormData {
	password: string;
	confirmPassword: string;
	token: string;
}

const ResetPassword: React.FC = () => {
	const id = location.pathname.split('/').pop() as string;

	const [error, setError] = useState(0);

	const { handleSubmit, register, errors, watch } = useForm<ResetFormData>();

	const handleFormSubmit = async (data: ResetFormData) => {
		data.token = id;
		setError(0);
		try {
			await resetPassword(data);
			setError(200);
		} catch (err) {
			if (err && err.response) {
				setError((err.response as AxiosResponse).status);
			}
		}
	};

	const props: Props = {
		handleSubmit,
		register,
		errors,
		watch,
		handleFormSubmit,
		error,
	};

	return <PResetPassword {...props} />;
};

export default ResetPassword;
