import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(),
	},
	header: {
		marginTop: theme.spacing(),
		marginBottom: theme.spacing(),
	},
	content: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		height: '100%',
		flexWrap: 'wrap',
		marginTop: theme.spacing(),
	},
	formLabel: {
		fontSize: 18,
	},
	radio: {
		fontSize: 16,
	},
}));
