import React from 'react';
import { Typography, Button, ButtonGroup } from '@material-ui/core';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { useStyles } from './styles';
import { useStoreState } from '../../../store';
import { Tooltip } from '../../Tooltip';
import { getMonth, getYear } from '../utils';

export interface Props {
	isMobile: boolean;
	handleNext: () => void;
	handlePrevious: () => void;
	handleCurrent: () => void;
	handleDateReset: () => void;
	handleDateChange: (newDate: Date | null) => void;
}

const Header: React.FC<Props> = ({
	isMobile,
	handleNext,
	handlePrevious,
	handleCurrent,
	handleDateReset,
	handleDateChange,
}) => {
	const classes = useStyles();

	const theme = useStoreState((state) => state.settings.theme);
	const days = useStoreState((state) => state.log.days);
	const date = useStoreState((state) => state.log.date);

	return (
		<div className={classes.root}>
			<div className={classes.food}>
				<Typography
					variant="h5"
					className={classes.item}
					style={{ marginRight: 0 }}
				>
					{getYear(days[0], days[27])}
				</Typography>
				<Typography className={classes.item} variant="h6">
					{getMonth(days[0], days[27])}
				</Typography>
			</div>
			<ButtonGroup
				orientation={isMobile ? 'vertical' : 'horizontal'}
				className={classes.controls}
			>
				<Tooltip title="Previous week">
					<Button
						className={classes.control}
						onClick={handlePrevious}
					>
						Previous
					</Button>
				</Tooltip>
				<Tooltip title="Current week">
					<Button className={classes.control} onClick={handleCurrent}>
						Current
					</Button>
				</Tooltip>
				<Tooltip title="Next week">
					<Button className={classes.control} onClick={handleNext}>
						Next
					</Button>
				</Tooltip>
			</ButtonGroup>
			<div
				style={{
					margin: 8,
					flex: 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-end',
				}}
			>
				<div
					style={{
						minHeight: 40,
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							style={{ margin: 0, height: '100%' }}
							autoOk
							disableToolbar
							variant="inline"
							format="dd/MM/yyyy"
							margin="normal"
							value={date}
							onChange={handleDateChange}
							KeyboardButtonProps={{
								className: classes.datePicker,
							}}
							InputProps={{
								style: { width: 150 },
							}}
						/>
					</MuiPickersUtilsProvider>
				</div>
				<Tooltip title="Reset date">
					<Button
						className={classes.control}
						onClick={handleDateReset}
						style={{ marginLeft: theme.spacing() }}
					>
						Reset
					</Button>
				</Tooltip>
			</div>
		</div>
	);
};

export default Header;
