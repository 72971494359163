import { Stream } from '../../../models/activity';

export const getRange = (
	activityData: Stream[],
	field: 'pace' | 'heartRate' | 'elevation',
): number[] => {
	const data = activityData.concat();
	data.sort((a, b) => (a[field] || 0) - (b[field] || 0));

	const min = data[0];
	const max = data[data.length - 1];

	return [min[field] || 0, max[field] || 0];
};

export const getDomain = (duration: number, start: number): number[] => {
	const numberTicks = Math.ceil(duration / 600) + 1;
	const bounds = [];
	for (let i = 0; i < numberTicks; i++) {
		if (i === numberTicks - 1) {
			bounds.push(i * 600 - (i * 600 - duration));
		} else if (i === 0) {
			bounds.push(start);
		} else {
			bounds.push(i * 600);
		}
	}
	return bounds;
};
