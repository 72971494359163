import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import Loading from '../../Loading';
import { useStyles } from './styles';
import { Averages } from './interfaces';
import { average, paceAverage } from './utils';
import List, { Props as ListProps } from './List';
import Result, { Props as ResultProps } from './Result';
import Actions, { Props as ActionsProps } from './Actions';
import { useStoreActions, useStoreState } from '../../../store';

const Compare: React.FC = () => {
	const classes = useStyles();

	const activities = useStoreState(
		(state) => state.activities.compareActivities,
	);
	const compare = useStoreState((state) => state.activities.compare);
	const compareIds = useStoreState((state) => state.activities.compareIds);
	const setCompare = useStoreActions(
		(actions) => actions.activities.setCompare,
	);
	const getActivities = useStoreActions(
		(actions) => actions.activities.getCompareActivities,
	);

	const [loading, setLoading] = useState(true);
	const [selected, setSelected] = useState<(boolean | undefined)[][]>([]);
	const [averages, setAverages] = useState<Averages[]>([]);

	const comparisonRef = useRef<null | HTMLDivElement>(null);

	const scrollToBottom = () => {
		comparisonRef.current?.scrollIntoView({
			behavior: 'smooth',
			block: 'end',
			inline: 'end',
		});
	};

	const isSelected = (activityId: number, lapId: number) =>
		selected[activityId]
			? selected[activityId][lapId] !== undefined
			: false;

	const handleSelect = (activityId: number, lapId: number) => {
		const newSelected = selected.slice();
		if (isSelected(activityId, lapId)) {
			newSelected[activityId][lapId] = undefined;
		} else {
			newSelected[activityId][lapId] = true;
		}
		setSelected(newSelected);
	};

	const resetSelected = () => {
		const newSelected: (boolean | undefined)[][] = [];
		compareIds.forEach(() => {
			newSelected.push([]);
		});
		setSelected(newSelected);
		setAverages([]);
	};

	const getAverages = () => {
		const newAverages: Averages[] = [];
		selected.forEach((laps, activityIndex) => {
			const distances: number[] = [];
			const times: number[] = [];
			const heartRate: number[] = [];
			const cadence: number[] = [];
			laps.forEach((lap, lapIndex) => {
				if (lap) {
					const activityLaps = activities[activityIndex].laps;
					if (activityLaps) {
						const {
							distance,
							avgCadence,
							avgHeartRate,
							duration,
						} = activityLaps[lapIndex];
						distances.push(distance);
						times.push(duration);
						cadence.push(avgCadence);
						if (avgHeartRate) {
							heartRate.push(avgHeartRate);
						}
					}
				}
			});
			if (distances.length) {
				newAverages[activityIndex] = {
					distance: average(distances),
					time: average(times),
					pace: paceAverage(times, distances),
					heartRate: average(heartRate),
					cadence: average(cadence),
				};
			}
		});
		setAverages(newAverages);
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				if (compareIds.length) {
					await getActivities({ activityIds: compareIds });
					const newSelected: (boolean | undefined)[][] = [];
					compareIds.forEach(() => {
						newSelected.push([]);
					});
					setSelected(newSelected);
					setAverages([]);
					setLoading(false);
				}
			} catch (err) {}
		};
		fetchData();
	}, [compareIds, getActivities]);

	const listProps: ListProps = {
		isSelected,
		handleSelect,
	};

	const resultProps: ResultProps = {
		selected,
		averages,
	};

	const ActionsProps: ActionsProps = {
		getAverages,
		resetSelected,
		scrollToBottom,
	};

	return (
		<Dialog open={compare} onClose={() => setCompare()} maxWidth="xl">
			<DialogTitle>Activity Comparison</DialogTitle>
			<DialogContent>
				{loading ? (
					<Loading />
				) : (
					<div className={classes.activity}>
						<List {...listProps} />
						<Result {...resultProps} />
						<div ref={comparisonRef}></div>
					</div>
				)}
			</DialogContent>
			<Actions {...ActionsProps} />
		</Dialog>
	);
};

export default Compare;
