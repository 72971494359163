import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		flexWrap: 'wrap',
		overflow: 'auto',
	},
});
