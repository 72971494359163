import { Activity } from '../../../models/activity';

export const validateDistance = (
	value: string,
	activity: Activity | undefined,
	multiplier: number,
): boolean => {
	if (
		(activity &&
			Number(value) &&
			Number(value) > 0 &&
			Number(value) <
				Number((activity.distance / multiplier).toFixed(2))) ||
		value === ''
	) {
		return true;
	}
	return false;
};
