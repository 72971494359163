import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useStyles } from './styles';
import { FiltersData } from '../../interfaces';
import { Tooltip } from '../../../Tooltip';
import { useStoreState } from '../../../../store';

export interface Props
	extends Pick<UseFormMethods, 'handleSubmit' | 'register'> {
	handleFormSubmit: (data: FiltersData) => void;
	handleExpandClick: () => void;
}

const Search: React.FC<Props> = ({
	register,
	handleSubmit,
	handleFormSubmit,
	handleExpandClick,
}) => {
	const classes = useStyles();

	const theme = useStoreState((state) => state.settings.theme);
	const filtersData = useStoreState((state) => state.activities.filtersData);

	return (
		<div className={classes.root}>
			<TextField
				autoComplete="off"
				variant="outlined"
				size="small"
				placeholder="Search"
				name="name"
				defaultValue={filtersData.name}
				inputRef={register}
				style={{ width: '33%' }}
			/>
			<Tooltip
				title="Search activities"
				style={{ marginLeft: theme.spacing() }}
			>
				<IconButton
					className={classes.iconButton}
					type="submit"
					onClick={handleSubmit(handleFormSubmit)}
					style={{ padding: 4 }}
				>
					<SearchIcon
						className={classes.icon}
						style={{ fontSize: 32 }}
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title="Filters" style={{ marginLeft: theme.spacing() }}>
				<IconButton
					onClick={handleExpandClick}
					className={classes.iconButton}
					style={{
						marginLeft: 'auto',
					}}
				>
					<ExpandMoreIcon className={classes.icon} fontSize="large" />
				</IconButton>
			</Tooltip>
		</div>
	);
};

export default Search;
