import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		borderColor: theme.palette.primary.dark,
		backgroundColor: theme.palette.primary.dark,
	},
	text: {
		color: theme.palette.defaultWhite,
	},
	tooltip: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginLeft: theme.spacing(),
		marginRight: theme.spacing(),
	},
}));
