import { action, Action, thunk, Thunk } from 'easy-peasy';

import { StoreModel } from '.';
import { axiosService } from '../services/axios';

export interface Notification {
	id: string;
	message: string;
	url: string;
}

export interface AddNotification {
	message: string;
	url: string;
}

export interface NotificationsModel {
	notifications: Notification[];
	setNotifications: Action<NotificationsModel, Notification[]>;
	getNotifications: Thunk<NotificationsModel>;
	removeNotification: Thunk<NotificationsModel, Notification>;
	addNotification: Thunk<
		NotificationsModel,
		AddNotification,
		undefined,
		StoreModel,
		Promise<Notification>
	>;
}

const getNotifications = async (): Promise<Notification[]> => {
	try {
		const response = await axiosService.get('/api/notifications', {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const removeNotification = async (
	notification: Notification,
): Promise<void> => {
	try {
		await axiosService.delete(`/api/notifications/${notification.id}`, {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
	} catch (err) {
		throw err;
	}
};

const addNotification = async (
	notification: AddNotification,
): Promise<Notification> => {
	try {
		const response = await axiosService.post(
			'/api/notifications',
			{ ...notification },
			{
				headers: {
					authorization: localStorage.getItem('JWT'),
				},
			},
		);
		return response.data.data;
	} catch (err) {
		throw err;
	}
};

const notifications: NotificationsModel = {
	notifications: [],
	setNotifications: action((state, value) => {
		state.notifications = value;
	}),
	getNotifications: thunk(async (actions) => {
		const notifications = await getNotifications();
		actions.setNotifications(notifications.reverse());
	}),
	removeNotification: thunk(async (actions, value) => {
		await removeNotification(value);
		await actions.getNotifications();
	}),
	addNotification: thunk(async (_actions, value) => {
		const notification = await addNotification(value);
		return notification;
	}),
};

export default notifications;
