import React from 'react';

import PDisplay, { Props } from './Display';
import { useStoreActions, useStoreState } from '../../../store';

const Display: React.FC = () => {
	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const paceUnit = useStoreState((state) => state.settings.paceUnit);
	const elevationUnit = useStoreState(
		(state) => state.settings.elevationUnit,
	);
	const timeFormat = useStoreState((state) => state.settings.timeFormat);
	const themeType = useStoreState((state) => state.settings.themeType);

	const updateSettings = useStoreActions(
		(actions) => actions.settings.updateSettings,
	);
	const getSettings = useStoreActions(
		(actions) => actions.settings.getSettings,
	);

	const handleDistanceChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		try {
			await updateSettings({
				distanceUnit: (event.target as HTMLInputElement).value,
				paceUnit,
				elevationUnit,
				timeFormat,
				theme: themeType,
			});
			await getSettings();
		} catch (err) {}
	};

	const handlePaceChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		try {
			await updateSettings({
				paceUnit: (event.target as HTMLInputElement).value,
				distanceUnit,
				elevationUnit,
				timeFormat,
				theme: themeType,
			});
			await getSettings();
		} catch (err) {}
	};

	const handleElevationChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		try {
			await updateSettings({
				elevationUnit: (event.target as HTMLInputElement).value,
				paceUnit,
				distanceUnit,
				timeFormat,
				theme: themeType,
			});
			await getSettings();
		} catch (err) {}
	};

	const handleTimeFormatChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		try {
			await updateSettings({
				timeFormat: (event.target as HTMLInputElement).value,
				paceUnit,
				elevationUnit,
				distanceUnit,
				theme: themeType,
			});
			await getSettings();
		} catch (err) {}
	};

	const handleThemeChange = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		try {
			await updateSettings({
				theme: (event.target as HTMLInputElement).value,
				paceUnit,
				elevationUnit,
				timeFormat,
				distanceUnit,
			});
			await getSettings();
		} catch (err) {}
	};

	const props: Props = {
		handleDistanceChange,
		handlePaceChange,
		handleElevationChange,
		handleTimeFormatChange,
		handleThemeChange,
	};

	return <PDisplay {...props} />;
};

export default Display;
