import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		justifyContent: 'flex-start',
		margin: theme.spacing(),
	},
	title: {
		display: 'flex',
		flexDirection: 'row',
	},
}));
