import React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { UseFormMethods } from 'react-hook-form';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';

import {
	distanceFilter,
	distanceFilterReset,
	timeFilter,
	timeFilterReset,
	paceFilter,
	paceFilterReset,
	dateFilter,
	dateFilterReset,
	heartRateFilter,
	heartRateFilterReset,
	elevationFilter,
	elevationFilterReset,
	buildFilters,
	tagFilterReset,
	tagFilter,
	shoeFilter,
	shoeFilterReset,
	gpsFilter,
	gpsFilterReset,
	resetAllFilters,
} from './utils';
import { useStyles } from './styles';
import DateFilter from './DateFilter';
import ArrayFilter from './ArrayFilter';
import { FiltersData } from '../interfaces';
import Search, { Props as SearchProps } from './Search';
import Filter, { Props as FilterProps } from './Filter';
import { useStoreActions, useStoreState } from '../../../store';

export type Props = Pick<
	UseFormMethods,
	| 'control'
	| 'handleSubmit'
	| 'reset'
	| 'getValues'
	| 'register'
	| 'watch'
	| 'errors'
>;

const Filters: React.FC<Props> = ({
	register,
	handleSubmit,
	control,
	errors,
	getValues,
	reset,
	watch,
}) => {
	const classes = useStyles();

	const theme = useStoreState((state) => state.settings.theme);
	const date = useStoreState((state) => state.activities.date);
	const dateSecondary = useStoreState(
		(state) => state.activities.dateSecondary,
	);
	const expand = useStoreState((state) => state.activities.expand);
	const setFilters = useStoreActions(
		(actions) => actions.activities.setFilters,
	);
	const setFiltersData = useStoreActions(
		(actions) => actions.activities.setFiltersData,
	);
	const setExpand = useStoreActions(
		(actions) => actions.activities.setExpand,
	);
	const selectedTags = useStoreState(
		(state) => state.activities.selectedTags,
	);
	const selectedShoes = useStoreState(
		(state) => state.activities.selectedShoes,
	);
	const setSelected = useStoreActions(
		(actions) => actions.activity.setSelected,
	);
	const setDate = useStoreActions((actions) => actions.activities.setDate);
	const setDateSecondary = useStoreActions(
		(actions) => actions.activities.setDateSecondary,
	);
	const setSelectedTags = useStoreActions(
		(actions) => actions.activities.setSelectedTags,
	);
	const setSelectedShoes = useStoreActions(
		(actions) => actions.activities.setSelectedShoes,
	);
	const setPage = useStoreActions((actions) => actions.activities.setPage);

	const handleFormSubmit = (data: FiltersData) => {
		setFiltersData(data);
		const newFilters = buildFilters(
			data,
			date,
			dateSecondary,
			selectedTags,
			selectedShoes,
		);
		setFilters(newFilters);
		setPage(0);
		setSelected([]);
		setExpand(false);
	};

	const handleExpandClick = () => {
		setExpand(!expand);
	};

	const handleReset = () => {
		setFilters([]);
		setFiltersData({});
		setSelectedTags([]);
		setSelectedShoes([]);
		setDate(null);
		setDateSecondary(null);
		reset({
			...resetAllFilters,
		});
		setExpand(false);
	};

	const filterProps = {
		control,
		register,
		errors,
		watch,
		reset,
		getValues,
	};

	const distanceFilterProps: FilterProps = {
		filterReset: distanceFilterReset,
		...filterProps,
		...distanceFilter,
	};

	const timeFilterProps: FilterProps = {
		filterReset: timeFilterReset,
		...filterProps,
		...timeFilter,
	};

	const paceFilterProps: FilterProps = {
		filterReset: paceFilterReset,
		...filterProps,
		...paceFilter,
	};

	const dateFilterProps: FilterProps = {
		filterReset: dateFilterReset,
		...filterProps,
		...dateFilter,
	};

	const heartRateFilterProps: FilterProps = {
		filterReset: heartRateFilterReset,
		...filterProps,
		...heartRateFilter,
	};

	const elevationFilterProps: FilterProps = {
		filterReset: elevationFilterReset,
		...filterProps,
		...elevationFilter,
	};

	const tagsFilterProps: FilterProps = {
		filterReset: tagFilterReset,
		...filterProps,
		...tagFilter,
	};

	const shoeFilterProps: FilterProps = {
		filterReset: shoeFilterReset,
		...filterProps,
		...shoeFilter,
	};

	const gpsFilterProps: FilterProps = {
		filterReset: gpsFilterReset,
		...filterProps,
		...gpsFilter,
	};

	const searchProps: SearchProps = {
		register,
		handleSubmit,
		handleFormSubmit,
		handleExpandClick,
	};

	return (
		<form className={classes.root}>
			<Search {...searchProps} />
			<Collapse in={expand}>
				<Divider className={classes.divider} />
				<Typography variant="h5">Filters</Typography>
				<div className={classes.filterDetails}>
					<DateFilter {...dateFilterProps} />
					<Filter {...distanceFilterProps} />
					<Filter {...timeFilterProps} />
					<Filter {...paceFilterProps} />
					<Filter {...heartRateFilterProps} />
					<Filter {...elevationFilterProps} />
					<ArrayFilter {...tagsFilterProps} />
					<ArrayFilter {...shoeFilterProps} />
					<Filter {...gpsFilterProps} />
				</div>
				<div className={classes.buttons}>
					<Button
						className={classes.button}
						type="submit"
						onClick={handleSubmit(handleFormSubmit)}
						style={{ marginRight: theme.spacing() }}
					>
						Apply
					</Button>
					<Button className={classes.button} onClick={handleReset}>
						Reset
					</Button>
				</div>
			</Collapse>
		</form>
	);
};

export default Filters;
