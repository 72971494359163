import React from 'react';
import { Divider, Paper, Typography } from '@material-ui/core';

import { useStyles } from './style';
import { Averages } from '../interfaces';
import { useStoreState } from '../../../../store';
import { TooltipNoMargin as Tooltip } from '../../../Tooltip';
import { formatDistance, formatTime } from '../../../../utils/formatters';

export interface Props {
	selected: (boolean | undefined)[][];
	averages: Averages[];
}

const Result: React.FC<Props> = ({ selected, averages }) => {
	const classes = useStyles();

	const distanceUnit = useStoreState((state) => state.settings.distanceUnit);
	const paceUnit = useStoreState((state) => state.settings.paceUnit);
	const activities = useStoreState(
		(state) => state.activities.compareActivities,
	);

	return (
		<div
			className={classes.root}
			style={{
				justifyContent:
					activities.length === 1 ? 'center' : 'flex-start',
			}}
		>
			{selected.map((_laps, activityIndex) => {
				const activityAverages = averages[activityIndex];
				return (
					<div
						key={activityIndex}
						className={classes.activityContainer}
					>
						<Paper variant="outlined" className={classes.paper}>
							<div className={classes.activity}>
								<Tooltip title={activities[activityIndex].name}>
									<Typography
										align="center"
										noWrap
										className={classes.title}
									>
										{activities[activityIndex].name}
									</Typography>
								</Tooltip>
								<Divider style={{ width: '100%' }} />
								{activityAverages ? (
									<>
										<Typography>
											{formatDistance(
												averages[activityIndex]
													.distance,
												distanceUnit,
											)}
										</Typography>
										<Typography>
											{formatTime(
												averages[activityIndex].time,
												undefined,
												true,
											)}
										</Typography>
										<Typography>
											{formatTime(
												averages[activityIndex].pace,
												paceUnit,
											)}
										</Typography>
									</>
								) : (
									<Typography>No laps selected</Typography>
								)}
							</div>
						</Paper>
					</div>
				);
			})}
		</div>
	);
};

export default Result;
