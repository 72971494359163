import { action, Action, thunk, Thunk } from 'easy-peasy';

import { StoreModel } from '.';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import darkTheme from '../themes/darkTheme';
import lightTheme from '../themes/lightTheme';
import { axiosService } from '../services/axios';

export interface Settings {
	distanceUnit: string;
	paceUnit: string;
	elevationUnit: string;
	timeFormat: string;
	theme: string;
}

export interface SettingsModel {
	meals: string[];
	setMeals: Action<SettingsModel, string[]>;
	theme: Theme;
	themeType: string;
	distanceUnit: string;
	paceUnit: string;
	elevationUnit: string;
	timeFormat: string;
	selectedTags: string[];
	setSelectedTags: Action<SettingsModel, string[]>;
	selectedShoes: string[];
	setSelectedShoes: Action<SettingsModel, string[]>;
	setSettings: Action<SettingsModel, Settings>;
	getSettings: Thunk<
		SettingsModel,
		undefined,
		undefined,
		StoreModel,
		Promise<Settings>
	>;
	updateSettings: Thunk<
		SettingsModel,
		Settings,
		undefined,
		StoreModel,
		Promise<Settings>
	>;
}

const getSettings = async (): Promise<Settings> => {
	try {
		const response = await axiosService.get('/api/user', {
			headers: {
				authorization: localStorage.getItem('JWT'),
			},
		});
		return response.data.data.settings;
	} catch (err) {
		throw err;
	}
};

const updateSettings = async (params: Settings): Promise<Settings> => {
	try {
		const response = await axiosService.patch(
			'/api/user/settings',
			{ ...params },
			{
				headers: {
					authorization: localStorage.getItem('JWT'),
				},
			},
		);
		return response.data.data.settings;
	} catch (err) {
		throw err;
	}
};

const settings: SettingsModel = {
	meals: ['Breakfast', 'Lunch', 'Dinner', 'Snacks'],
	setMeals: action((state, value) => {
		state.meals = value;
	}),
	theme: lightTheme,
	themeType: 'light',
	distanceUnit: 'km',
	paceUnit: '/km',
	elevationUnit: 'm',
	timeFormat: '12h',
	selectedTags: [],
	setSelectedTags: action((state, value) => {
		state.selectedTags = value;
	}),
	selectedShoes: [],
	setSelectedShoes: action((state, value) => {
		state.selectedShoes = value;
	}),
	setSettings: action((state, value) => {
		state.distanceUnit = value.distanceUnit;
		state.paceUnit = value.paceUnit;
		state.elevationUnit = value.elevationUnit;
		state.timeFormat = value.timeFormat;
		state.themeType = value.theme;
		state.theme = value.theme === 'light' ? lightTheme : darkTheme;
	}),
	getSettings: thunk(async (actions) => {
		try {
			const settings = await getSettings();
			actions.setSettings(settings);
			return settings;
		} catch (err) {
			throw err;
		}
	}),
	updateSettings: thunk(async (_actions, value) => {
		try {
			const settings = await updateSettings(value);
			return settings;
		} catch (err) {
			throw err;
		}
	}),
};

export default settings;
