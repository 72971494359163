import React from 'react';
import {
	Divider,
	TableContainer,
	Typography,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	Button,
	TextField,
	FormControl,
	Select,
	MenuItem,
	IconButton,
} from '@material-ui/core';
import { Controller, UseFormMethods } from 'react-hook-form';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useStyles } from './styles';
import { useStoreState } from '../../../store';
import Dialog from '../../Dialog';
import { formatMealNumber, getMealTotal } from './utils';
import { EntryData } from './interfaces';
import { Entry, Food } from '../../../models/foods';
import Tooltip from '../../Tooltip';

export interface Props
	extends EntryData,
		Pick<
			UseFormMethods,
			'control' | 'handleSubmit' | 'register' | 'errors'
		> {
	name: string;
	number: number;
	resetData: () => void;
	handleMealConfirmClick: (data: EntryData) => Promise<void>;
	handleCheckbox: (id: string) => void;
	handleRemoveSelected: () => Promise<void>;
	handleRemoveOne: () => Promise<void>;
	handleSelectAll: (event: React.ChangeEvent<HTMLInputElement>) => void;
	isSelected: (id: string) => boolean;
	mealEntries: Entry[];
	mealSelected: string[];
	food: Food | null;
	unit: string;
	value: string;
	setRemoveId: React.Dispatch<React.SetStateAction<string>>;
	setRemoveSelected: React.Dispatch<React.SetStateAction<boolean>>;
	setRemoveOne: React.Dispatch<React.SetStateAction<boolean>>;
	removeOne: boolean;
	removeSelected: boolean;
}

const Meal: React.FC<Props> = ({
	name,
	number,
	resetData,
	handleMealConfirmClick,
	control,
	handleSubmit,
	register,
	errors,
	handleCheckbox,
	handleRemoveSelected,
	handleRemoveOne,
	handleSelectAll,
	isSelected,
	mealEntries,
	mealSelected,
	food,
	unit,
	value,
	setRemoveId,
	setRemoveSelected,
	setRemoveOne,
	removeOne,
	removeSelected,
}) => {
	const classes = useStyles();

	const theme = useStoreState((state) => state.settings.theme);
	const allFood = useStoreState((state) => state.foods.foods);
	const meals = useStoreState((state) => state.foods.meals);
	const entries = useStoreState((state) => state.foods.entries);

	return (
		<div className={classes.root}>
			<div className={classes.headerContainer}>
				<Typography variant="h5" className={classes.header}>
					{name}
				</Typography>
			</div>
			<Divider className={classes.divider} />
			<TableContainer
				component={Paper}
				variant="outlined"
				style={{
					borderWidth: 0,
				}}
			>
				<Table stickyHeader size="small">
					<TableHead>
						<TableRow>
							<TableCell
								classes={{ head: classes.tableHeader }}
								width="5%"
							>
								<Checkbox
									color="primary"
									classes={{
										root: classes.checkbox,
									}}
									checked={
										mealEntries.length > 0 &&
										mealSelected.length ===
											mealEntries.length
									}
									onChange={handleSelectAll}
									style={{ padding: 4 }}
								/>
							</TableCell>
							<TableCell
								classes={{ head: classes.tableHeader }}
								width="30%"
							>
								Name
							</TableCell>
							<TableCell
								classes={{ head: classes.tableHeader }}
								width="12%"
							>
								Amount
							</TableCell>
							<TableCell
								classes={{ head: classes.tableHeader }}
								width="12%"
							>
								Calories
							</TableCell>
							<TableCell
								classes={{ head: classes.tableHeader }}
								width="12%"
							>
								Carbs
							</TableCell>
							<TableCell
								classes={{ head: classes.tableHeader }}
								width="12%"
							>
								Fat
							</TableCell>
							<TableCell
								classes={{ head: classes.tableHeader }}
								width="12%"
							>
								Protein
							</TableCell>
							<TableCell
								classes={{ head: classes.tableHeader }}
								width="5%"
							>
								Remove
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{mealEntries.map((entry, index) => {
							const {
								id,
								name,
								amount,
								calories,
								carbs,
								fat,
								protein,
								unit,
							} = entry;
							const isItemSelected = isSelected(id);
							return (
								<TableRow
									key={index}
									hover
									selected={isItemSelected}
									className={classes.row}
									classes={{ selected: classes.selected }}
								>
									<TableCell>
										<Checkbox
											color="primary"
											classes={{
												root: classes.checkbox,
											}}
											checked={isItemSelected}
											onClick={() => handleCheckbox(id)}
											style={{ padding: 4 }}
										/>
									</TableCell>
									<TableCell>
										{amount ? name : `${name} (meal)`}
									</TableCell>
									<TableCell>
										{amount
											? `${formatMealNumber(
													amount,
											  )}${unit}`
											: ''}
									</TableCell>
									<TableCell>
										{formatMealNumber(calories)}
									</TableCell>
									<TableCell>
										{formatMealNumber(carbs)}
									</TableCell>
									<TableCell>
										{formatMealNumber(fat)}
									</TableCell>
									<TableCell>
										{formatMealNumber(protein)}
									</TableCell>
									<TableCell align="center">
										<IconButton
											className={classes.iconButton}
											onClick={() => {
												setRemoveId(id);
												setRemoveOne((value) => !value);
											}}
										>
											<DeleteIcon
												className={classes.icon}
											/>
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
						<TableRow>
							<TableCell colSpan={2} />
							<TableCell style={{ fontWeight: 'bold' }}>
								Total
							</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>
								{`${getMealTotal(
									entries,
									'calories',
									number,
								)} C`}
							</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>
								{`${getMealTotal(entries, 'carbs', number)} g`}
							</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>
								{`${getMealTotal(entries, 'fat', number)} g`}
							</TableCell>
							<TableCell style={{ fontWeight: 'bold' }}>
								{`${getMealTotal(
									entries,
									'protein',
									number,
								)} g`}
							</TableCell>
							<TableCell />
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			<Dialog
				open={removeOne}
				handleDialogConfirmClick={handleRemoveOne}
				handleDialogClick={() => setRemoveOne((value) => !value)}
				title="Remove Entry"
				content="Are you sure you want to remove this entry?"
				confirm="Remove"
			/>
			<Dialog
				open={removeSelected}
				handleDialogConfirmClick={handleRemoveSelected}
				handleDialogClick={() => setRemoveSelected((value) => !value)}
				title="Remove selected Entries"
				content="Are you sure you want to remove the selected entries?"
				confirm="Remove"
			/>
			<div className={classes.selection}>
				<Tooltip
					title="Remove selected entries"
					style={{ marginTop: theme.spacing() }}
				>
					<span>
						<Button
							className={classes.button}
							disabled={mealSelected.length === 0}
							onClick={() => {
								setRemoveSelected((value) => !value);
							}}
						>
							Remove
						</Button>
					</span>
				</Tooltip>
				<form
					style={{
						marginLeft: 'auto',
						display: 'flex',
						flexWrap: 'wrap',
					}}
				>
					<Controller
						name="food"
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						onChange={([, data]: [any, any]) => data}
						defaultValue={null}
						control={control}
						render={({ onChange, ...props }) => (
							<Autocomplete
								{...props}
								options={[...allFood, ...meals]}
								getOptionLabel={(food) =>
									food.amount
										? `${food.name}, ${food.amount} ${food.unit}`
										: food.name
								}
								className={classes.selectionItem}
								ListboxProps={{ style: { maxHeight: 300 } }}
								onChange={(_e, data) => onChange(data)}
								renderInput={(params) => (
									<TextField
										{...params}
										size="small"
										variant="outlined"
										style={{
											width: 300,
											marginTop: theme.spacing(),
										}}
										InputProps={{
											...params.InputProps,
											placeholder: 'Search',
											style: { height: 40 },
										}}
									/>
								)}
							/>
						)}
					/>
					{!!food && food.amount && (
						<FormControl
							className={classes.selectionItem}
							style={{
								marginTop: theme.spacing(),
							}}
						>
							<Controller
								name="unit"
								control={control}
								defaultValue="quantity"
								as={
									<Select className={classes.select}>
										<MenuItem value="quantity">
											quantity
										</MenuItem>
										<MenuItem value="amount">
											amount
										</MenuItem>
									</Select>
								}
							/>
						</FormControl>
					)}
					{!!food && food.amount && (
						<TextField
							name="value"
							inputRef={register({
								validate: (value: string) => Number(value) > 0,
							})}
							autoComplete="off"
							size="small"
							variant="outlined"
							error={errors.value ? true : false}
							style={{ width: 112, marginTop: theme.spacing() }}
							placeholder={
								unit === 'quantity'
									? 'Qty'
									: unit === 'amount'
									? `Amt ${
											food && food.unit
												? `(${food.unit})`
												: ''
									  }`
									: 'Qty'
							}
							className={classes.selectionItem}
							InputProps={{ style: { height: 40 } }}
						/>
					)}
					<Button
						type="submit"
						disabled={!food || (!!food && !!food.amount && !value)}
						className={classes.button}
						style={{
							marginTop: theme.spacing(),
						}}
						onClick={handleSubmit(handleMealConfirmClick)}
					>
						Add
					</Button>
					<Button
						className={classes.secondaryButton}
						disabled={
							(!food && !value && !unit) ||
							(!food && !value && unit === 'quantity')
						}
						style={{
							marginTop: theme.spacing(),
						}}
						onClick={resetData}
					>
						Cancel
					</Button>
				</form>
			</div>
		</div>
	);
};

export default Meal;
