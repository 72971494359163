import React from 'react';
import { IconButton } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import HealthIcon from '../../../Icons/HealthIcon';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import {
	getBmr,
	getCalories,
	getExtraCalories,
	getCaloriePercentage,
} from '../../../Log/Day/utils';
import { useStyles } from './styles';
import { getCaloriesEaten } from './utils';
import { useStoreState } from '../../../../store';
import { TooltipNoMargin as Tooltip } from '../../../Tooltip';

export interface Props {
	handleExpandClick: () => void;
	handleHealthClick: () => void;
	handleFoodClick: () => void;
}

const Icons: React.FC<Props> = ({
	handleExpandClick,
	handleHealthClick,
	handleFoodClick,
}) => {
	const classes = useStyles();

	const activities = useStoreState((state) => state.day.activities);
	const entries = useStoreState((state) => state.foods.entries);
	const health = useStoreState((state) => state.day.health);
	const date = useStoreState((state) => state.day.date);
	const user = useStoreState((state) => state.user.user);

	return (
		<div className={classes.root}>
			{getCaloriePercentage(
				getCaloriesEaten(entries),
				getBmr(health, user, date) +
					getCalories(activities) +
					getExtraCalories(health, date),
			) > 10 ? (
				<Tooltip title="High calorie intake">
					<IconButton className={classes.errorIconButton}>
						<ErrorIcon className={classes.errorIcon} />
					</IconButton>
				</Tooltip>
			) : getCaloriePercentage(
					getCaloriesEaten(entries),
					getBmr(health, user, date) +
						getCalories(activities) +
						getExtraCalories(health, date),
			  ) < -10 ? (
				<Tooltip title="Low calorie intake">
					<IconButton className={classes.errorIconButton}>
						<ErrorIcon className={classes.errorIcon} />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Good calorie intake">
					<IconButton className={classes.successIconButton}>
						<CheckCircleIcon className={classes.successIcon} />
					</IconButton>
				</Tooltip>
			)}
			<Tooltip title={'Edit health'}>
				<IconButton
					className={classes.svgButton}
					onClick={handleHealthClick}
				>
					<HealthIcon className={classes.svg} />
				</IconButton>
			</Tooltip>
			<Tooltip title="Add food or meal">
				<IconButton
					className={classes.iconButton}
					onClick={handleFoodClick}
				>
					<FastfoodIcon className={classes.icon} />
				</IconButton>
			</Tooltip>
			<Tooltip title={'Details'}>
				<IconButton
					onClick={handleExpandClick}
					className={classes.iconButton}
				>
					<ExpandMoreIcon className={classes.icon} fontSize="large" />
				</IconButton>
			</Tooltip>
		</div>
	);
};

export default Icons;
